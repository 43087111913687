/** @jsxImportSource theme-ui */
import { useTranslation } from 'react-i18next';

import React from 'react';
import { ThemeUIStyleObject } from 'theme-ui';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Text from '../../01_atoms/Text/Text';
import Container from '../../01_atoms/Container/Container';
import { getDeviceWidth } from '../../../utils/getDeviceWidth';
import i18n from '../../../i18n/i18n';
import { useStripe } from '../../../context/StripeContext';

export interface FooterProps {
  sx?: ThemeUIStyleObject;
  theme?: 'light' | 'dark-transparent' | 'dark-solid';
}

const Wrapper = styled.div`
  background: rgb(255, 255, 255);
  border-radius: 0;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  @media (max-width: ${getDeviceWidth.tablet}) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;
const ContainerStyled = styled(Container)`
  justify-content: space-between;
  align-items: center;
  display: flex;
  @media (max-width: ${getDeviceWidth.tablet}) {
    flex-direction: column;
  }
`;
const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  @media (max-width: ${getDeviceWidth.tablet}) {
    margin-bottom: 40px;
    align-items: center;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  @media (max-width: ${getDeviceWidth.tablet}) {
    flex-direction: column;
  }
`;

const FooterTitle = styled(Text)`
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
  display: flex;
  text-transform: uppercase;
`;

const StyledLink = styled(Link)`
  @media (max-width: ${getDeviceWidth.tablet}) {
    margin-top: 16px;
  }
  color: #000;
  &.active {
    color: #a4d9d6;
  }
`;

const StyledAnchorTag = styled.a`
  @media (max-width: ${getDeviceWidth.tablet}) {
    margin-top: 16px;
  }
  color: #000;
  &.active {
    color: #a4d9d6;
  }
`;

const Footer: React.FC<FooterProps> = (props: FooterProps) => {
  const { t } = useTranslation();
  const { setIsStripePayment } = useStripe();
  const { theme = 'light' } = props;
  let variant = 'links.footer';

  if (theme === 'dark-solid') {
    variant = 'links.footerDarkSolid';
  } else if (theme === 'dark-transparent') {
    variant = 'links.footerDarkTransparent';
  }

  return (
    <Wrapper>
      <ContainerStyled>
        <LeftContainer>
          <FooterTitle>{t('ABOUT_YOU')}</FooterTitle>
        </LeftContainer>

        <LinksContainer>
          <Text variant={variant} sx={{ my: [null, null, 0], mx: [0, 2, 3] }}>
            <StyledLink
              activeClassName="active"
              to="/imprint"
              sx={{ textDecoration: 'none' }}
            >
              {t('IMPRESSUM')}
            </StyledLink>
          </Text>
          <Text variant={variant} sx={{ my: [null, null, 0], mx: [0, 2, 3] }}>
            <StyledAnchorTag
              target="blank"
              href={
                i18n.language === 'pl'
                  ? '/pdfs/privacy-policy.pdf'
                  : '/pdfs/privacy-policy_eng.pdf'
              }
              sx={{ textDecoration: 'none' }}
            >
              {t('DATENSCHUTZERKL_RUNG')}
            </StyledAnchorTag>
          </Text>
          <Text variant={variant} sx={{ my: [null, null, 0], mx: [0, 2, 3] }}>
            <StyledAnchorTag
              href={
                i18n.language === 'pl'
                  ? '/pdfs/terms-and-conditions.pdf'
                  : '/pdfs/terms-and-conditions_eng.pdf'
              }
              sx={{ textDecoration: 'none' }}
            >
              {t('NUTZUNGSBEDINGUNGEN')}
            </StyledAnchorTag>
          </Text>
          <Text variant={variant} sx={{ my: [null, null, 0], mx: [0, 2, 3] }}>
            <StyledAnchorTag
              href={
                i18n.language === 'pl'
                  ? '/pdfs/cookies-policy.pdf'
                  : '/pdfs/cookies-policy_eng.pdf'
              }
              sx={{ textDecoration: 'none' }}
            >
              {t('COOKIE_POLICY')}
            </StyledAnchorTag>
          </Text>
          <Text variant={variant} sx={{ my: [null, null, 0], mx: [0, 2, 3] }}>
            <StyledAnchorTag
              href="/questions-and-answers"
              sx={{ textDecoration: 'none' }}
            >
              {t('QUESTIONS')}
            </StyledAnchorTag>
          </Text>
          <Text variant={variant} sx={{ my: [null, null, 0], mx: [0, 2, 3] }}>
            <StyledAnchorTag href="/register" sx={{ textDecoration: 'none' }}>
              {t('BUSINESS_REGISTRATION2')}
            </StyledAnchorTag>
          </Text>
          <Text variant={variant} sx={{ my: [null, null, 0], mx: [0, 2, 3] }}>
            <StyledAnchorTag
              href="/business-login"
              sx={{ textDecoration: 'none' }}
            >
              {t('BUSINESS_LOGIN')}
            </StyledAnchorTag>
          </Text>
          <Text variant={variant} sx={{ my: [null, null, 0], mx: [0, 2, 3] }}>
            {t('LANGUAGE')}:{'     '}
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg"
              alt="Description of SVG"
              height={12}
              style={{ marginLeft: '10px' }}
              onClick={() => i18n.changeLanguage('eng')}
            />
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/1/12/Flag_of_Poland.svg"
              alt="Description of SVG"
              height={12}
              style={{ marginLeft: '10px' }}
              onClick={() => i18n.changeLanguage('pl')}
            />
          </Text>
        </LinksContainer>
      </ContainerStyled>
    </Wrapper>
  );
};

export default Footer;
