export default {
  android_promo_banner: {
    title: 'PhysioStretch.app',
    desc: 'stretching & yoga',
    install: 'Zainstaluj',
  },
  i_have_voucher: 'Masz voucher??',
  have_voucher: 'Masz voucher ?',
  voucher_placeholder: 'Voucher',
  send_voucher: 'Wyślij',
  voucher_activated: 'Aktywowano voucher',
  voucher_activated_desc:
    'Cieszymy się, że dołączyłeś do nas jako klient biznesowy. Twoje partnerstwo jest dla nas cenne. Po zakończonym procesie anuluj subskrypcje AppStore / Google play',
  voucher_activated_label: 'Dalej',
  voucher_code_invalid: 'Kod nieprawidłowy',
  voucher_code_invalid_desc: 'Wpisz kod ponownie',
  voucher_code_invalid_label: 'Ok',
  finish_session_thanks: {
    position_to_improve: 'Pozycja, którą warto poprawić:',
    tips_for_you: 'Wskazówki dla Ciebie:',
    level_1_message: 'Świetny początek!',
    level_2_message: 'Jesteś na dobrej drodze!',
    level_3_message: 'Jesteśmy pod wrażeniem!',
    level_4_message: 'Świetnie Ci idzie!',
    level_5_message: 'jesteś mistrzem rozciągania!',
    congratulation: 'Gratulacje!',
    your_score: 'Twój wynik to:',
    best_score: 'Najlepszy wynik:',
    first_category_tips: {
      _1: 'Regularny trening poprawia krążenie i uelastycznia ciało. Zobaczysz efekty na co dzień!',
      _2: 'Regularny trening to połowa sukcesu! Z czasem Twoja elastyczność się zwiększy.',
      _3: 'Lepiej ćwiczyć często i krótko niż rzadko i długo.',
      _4: 'Regularna aktywność fizyczna to podstawa zdrowego trybu życia. Pamiętaj - w zdrowym ciele zdrowy duch! :)',
      _5: 'Systematyczność to klucz do doskonalenia siebie.',
    },
    second_category_tips: {
      _1: 'Większość pozycji jest wykonana poprawnie tylko, jeśli plecy są proste. To nic, jeśli nie sięgasz daleko!',
      _2: 'Przy skłonie pamiętaj o prostym kręgosłupie i kolanach. Zakres ruchu może być mniejszy, ale ćwiczenie będzie wykonane poprawnie.',
      _3: 'Pamiętaj o nawodnieniu w trakcie treningu!',
      _4: 'Nie wykonuj ćwiczeń na wstrzymanym oddechu, chyba że ćwiczenie tego wymaga - staraj się wykonywać polecenia instruktora.',
      _5: 'Jeśli tego potrzebujesz, wyjdź z pozycji wcześniej. Ciało Ci podpowie.',
    },
    third_category_tips: {
      _1: 'Głęboki oddech pomaga pogłębić rozciąganie.',
      _2: 'Ćwicz do granicy bólu. Rozciąganie nie powinno być ponad Twoje siły. Jeśli czujesz, że to Twój maksymalny zakres to nie próbuj pogłębić na siłę.',
      _3: 'Nie omijaj rozgrzewki! Rozciąganie jest treningiem i wymaga przygotowania ciała przed rozpoczęciem.',
      _4: 'Każdy cel możesz osiągnąć, nie poddawaj się!',
      _5: 'Rozciąganie może być dynamiczne i statyczne. Dopasuj treningi pod siebie.',
    },
  },
  COMPANY_NAME: 'Nazwa firmy',
  MINIMAL_CHAR_LONG: 'Minimalna długość 6 znaków',
  PASS_TO_SHORT: 'Za krótkie (6 znaków)',
  CAPITAL_LETTER_REQUIRED: 'Wymagane wielkie litery',
  LOWERCASE_REQUIRED: 'Wymagane małe litery',
  QUESTIONS_AND_ANSWERS: {
    QUESTION_1: 'Dlaczego StretchMe?',
    QUESTION_2: 'Co to jest i jak działa natychmiastowa informacja zwrotna?',
    QUESTION_3: 'Jakie treningi znajdę w StretchMe?',
    QUESTION_4:
      'Czy muszę mieć doświadczenie w rozciąganiu, żeby korzystać ze StretchMe?',
    QUESTION_5: 'Czy mogę bezpłatnie zapoznać się z oferowanymi treningami?',
    QUESTION_6: 'Jak działa subskrypcja?',
    QUESTION_7: 'Jakie są możliwości płatności?',
    QUESTION_8: 'Na jakich urządzeniach mogę korzystać ze StretchMe?',
    QUESTION_9: 'Czego potrzebuję do treningów?',
    QUESTION_10: 'Kto prowadzi treningi?',
    QUESTION_11: 'Czy moje treningi są nagrywane?',
    ANSWER_1:
      'PhysioStretch to platforma treningowa inna niż wszystkie! Dzięki naszym autorskim treningom szpagat, sznurek oraz mostek będzie w zasięgu ręki. Po przeanalizowaniu indywidualnych celów oraz formularza wstępnego, PhysioStretch dobierze program treningowy i będzie go automatycznie dostosowywać w oparciu o Twoje postępy. Dodatkowo w czasie treningu nasz autorski algorytm będzie śledził położenie Twojego ciała i dawał informacje na temat poprawności wykonywanych przez Ciebie ćwiczeń.',
    ANSWER_2:
      'Podczas treningu aktywnie wykorzystywana jest kamera w telefonie/tablecie użytkownika. Dzięki temu, ćwiczący w czasie rzeczywistym może korygować swoje pozy. Pozwala to na zapewnienie poprawnego wykonywania ćwiczeń, co zwiększa bezpieczeństwo użytkownika. Natychmiastowa informacja zwrotna działa w oparciu o algorytmy sztucznej inteligencji, Żeby w pełni wykorzystać potencjał StretchMe potrzebujesz tylko jednego urządzenia!',
    ANSWER_3_1:
      'Na naszej platformie PhysioStretch znajdziesz wszystkie treningi oferowane przez studio:',
    ANSWER_3_2:
      'autorskie zajęcia rozciągająco-wzmacniające zwiększające mobilność ciała oraz przygotowujące do szpagatów, sznurków oraz mostków',
    ANSWER_3_3:
      'treningi Stretch Wheel skupiające się w głównej mierze na rozciągnięciu oraz wzmocnieniu kręgosłupa, pomagające wypracować poprawną postawę ciała (podczas treningów wykorzystujemy koło do jogi)',
    ANSWER_3_4:
      'praktyki jogowe, które pomogą Ci lepiej poznać swoje ciało oraz jego możliwości',
    ANSWER_3_5:
      'treningi RelaxStretch i StretchFlow dzięki, którym odpoczniesz i zrelaksujesz się po ciężkim i stresującym dniu',
    ANSWER_4:
      'Nie musisz mieć doświadczenia. Program treningowy PhysioStretch oferuje treningi na każdym poziomie zaawansowania, dostosowane do Twojego trybu życia oraz potrzeb. Nasi trenerzy będą towarzyszyć Ci podczas Twojej przygody z rozciąganiem i pomogą osiągnąć zamierzone cele.',
    ANSWER_5:
      'Tak! Oferujemy 14 dni za darmo, podczas których uzyskasz pełny dostęp do naszej biblioteki treningowej i ocenisz czy PhysioStretch jest dla Ciebie.',
    ANSWER_6_1: 'Subskrypcja StretchMe możliwa jest na okres:',
    ANSWER_6_2: 'miesiąca,',
    ANSWER_6_3: '3 miesięcy,',
    ANSWER_6_4: 'roku.',
    ANSWER_6_5:
      'Subskrypcja jest automatycznie odnawialna, zatem w przypadku braku chęci kontynuacji, należy zrezygnować z niej w aplikacji. StretchMe oferuje 14 dniowy okres próbny.',
    ANSWER_7:
      'Płatność możliwa jest w aplikacji StretchMe. Wówczas, sposobem płatności jest ten, który użytkownik ma połączony z swoim AppleID. Za dostęp do platformy internetowej możliwe jest także wykorzystanie płatności kartą poprzez stronę stretchme.app',
    ANSWER_8:
      'Z StretchMe można w pełni korzystać na urządzeniach mobilnych z systemem IOS. Możliwe jest także jedynie odtwarzanie treningów na platformie internetowej, dostępnej z każdej przeglądarki.',
    ANSWER_9:
      'Do większości naszych treningów potrzebujesz jedynie maty oraz wolnej przestrzeni. W przypadku niektórych treningów sporym ułatwieniem będzie posiadanie kostek, gum oporowych oraz koła do jogi. Jeżeli nie posiadasz obecnie tych sprzętów nie martw się, programy treningowe zostaną w pełni dostosowane do posiadanych przez Ciebie sprzętów.',
    ANSWER_10_1:
      'Wszystkie treningi PhysioStretch prowadzone są przez certyfikowanych nauczycieli, których na co dzień możesz spotkać w naszym studio stacjonarnym. Chcesz ich poznać?',
    ANSWER_10_2: 'Monika Stasiak',
    ANSWER_10_3:
      'Trenerka autorskiego programu StretchMe, inspirowanego gimnastyką, tańcem i jogą. Stretching jest nieodłączna częścią jej osobowości, tak jak pozytywne nastawienie do świata, wdzięczność, radość, otwartość umysłu. Uważa, że piękne chwile nas nie spotykają, piękne chwile trzeba tworzyć. Mówi, ze nie jest „normalna” w końcu żyje z rozciągania, który większości kojarzy się z bólem. Ona odczarowuje ten ból. Pokaże, że rozciąganie stanie się najprzyjemniejszą częścią Twojego dnia, tygodnia, po prostu wolnego czasu.',
    ANSWER_10_4: 'Natalia Zagumna',
    ANSWER_10_5:
      'Absolwentka Akademii Wychowania Fizycznego w Warszawie na kierunku fizjoterapia, certyfikowany trener personalny, pasjonatka ruchu ludzkiego ciała. Od najmłodszych lat związana ze sportem, teatrem oraz akrobatyką i tańcem. Nieustannie poszukująca nowych form wyrażania siebie. Na co dzień uczy ludzi ruchu ale jej celem jest pokazać jak piękne i wystarczające potrafi być ludzkie ciało.',
    ANSWER_10_6: 'Nikola Chmiel',
    ANSWER_10_7:
      'Była tancerka, certyfikowana nauczycielka jogi. To właśnie taniec i joga sprawiły, że pokochała rozciąganie. Dzięki nim odkryła siebie i swoją największą miłość, czyli uczenie innych. Jej zajęcia są dla każdego, prowadzone prosto z serca.',
    ANSWER_11:
      'Tylko, jeśli wyrazisz na to zgodę przed daną sesją. Jeśli tak, to dane z Twojego treningu będą użyte do poprawiania pracy algorytmu uczenia maszynowego. Podczas sesji masz możliwość wykonania zdjęć w danych pozycjach, aby móc obserwować i mierzyć swoje postępy',
  },
  QUESTIONS: 'Pytania i odpowiedzi',
  BUSINESS_REGISTRATION2: 'Rejestracja klientów biznesowych',
  BUSINESS_LOGIN: 'Logowanie klientów biznesowych',
  BUSINESS_REGISTER: 'Rejestracja klientów biznesowych',
  BUSINESS_REGISTER_SUBTITLE:
    'Jeśli jesteś nowym klientem biznesowym, możesz teraz zarejestrować się i rozpocząć korzystanie z naszych usług. Jeśli posiadasz voucher, możesz go użyć podczas rejestracji.',
  BUSINESS_LOGIN_SUBTITLE:
    'Jeśli jesteś już naszym klientem biznesowym, możesz się teraz zalogować lub przedłużyć swoją subskrypcję, używając voucheru',
  TRAIN_WITH_US: 'Ćwicz z PhysioStretch',
  SET_CONSENT: 'Aby kontynuować udziel poniższych zgód',
  OWN_RISK: 'Ćwiczę na własną odpowiedzialność',
  ALLOW_ML:
    'Wyrażam zgodę ne przetwarzanie moich danych w celu doskonalenia modeli Machine Learning',
  NEXT: 'Dalej',
  COOKIE_BANNER: {
    title: 'Pliki cookie - Pomóż nam stać się lepszymi',
    body: 'Możesz zdecydować, których plików cookie użyć, wybierając odpowiednie opcje poniżej. Pamiętaj, że Twój wybór może wpłynąć na funkcjonalność aplikacji.',
    required_only: 'Konieczne',
    analytics: 'Funkcjonalne',
    marketing: 'Wszystkie',
    manage_cookie: 'Zarządzaj plikami cookie',
  },
  COOKIE_MANAGER: 'Pliki Cookie',
  NEU: 'Nowy',
  BESONDERS_BELIEBT: 'Popularne',
  BEISPIEL_ANSEHEN: 'Pokaż przykład',
  POSE_COMPARISON: 'Porównanie pozycji',
  VERGLEICHE_DEINE_POSEN_DIREKT: 'Porównaj swoje pozy z filmem tuż po sesji.',
  SMART_MIRROR: 'inteligentne lustro',
  VERBESSERE_DICH_DIREKT_IN_DER:
    'Popraw pozy podczas sesji dzięki feedbackowi w czasie rzeczywisytm.',
  SMARTMIRROR_VERBINDEN: 'Połącz SmartMirror',
  MEHR_BER: 'Więcej o ',
  NON_FULLSCREEN: 'nie pełnoekranowy',
  BY: 'za pomocą',
  F_R_DICH: 'Dla Ciebie',
  VISUELLES_FEEDBACK_SO_FUNKTI: `Feedback w czasie rzeczywistym - jak działa`,
  MEHR_ERFAHREN: 'Dowiedz się więcej',
  CENTER_RIGHT: 'środek prawo',
  BEST_TIGEN: 'Potwierdź',
  SEND_CODE_AGAIN: 'Wyślij kod ponownie',
  ZUR_CK_ZUM_LOGIN: 'Powrót do logowania',
  PASSWORT_ZUR_CKSETZEN: 'Zresetuj hasło',
  IMPRESSUM: 'Informacje o aplikacji',
  DATENSCHUTZERKL_RUNG: 'Ochrona danych',
  COOKIE_POLICY: 'Polityka Cookie',
  NUTZUNGSBEDINGUNGEN: 'Warunki korzystania',
  LINKS_FOOTER: 'linki.stopka',
  LINKS_FOOTERDARKSOLID: 'linki.stopkaDarkSolid',
  LINKS_FOOTERDARKTRANSPARENT: 'linki.stopkaCiemnyPrzezroczysty',
  SERIE: 'Seria (',
  BILDER1: 'Obrazki)',
  ABLEHNEN: 'Odrzuć',
  ABOUT_YOU: 'o Tobie',
  HOME: 'Ekran początkowy',
  KURSE: 'kursy',
  MEIN_BEREICH: 'Moja przestrzeń',
  MEIN_ACCOUNT: 'Moje konto',
  WIE_FUNKTIONIERT_S: 'Jak to działa?',
  AUSLOGGEN: 'Wyloguj',
  HALLO: 'Cześć',
  NOCH: 'Nadal',
  TAGE: 'dni',
  FINAL: 'koniec',
  DAY: 'dzień',
  TAG: 'Dzień #',
  INTRO: '1 - Wprowadzenie',
  MEHR_BER_DIE_POSE: 'Więcej o pozie',
  MIT_HILFE_DER_POSE_COMPARISON: `Dzięki porównaniu póz oferujemy Ci osobisty i prywatny dziennik treningowy. Na poniższym przykładzie możesz zobaczyć jak działa porównanie póz. Aby zacząć korzystać z porównania póz rozpocznij swoją pierszą sesję. Dzięki porównaniu póz możesz porównywać poszczególne pozy z nauczycielem i monitorować Twoje postępy.`,
  KEINE_SORGE_DEINE_BILDER_SIN: 'Nie martw się - tylko Ty masz dosęp do zdjęć',
  SORRY: 'Przepraszam!',
  DA_HAT_WOHL_ETWAS_NICHT_FUNKTI: 'Coś musiało pójść nie tak!',
  MELDE_DICH_GERNE_BEI_UNS_UND_W: 'Skontaktuj się z nami, aby uzyskać pomoc',
  QR_CODE_ZUM_EINLOGGEN: 'Kod QR do logowania',
  BITTE_SCANNE_DEN_CODE_MIT_DER: 'Proszę zeskanować kod za pomocą',
  YUUYOGA_SMARTPHONE_APP: 'Aplikacja PhysioStretch',
  UM_ZUGANG_ZU_ERHALTEN: 'aby uzyskać dostęp',
  SMARTMIRROR: 'SmartMirror+',
  SMARTMIRROR1: 'Inteligentne Lustro',
  VERBINDUNG_ERFOLGREICH: 'Połączenie powiodło się.',
  SEHE_DIREKT_WO_DU_DICH_VERBES: 'Zobacz dokładnie, co możesz poprawić.',
  SCANNE_DEN_CODE_MIT_DER: 'Skanuj kod za pomocą',
  KAMERA_APP: 'aparat',
  AUF_DEINEM_IOS_GER_T: 'na twoim urządzeniu z systemem iOS.',
  STELLE_DEN_SMART_MIRROR_AUF_UN:
    'Skonfiguruj inteligentne lustro i dopasuj je do swojej maty do jogi.',
  STARTE_DEINE_SESSION: 'Rozpocznij sesję.',
  ERSTELLE_JETZT_DEINEN_YUU_YOGA:
    'UTWÓRZ SWOJE KONTO PhysioStretch W APLIKACJI',
  UM_UNSERE_WEB_APP_ZU_NUTZEN_M:
    'Aby korzystać z naszej aplikacji internetowej, musisz mieć konto. Możesz je utworzyć w aplikacji PhysioStretch.',
  FFNE_DAZU_EINFACH_DIE_APP_UND: `Aby założć konto otwórz aplikację PhysioStretch i kliknij "Utwórz nowe konto". Następnie wróć tutaj i zaloguj się.`,
  EINLOGGEN: 'Zaloguj się',
  HTTPS_APPS_APPLE_COM_APP_APP: `https`,
  ZUM_APP_STORE: 'Do App Store',
  JOURNEY_ERFOLGREICH_ABGESCHLOS: 'zakończono sukcesem',
  DEINE_EMAIL_ADRESSE_BEST_TIGEN: 'Potwierdź adres e-mail.',
  DU_HAST_VERSUCHT_DEINE_EMAIL_A:
    'Próbowałeś potwierdzić swój adres e-mail, niestety w tej chwili możemy to zrobić tylko w aplikacji. Otwórz e-mail lub link ponownie na telefonie komórkowym, aby przeprowadzić potwierdzenie. Dziękujemy za pomoc!',
  SMART_MIRROR1: `Inteligentne lustro`,
  VERBINDE_DEIN_PHONE: 'Podłącz telefon',
  ALLE_KURSE: 'Wszystkie Kursy',
  FAVORITEN: 'ulubione',
  VISUELLES_FEEDBACK_SO_FUNKTI1: `Feedback w czasie rzeczywistym – jak to działa`,
  ALLE_VORTEILE_VON_YUU_YOGA_IN:
    'Wszystkie korzyści PhysioStretch w 60 sekund.',
  NOCH_FRAGEN_WIR_HELFEN_DIR_GE: 'Masz pytania? Chętnie pomożemy.',
  YUU_YOGA_IST_YOGA_ZU_HAUSE_WEI: `PhysioStretch to stretching w domu. W centrum uwagi jest Twój stretching. Chcesz ćwiczyć w domu i mieć pewność, że wykonujesz ćwiczenia prawidłowo i robisz satysfakcjonujące postępy? PhysioStretch Ci to umożliwia dając Ci feedback w czasie rzeczywistym`,
  MIT_DEM_SMARTMIRROR_KANNST_DU:
    'Dzięki SmartMirror możesz porównać swoją pozę z pozą nauczyciela bezpośrednio podczas sesji i samodzielnie ją poprawić. Potrzebujesz tylko laptopa i smartfona.',
  DURCH_DIE_POSE_COMPARISON_GEBE:
    'Dzięki porównaniu pozycji, po każdej sesji dajemy Ci feedback o poszczególnych pozycjach i bezpośrednie porównanie z twoim nauczycielem stretchingu.',
  UNSERE_YOGALEHRERINNEN_HELFEN: `Nasi nauczyciele pomogą Ci wypróbować różne style treningów dostosowane do Twojego indywidualnego poziomu. Od ćwiczeń dla początkujących, przez programy mające na celu naukę bądź doskonalenie konkretnych póz, każdy znajdzie w PhysioStretch trening dla siebie.`,
  SOLLTE_DIE_RICHTIGE_SESSION_F:
    'Jeśli nie widzisz w aplikacji sesji dla siebie, napisz do nas na kontakt@stretchme.pl, a zobaczymy, czy możemy dodać więcej do programu.',
  ALLE_YUU_YOGA_KURSE_WERDEN_VON:
    'Wszystkie kursy PhysioStretch prowadzone są przez certyfikowanych nauczycieli. Chcesz ich poznać?',
  MONIKA: `Monika`,
  SCH_N_DICH_KENNENZULERNEN_IC:
    'Trenerka autorskiego programu StretchMe, inspirowanego gimnastyką, tańcem i jogą. Stretching jest nieodłączna częścią jej osobowości, tak jak pozytywne nastawienie do świata, wdzięczność, radość, otwartość umysłu. Uważa, że piękne chwile nas nie spotykają, piękne chwile trzeba tworzyć. Mówi, ze nie jest „normalna” w końcu żyje z rozciągania, który większości kojarzy się z bólem. Ona odczarowuje ten ból. Pokaże, że rozciąganie stanie się najprzyjemniejszą częścią Twojego dnia, tygodnia, po prostu wolnego czasu.',
  KAROLINA: 'Karolina',
  HEY_YOGI_ICH_BIN_RICARDA_YOG:
    'Pole dance zapoczątkował w Karolinie pasję do tańca oraz do stretchingu. By udoskonalać swoje umiejętności, zaczęła trenować rozciąganie pod wymagającym okiem Moniki. Dzięki systematyce i wytrwałości, pasja przerodziła się w miłość do rozciągania, którą Karolina przekazuje dalej podczas treningów. Ceni sobie chęć do pracy i dobre nastawienie.',
  NATALIA: 'Natalia',
  NAMAST_WIE_SCH_N_DASS_DU_DA:
    'Fizjoterapeutka, absolwentka Akademii Wychowania Fizycznego w Warszawie, certyfikowany trener personalny, pasjonatka ruchu ludzkiego ciała. Od najmłodszych lat związana ze sportem, teatrem oraz akrobatyką i tańcem. Nieustannie poszukująca nowych form wyrażania siebie. Na co dzień uczy ludzi ruchu ale jej celem jest pokazać jak piękne i wystarczające może być ludzkie ciało.',
  NIKOLA: 'Nikola',
  ICH_BIN_ZERTIFIZIERTE_YOGALEHR:
    'Była tancerka i modelka, certyfikowana nauczycielka jogi. To właśnie taniec i joga sprawiły, że pokochała rozciąganie. Dzięki nim odkryła siebie i swoją największą miłość, czyli uczenie innych. Jej zajęcia są dla każdego, prowadzone prosto z serca. ',
  MARTA: 'Marta',
  WZMACNIANIE:
    'Trochę rozważna, trochę szalona, zarażająca uśmiechem i energią, świeżo upieczona fitnessowa mama. Lubiąca dać solidny wycisk przy dobrej muzyce, ale w pierwszej kolejności słuchająca potrzeb swoich podopiecznych tak, by trening u niej był przede wszystkim przyjemnością i fajnie spędzonym czasem. Motto: „najbardziej wartościowa jesteś jak jesteś sobą”',
  EGAL_OB_NEULING_ODER_ERFAHRENE: `Niezależnie od tego, czy jesteś początkujący, czy nie, PhysioStretch oferuje Ci kursy dostosowane do Twoich potrzeb. Nasi nauczyciele będą Ci towarzyszyć w Twojej podróży i pomogą Ci osiągnąć oczekiwane efekty.`,
  VERBINDEST_DU_LAPTOP_UND_SMART: `Jeśli połączysz laptopa i smartfona na początku sesji, PhysioStretch pomoże Ci wydobyć jeszcze więcej z sesji dzięki feedbackowi w czasie rzeczywistym. Użyj aparatu swojego smartfona, aby zobaczyć siebie obok nauczyciela jogi na dużym ekranie.`,
  DIE_VERBINDUNG_DER_BEIDEN_GER:
    'Urządzenia łączy się poprzez zeskanowanie kodów QR za pomocą smartfona. Opcję i instrukcje dotyczące wizualnej informacji zwrotnej otrzymasz, gdy tylko wybierzesz film.',
  ALTERNATIV_ZUM_LAPTOP_KANNST_D:
    'Zamiast laptopa możesz użyć tabletu jeśli ekran jest wystarczająco duży.',
  DEINE_DATEN_GEH_REN_DIR_DESWE:
    'Twoje dane należą do Ciebie. Dlatego ani nasz zespół, ani nikt inny nie może Cię obserwować podczas sesji jogi.',
  ENTSCHEIDEST_DU_DICH_DEN_SMART:
    'Jeśli zdecydujesz się aktywować Smart Mirror, Twój obraz na żywo zostanie przeniesiony ze smartfona na laptopa lub tablet, ale nie zostanie zapisany.',
  M_CHTEST_DU_ZUS_TZLICH_DIE_POS:
    'Jeśli chcesz skorzystać z porównania pozycji, podczas sesji zostaną zrobione zdjęcia Twoich indywidualnych póz i wyświetlone na końcu. Masz dostęp do galerii zdjęć z wszystkich Twoich poprzednich sesji',
  WARUM_YUU_YOGA_WELCHE_VORTEIL:
    'Dlaczego PhysioStretch? Jakie są zalety porównania pozycji i inteligentnego lustra?',
  WELCHE_VERSCHIEDENEN_KURSE_UND:
    'Jakie różne zajęcia i sesje oferuje PhysioStretch?',
  VON_WEM_WERDEN_DIE_SESSIONS_AN: 'Kto prowadzi sesje?',
  MUSS_ICH_BEREITS_YOGA_ERFAHRUN:
    'Czy muszę mieć doświadczenie w rozciąganiu, żeby koszystać z PhysioStretch?',
  MIT_WELCHEN_GER_TEN_KANN_ICH_Y:
    'Z jakimi urządzeniami mogę używać PhysioStretch?',
  WANN_WERDEN_BILDER_UND_VIDEOS:
    'Kiedy będą mi robione zdjęcia i filmy i kto może je zobaczyć?',
  ZUR_POSE_COMPARISON: 'do porównania pozycji',
  COURSECAROUSEL_ITEM: 'kurs karuzela-pozycja-',
  SO_FUNKTIONIERT_S: 'Jak to działa',
  DEINE_POSE_COMPARISONS: 'Twoje Porównania Póz',
  KURSE_F_R_DICH: 'Kursy dla Ciebie',
  DEINE_FAVORITEN: 'Twoje ulubione',
  DEINE_TRAINERINNEN: 'Twoi trenerzy',
  HOL_DIR_DIE_APP: 'Pobierz aplikację.',
  UM_DIR_YOGA_IN_BESTER_QUALIT_T:
    'Aby móc oferować treningi w najlepszej jakości, obecnie możesz używać PhysioStretch na swoim smartfonie tylko z naszą aplikacją. Po prostu pobierz ją z App Store lub Google Play.',
  MEINE_SESSIONS_BISHER: 'Moje dotychczasowe sesje',
  MEIN_FORTSCHRITT: 'Mój postęp',
  DEINE_POSE_COMPARISONS_PER_S: `Porównania Twojej Pozy - na sesji`,
  BEREITS_GESTARTETE_KURSE: 'Rozpoczęte kursy',
  DEINE_JOURNEY: 'Twój',
  ABGESCHLOSSENE_SESSIONS: 'Ukończone sesje',
  DIESE_WOCHE: 'W tym tygodniu',
  DIESEN_MONAT: 'W tym miesiącu',
  DIESES_JAHR: 'W tym roku',
  DEINE_EMAIL_ADRESSE: 'Twój adres email',
  ANGEMELDET_SEIT: 'Zarejestrowany od',
  DEIN_AKTUELLES_ABONNEMENT: `Twoja obecna subskrypcja`,
  GEKAUFT_AM: '(kupiony w',
  MAILTO_INFO_YUUXYOGA_DE_SUBJEC: `wyślij do`,
  NUR_NOCH_WENIGE_SCHRITTE_ENTFE: 'Zaledwie kilka kroków...',
  BITTE_W_HLE_NUN_AUF_DIESEM_GER:
    'Teraz wybierz sesję, którą chcesz rozpocząć na tym urządzeniu.',
  ZUR_AUSWAHL: 'Wybierz',
  BITTE_MELDE_DICH_NUN_AUF_DIESE:
    'Teraz zaloguj się na tym urządzeniu i wybierz sesję, którą chcesz rozpocząć.',
  ZUM_LOGIN: 'Do logowania',
  AN_VORDEFINIERTEN_ZEITPUNKTEN: `W ustalonych momentach robimy zdjęcie Twojego treningu, dzięki czemu możesz je teraz porównać z nauczycielem i udostępniać swoje postępy w mediach społecznościowych.`,
  DEIN_YUU_YOGA_ABO_IST_ABGELAUF: 'Twoja subskrypcja PhysioStretch wygasła.',
  MOMENTAN_HAST_DU_KEINEN_ZUGRIF:
    'Obecnie nie masz już dostępu do treści PhysioStretch. Aby ponownie aktywować subskrypcję, skorzystaj z aplikacji PhysioStretch.',
  HIER_KANNST_DU_EINFACH_UND_UMK:
    'Tutaj możesz w prosty sposób reaktywować swoją subskrypcję. Po prostu otwórz aplikację, wybierz nowy pakiet i aktywuj go poprzez zakup w aplikacji.',
  UNG_LTIGE_E_MAIL_ADRESSE: 'Niepoprawny adres email.',
  JETZT_VORMERKEN: 'Zarezerwuj teraz',
  F_R_DIE_ANDROID_VERSION: 'dla wersji na Androida.',
  ICH_M_CHTE_INFORMIERT_WERDEN:
    'Chciałbym być poinformowany, gdy tylko aplikacja PhysioStretch będzie dostępna również jako aplikacja na Androida.',
  ABSCHICKEN: 'Wyślij',
  AKTUELL_NUR: 'Obecnie tylko',
  F_R_IOS_VERF_GBAR: 'dostępny na iOS.',
  MOMENTAN_BIETEN_WIR_YUU_YOGA_N:
    'Obecnie PhysioStretch oferujemy tylko jako aplikację na iOS. Planowana jest wersja na smartfony z Androidem.',
  LASS_DICH_GERNE_VORMERKEN_UND:
    'Zapisz się, a poinformujemy Cię, gdy tylko będzie dostępny.',
  WIR_MELDEN_UNS: 'Skontaktujemy się!',
  DANKE_F_R_DEIN_INTERESSE: 'Dziękuję za zainteresowanie.',
  LEIDER_KONNTE_DIE_SEITE_NICHT: `Przepraszamy, nie można znaleźć strony`,
  ANWENDUNGSBEREICH: 'Szereg zastosowań',
  DIESE_COOKIE_POLICY_GILT_ERG_N:
    'Niniejsza polityka dotycząca plików cookie ma zastosowanie jako uzupełnienie ogólnego oświadczenia o ochronie danych dla naszej strony internetowej i opisuje rodzaj, zakres, cel i podstawę prawną przetwarzania danych w przypadku plików cookie. Ogólne oświadczenie o ochronie danych dotyczy również wszystkich dalszych informacji.',
  WAS_SIND_COOKIES: 'Co to są pliki cookies?',
  UM_IHNEN_EINEN_UMFANGREICHEN_F: `Aby zaoferować Ci szeroki zakres funkcji, aby korzystanie z nich było wygodniejsze i móc zoptymalizować nasze oferty, używamy tak zwanych plików "cookies". Pliki cookie to małe pliki, które są przechowywane na Twoim urządzeniu końcowym za pomocą Twoj przeglądarka internetowa.`,
  KATEGORIEN_VON_COOKIES: `Kategorie plików cookie`,
  WIR_SETZEN_COOKIES_ZU_UNTERSCH: `Używamy plików cookie do różnych celów i o różnych funkcjach. Rozróżniamy, czy plik cookie jest technicznie konieczny (konieczność techniczna), jak długo jest przechowywany i używany (okres przechowywania) oraz czy jest tworzony przez samą naszą stronę internetową, czy przez osoby trzecie i przez którego dostawcę został ustawiony ten plik cookie (dostawca plików cookie).`,
  TECHNISCHE_ERFORDERLICHKEIT: `Konieczność techniczna`,
  TECHNISCH_ERFORDERLICHE_COOKIE: `Ciasteczka konieczne technicznie są absolutnie niezbędne do prawidłowego działania naszej witryny. Te pliki cookie są ustawiane automatycznie w momencie wejścia na naszą witrynę internetową lub skorzystania z określonej funkcji, chyba że zablokowałeś ustawienie plików cookie, dokonując odpowiednich ustawień w swoim urządzeniu końcowym lub przeglądarce. `,
  TECHNISCH_NICHT_ERFORDERLICHE: `Niekonieczne technicznie pliki cookies: używamy też plików cookies, które nie są ściśle niezbędne, na przykład w celu poprawy komfortu i wydajności strony internetowej lub w celu zapisania niektórych dokonanych przez Ciebie ustawień. Używamy również nie technicznie koniecznych plików cookie w celu ustalenia informacji o częstotliwości korzystania z niektórych obszarów naszej stronę internetową, abyśmy mogli dostosować je do Twoich potrzeb.`,
  SPEICHERDAUER: 'Okres przechowywania',
  SESSION: 'Sesja',
  HIER: 'tutaj',
  SESSION_COOKIES_DIE_MEISTEN_C: `Sesyjne pliki cookie są wymagane do bieżącego połączenia serwisowego lub sesji (sesji) i są usuwane lub tracą ważność w momencie opuszczenia naszej strony internetowej lub wygaśnięcia bieżącej sesji (tzw. "sesyjne pliki cookie"). np. do przechowywania niektórych danych podczas Twojej sesji Zachowania informacji, takich jak Twój login do naszego serwisu.`,
  PERMANENT_COOKIES_NUR_VEREINZ: `Stałe pliki cookie są przechowywane przez pewien czas, na przykład po to, aby móc Cię rozpoznać, gdy ponownie odwiedzasz naszą stronę internetową w późniejszym czasie i aby móc wywołać zapisane ustawienia. Umożliwia to na przykład dostęp do szybciej lub wygodniej na naszej stronie internetowej lub nie musisz ponownie wprowadzać niektórych ustawień, takich jak wybrany język. Stałe pliki cookie są przechowywane po określonym czasie, automatycznie usuwane.`,
  COOKIE_ANBIETER: 'Dostawca Plików Cookie',
  DRITTANBIETER_COOKIES_SOGENAN: `Zewnętrzne pliki cookie są ustawiane i wykorzystywane przez inne witryny lub witryny internetowe, na przykład przez dostawców narzędzi do analizy sieci. Bardziej szczegółowe informacje na temat narzędzi do analizy sieci można znaleźć w dalszej części niniejszej polityki plików cookie. Zewnętrzni dostawcy mogą również korzystać pliki cookie do wyświetlania reklam lub integracji treści z sieci społecznościowych, takich jak wtyczki społecznościowe `,
  EINSATZ_VON_TOOLS_ZUR_WEBANALY: 'Korzystanie z narzędzi do analizy sieci',
  WIR_VERWENDEN_IM_BEREICH_DER_N: `W zakresie analizy użytkowania korzystamy z usługi analizy internetowej GoogleAnalytics, oferowanej przez Google Ireland Ltd., Gordon House, BarrowStreet, Dublin 4, Irlandia. Google Analytics wykorzystuje pliki cookie stron trzecich w celu określenia częstotliwości korzystania z niektórych obszary naszej witryny i preferencje Informacje generowane przez plik cookie na temat korzystania z niego (w tym skrócony adres IP) są przesyłane na serwer Google w USA i tam przechowywane. Google wykorzysta te informacje w naszym imieniu do oceny korzystania z naszej witryny, aby sporządzać dla nas raporty i dostarczać dodatkowe informacje związane ze stroną Aby świadczyć usługi związane z analityką internetową Informacje na temat ochrony i bezpieczeństwa danych w Google Analytics można znaleźć na stronie https//support.google.com/analytics/answer/6004245?hl =pl.`,
  WIR_SETZEN_F_R_UNSERE_MARKETIN: `Korzystamy z usługi Piksel Facebooka (dostawca FacebookIreland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irlandia) do naszych działań marketingowych oraz w obszarze analizy użytkowania Reklama na Facebooku i jego partnerach jest wtedy wyświetlana tylko tym użytkownikom, którzy ze względu na ich sposób użytkowania mogliby być zainteresowani odpowiednimi ofertami (tzw. Custom audiences). Jeśli logujesz się do Facebooka w związku z korzystaniem z naszej strony internetowej lub aplikacji, nawiązywane jest połączenie z serwerem Facebook i ta informacja jest połączona z Twoim kontem na Facebooku, dzięki czemu w razie potrzeby możesz zostać przyporządkowany do grupy docelowej.Jednakże Facebook może również zbierać dane, jeśli nie jesteś jednocześnie zalogowany na swoim koncie na Facebooku. Możemy również korzystać z Facebooka piksel, aby statystycznie zrozumieć, która Promocja wizyty na naszej stronie internetowej doprowadziła Cię na stronę. Umożliwia nam to ocenę skuteczności naszych reklam do celów statystycznych i badań rynkowych oraz na tej podstawie optymalizację przyszłych działań reklamowych. Więcej informacji na temat ochrony danych w związku z pikselem Facebooka można znaleźć pod adresem https://de-de.facebook.com/privacy/explanation.`,
  WIR_VERWENDEN_ZUR_ANALYSE_DER: `Korzystamy z usługi Sentry (dostawca oprogramowania funkcjonalnego, 132 Hawthorne Street, San Francisco, CA94107, USA) w celu analizy stabilności aplikacji i identyfikacji usterek programowania. Sentry wykorzystuje pliki cookie stron trzecich do zbierania informacji do analizy w naszym imieniu, takie jak informacje o używanym urządzeniu lub w momencie wystąpienia błędu i udostępnić je nam. Więcej informacji na temat ochrony i bezpieczeństwa danych w Sentry można znaleźć pod adresem https://sentry.io/privacy/.`,
  WIR_VERWENDEN_IM_BEREICH_DER_N1: `W zakresie analizy użytkowania korzystamy z usługi analizy internetowej FullStory (dostawca FullStory, Inc., 1745 Peachtree St NE, Suite G, Atlanta GA 30309, USA). strona internetowa (wybrane linki, specyfikacja urządzenia końcowego, z którego pobierasz dane, kliknięcia, ruchy myszką). FullStory wykorzysta informacje zebrane w naszym imieniu i na podstawie umowy o realizację zamówienia, w celu oceny korzystania przez Ciebie z naszej strony internetowej, tworzenia raportów o czynnościach związanych z korzystaniem dla nas i świadczeniem innych powiązanych usług. Więcej informacji na temat ochrony danych w odniesieniu do FullStory można znaleźć pod adresem https://www.fullstory.com/legal/privacy-policy.`,
  WIR_NUTZEN_TRACKING_TECHNOLOGI: `Korzystamy z technologii śledzenia od dostawcy Singular (Singular Labs Inc., 181 South Park Street, Apt 2, San Francisco, CA 94107, USA). Aby stale ulepszać nasze usługi, używamy Singular do zbierania danych o korzystaniu z naszych usług i o tym, które kampanie/strony zewnętrzne zostały skierowane do naszych usług. W szczególności wykorzystywane są w tym celu informacje, które przesyła do nas Twoje urządzenie, takie jak adres IP lub identyfikator reklamowy, który jest przypisywany przez system operacyjny urządzenia. Więcej informacji na temat ochrony danych w związku z Singular można znaleźć na stronie https //www.singular.net/privacy-policy.`,
  RECHTSGRUNDLAGE_F_R_DEN_EINSAT: `Podstawa prawna wykorzystywania plików cookies`,
  SOWEIT_SIE_DIE_WEBSITE_NUTZEN: `Jeśli korzystasz ze strony internetowej, używamy nie technicznie koniecznych plików cookie tylko wtedy, jeśli wyraziłeś zgodę poprzez wyrażenie zgody. Podstawą prawną jest wtedy art. 6 ust. 1 lit. a) RODO. Możesz odwołać swoją zgodę w dowolnym momencie ze skutkiem na przyszłość poprzez dezaktywację opcji opt-in poprzez odpowiednie opcje ustawień na stronie internetowej lub w przeglądarce. Podstawą prawną stosowania technicznie wymaganych plików cookie jest art. 6 ust. odpowiednio.`,
  L_SCHUNG_UND_WIDERSPRUCH_GEGEN: `Usunięcie i sprzeciw wobec wykorzystywania plików cookies`,
  DIE_AKZEPTANZ_VON_COOKIES_IST: `Akceptacja plików cookies nie jest obowiązkowa; jeżeli nie chcesz, aby pliki cookies były zapisywane na Twoim urządzeniu końcowym, możesz wyłączyć odpowiednią opcję w ustawieniach systemowych swojego urządzenia końcowego lub przeglądarki. Zapisane pliki cookies możesz w każdej chwili usunąć w zakładce ustawienia systemowe Twojego urządzenia końcowego lub przeglądarki, jednak brak akceptacji plików cookies może prowadzić do ograniczeń funkcjonalnych naszych ofert.`,
  LISTE_DER_COOKIES: `Lista plików cookies`,
  IM_EINZELNEN_K_NNEN_BEIM_AUFRU: `W szczególności podczas odwiedzania naszej strony internetowej mogą być przechowywane następujące pliki cookie`,
  EINSTELLUNGEN_ZU_COOKIES_ANZEI: 'Pokaż ustawienia cookies',
  LINKS_DEFAULT: 'lewy.domyślny',
  ANBIETER: `Oferent`,
  INITIUM_GMBH: `PhysioStretch sp. z o.o.`,
  STRALAUER_ALLEE: `Lubomirskiego 20`,
  BERLIN: `Kraków`,
  DEUTSCHLAND: `Polska`,
  KONTAKT: 'Kontakt',
  TELEFONNUMMER: 'Numer telefonu',
  E_MAIL: 'E-mail',
  VERTRETEN_DURCH: '',
  MATTIAS_ULBRICH_STEFAN_ZERWEC: '',
  REGISTRIEREINTRAG: 'wpis do rejestru',
  REGISTERGERICHT_AMTSGERICHT_C: `Sąd Rejestrowy`,
  HRB_NR_B: `HRB nr.`,
  UST_IDENT_NR_DE: `Nr VAT`,
  VERBRAUCHERINFORMATION_GEM_SS: `Informacje konsumenckie zgodnie z ustawą o rozstrzyganiu sporów konsumenckich`,
  DIE_INITIUM_GMBH_IST_NICHT_BER: `PhysioStretch sp. Z o.o. nie jest skłonna ani zobowiązana do brania udziału w procedurze rozstrzygania sporów przed konsumencką komisją arbitrażową.`,
  WIR_DIE_INITIUM_GMBH_NACHFOL: `My, PhysioStretch sp. Z o.o. (dalej "my" lub "PhysioStretch"), cieszymy się, że zainteresowała Cię nasza oferta online PhysioStretch, którą oferujemy za pośrednictwem aplikacji i dodatkowo za pośrednictwem strony internetowej (dalej "oferta online"). Bardzo poważnie traktujemy ochronę Twoich danych osobowych.Przetwarzanie Twoich danych osobowych odbywa się wyłącznie w ramach ustawowych przepisów prawa o ochronie danych, w szczególności ogólnego rozporządzenia o ochronie danych (dalej "RODO"). oświadczenie, że informujemy Cię o przetwarzaniu Twoich danych osobowych oraz o przysługujących Ci prawach jako osoby, której dane dotyczą w ramach oferty internetowej.`,
  SOFERN_WIR_VON_EXTERNEN_SOCIAL: `Jeśli odwołujemy się do niniejszego oświadczenia o ochronie danych z zewnętrznych profili mediów społecznościowych, poniższe wyjaśnienia mają zastosowanie tylko w zakresie, w jakim przetwarzanie odbywa się w naszym obszarze odpowiedzialności i o ile nie podano bardziej szczegółowych, a zatem nadrzędnych informacji na temat ochrony danych w kontekst takich profili w mediach społecznościowych.`,
  VERANTWORTLICHER_UND_DATENS: '1. Odpowiedzialny i inspektor ochrony danych',
  VERANTWORTLICH_F_R_DIE_DATENVE: `Odpowiedzialnym za przetwarzanie danych w rozumieniu przepisów o ochronie danych jest`,
  SOLLTEN_SIE_FRAGEN_ODER_ANREGU:
    'W przypadku jakichkolwiek pytań lub sugestii dotyczących ochrony danych prosimy o kontakt. Z naszym inspektorem ochrony danych można skontaktować się w następujący sposób',
  BEAUFTRAGTER_F_R_DATENSCHUTZ: 'Oficer Prywatności',
  DATENSCHUTZBEAUFTRAGTER_INITIU: `kontakt@stretchme.pl`,
  GEGENSTAND_DES_DATENSCHUTZE: '2. Przedmiot ochrony danych',
  GEGENSTAND_DES_DATENSCHUTZES_I: `Przedmiotem ochrony danych jest ochrona danych osobowych. Są to wszelkie informacje dotyczące zidentyfikowanej lub możliwej do zidentyfikowania osoby fizycznej (tzw. podmiotu danych). Obejmuje to informacje takie jak imię i nazwisko, adres pocztowy, adres e-mail lub numer telefonu ale również inne informacje, które powstają w ramach korzystania z oferty internetowej, w szczególności informacje o rozpoczęciu, zakończeniu i zakresie korzystania oraz transmisji Twojego adresu IP.`,
  ZWECKE_UND_RECHTSGRUNDLAGEN: `3. Cele i podstawa prawna przetwarzania danych`,
  NACHFOLGEND_ERHALTEN_SIE_EINEN: `Poniżej otrzymasz przegląd celów i podstaw prawnych przetwarzania danych w kontekście oferty online. W każdym przypadku przetwarzamy dane osobowe zgodnie z wymogami prawnymi, nawet jeśli w poszczególnych przypadkach istnieje inna podstawa prawna niż podano poniżej.`,
  DIE_BEREITSTELLUNG_PERSONENBEZ: `Podanie przez Ciebie danych osobowych może być wymagane przepisami prawa lub umową lub być konieczne do zawarcia umowy. Osobno poinformujemy Cię, czy powinieneś być zobowiązany do podania danych osobowych i jakie byłyby wtedy konsekwencje ich niepodania być (np. utrata roszczeń lub nasze zawiadomienie nie jest w stanie wykonać żądanej usługi bez podania określonych informacji). Korzystanie z oferty online jest zasadniczo możliwe bez rejestracji. Korzystanie z poszczególnych usług i funkcji może wymagać wcześniejszej rejestracji. Nawet jeśli korzystasz z naszej oferty online bez rejestracji, dane osobowe mogą być nadal przetwarzane.`,
  ERF_LLUNG_VERTRAGLICHER_UN: `3.1 Wypełnianie zobowiązań umownych i przedumownych`,
  WIR_VERARBEITEN_IHRE_PERSONENB: `Przetwarzamy Twoje dane osobowe, jeśli jest to konieczne do wykonania umowy, której jesteś stroną lub do wykonania działań przedumownych, które są zgodne z Twoim żądaniem. Przetwarzanie danych odbywa się na podstawie art. 6 ust. 1 lit. b) DSGVO Celem przetwarzania jest umożliwienie korzystania z naszych konkretnych produktów i usług w ramach oferty online.W tym zakresie prosimy również o zapoznanie się z opisami tych produktów i usług w ramach oferty online.W tym konkretnym przypadku są to w szczególności następujące funkcje`,
  REGISTRIERUNG_F_R_EIN_KONTO: '- rejestracja konta',
  MIT_DER_REGISTRIERUNG_F_R_EIN:
    'Rejestrując konto PhysioStretch za pomocą swojego adresu e-mail, wybierasz nazwę użytkownika i hasło, które sam wybierasz i możesz dodać dodatkowe informacje do swojego konta za pomocą ustawień konta, jeśli chcesz. Za pośrednictwem swojego konta możesz następnie korzystać z funkcji Zarządzaj i treści (przegląd ukończonych szkoleń itp.),',
  WIR_VERWENDEN_ZUR_REGISTRIERUN: `Korzystamy z tak zwanej "procedury pojedynczego logowania" do rejestracji i rejestracji, obejmującej usługi dostawców zewnętrznych. Za pomocą procedury jednokrotnego logowania możesz zalogować się jako zalogowany użytkownik Facebook (odpowiedzialny Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irlandia), Google (odpowiedzialny Google Ireland Ltd., Gordon House, BarrowStreet, Dublin 4, Irlandia) lub Apple (odpowiedzialny Apple Inc., Infinite Loop, Cupertino, CA 95014, USA) dla konta PhysioStretch zarejestruj się i zaloguj. Oznacza to, że nie musisz pamiętać nowego hasła do PhysioStretch. Alternatywnie możesz zarejestrować się i zalogować za pomocą swojego adresu e-mail. Twój adres e-mail i wybrane przez Ciebie hasło.`,
  SOLLTEN_SIE_SICH_F_R_DAS_SINGL: `Jeśli zdecydujesz się skorzystać z procedury jednokrotnego logowania, zostaniesz przekierowany do maski logowania/rejestracji swojego konta u odpowiedniego dostawcy, za pomocą którego przetwarzana jest procedura jednokrotnego logowania. Tutaj rejestrujesz się za pomocą swojej lokalnej nazwy użytkownika i hasło Otrzymujemy wiadomość od dostawcy, że pomyślnie zalogowałeś się i rejestracja lub logowanie zostało zakończone.Podczas tego procesu nie przychodzimy do dostawcy z Twoim hasłem lub nazwą użytkownika, przez które procedura jednokrotnego logowania będą przetwarzane będą w kontakcie. Jeśli zarejestrujesz się lub zalogujesz pomyślnie, musisz potwierdzić dostawcy, że możemy uzyskać dostęp do twoich podstawowych danych profilu (patrz poniżej dla odpowiedniego dostawcy). Oznacza to, że nie musisz utrzymywać swojego podstawowego profilu dane w PhysioStretch i aktualizować je (jeśli zmieni się Twój adres). Jeśli zdecydujesz się nie łączyć swojego konta PhysioStretch z kontem w Anbi w którym przetwarzana jest procedura jednokrotnego logowania, odpowiednie ustawienia są dostępne u tego dostawcy.`,
  WENN_SIE_EIN_YUU_YOGA_KONTO_B: `Jeśli utworzysz konto PhysioStretch za pośrednictwem Facebooka lub zalogujesz się na konto PhysioStretch za pośrednictwem Facebooka, Facebook prześle nam pewne informacje z Twojego konta na Facebooku: imię i nazwisko, adres URL zdjęcia, identyfikator użytkownika, adres e-mail. Więcej informacji o Facebooku i ustawieniach prywatności znajdują się w informacjach o ochronie danych Facebooka pod`,
  HTTPS_WWW_FACEBOOK_COM_POLIC: `https`,
  ZU_ENTNEHMEN_WENN_SIE_EIN_YUU:
    '. Jeśli utworzysz konto PhysioStretch za pośrednictwem Google lub zalogujesz się na konto PhysioStretch za pośrednictwem Google, Google prześle nam niektóre szczegóły Twojego konta Google: imię i nazwisko, adres URL zdjęcia, identyfikator użytkownika, adres e-mail. Więcej informacji na temat Google i ustawień prywatności można znaleźć informacje o ochronie danych Google pod',
  HTTPS_POLICIES_GOOGLE_COM_PR: `https`,
  ZU_ENTNEHMEN_WENN_SIE_EIN_YUU1:
    '. Jeśli utworzysz konto PhysioStretch za pośrednictwem Apple lub zalogujesz się na konto PhysioStretch za pośrednictwem Apple, Apple przekaże nam pewne informacje dotyczące nazwy Twojego konta Apple, adresu URL zdjęcia, identyfikatora użytkownika, adresu e-mail.Dowiedz się więcej o Apple i ustawieniach prywatności są informacje Apple o ochronie danych na',
  HTTPS_WWW_APPLE_COM_LEGAL_PR: `https`,
  ZU_ENTNEHMEN: 'odnosić się do.',
  ZUR_DURCHF_HRUNG_DER_AUTHENTIF:
    'Korzystamy z usług Amazon Cognito (dostawca Amazon Web Services, Inc., P.O. Box 81226, Seattle, WA 98108-1226, USA) w celu przeprowadzenia uwierzytelniania. Aby uzyskać więcej informacji, zobacz',
  HTTPS_DOCS_AWS_AMAZON_COM_DE: `https`,
  HINWEISE_ZUM_DATENSCHUTZ_BEI:
    '. Aby uzyskać informacje na temat ochrony danych w Amazon Cognito, zobacz',
  HTTPS_AWS_AMAZON_COM_DE_PRIV: `https`,
  DIE_ERHOBENEN_DATEN_VERARBEITE: `Zgromadzone dane przetwarzamy na podstawie art. 6 ust. 1 lit. b) i f) RODO w celu założenia Twojego konta i identyfikacji Cię przy każdorazowej rejestracji. Oprócz przeprowadzenia wnioskowanej przez Ciebie procedury lub procedury monitorować zainteresowanie, aby proces rejestracji i rejestracji był sprawny i wygodny.`,
  NUTZUNG_DER_FUNKTIONEN: `- Korzystanie z funkcji`,
  YUUYOGA_BIETET_DIE_M_GLICHKEIT: `PhysioStretch oferuje możliwość dostępu do biblioteki treści wideo, takich jak treningi jogi i ćwiczenia, oraz otrzymywania spersonalizowanych informacji zwrotnych na temat rodzaju wykonania podczas sesji treningowej i po niej. Spersonalizowana informacja zwrotna na temat postępów jest oparta na nagraniach wideo z treningu. Istnieją następujące warianty nagrywania wideo`,
  SMART_MIRROR_SIE_K_NNEN_SIC:
    '- Smart Mirror widzi tę samą perspektywę boczną co trener. Zasadniczo nie ma stałego nagrywania strumienia wideo. Poszczególne migawki można zapisać, aby zapewnić następujące funkcje.',
  POSE_COMPARISON_NACH_DEM_TR:
    '- Porównanie pozycji Użyj migawek, aby porównać kluczowe pozy podczas treningu między wykonaniem a wykonaniem trenera. Migawki są trwale rejestrowane i są dostępne na Twoim koncie.',
  POSE_PROGRESS_SIE_K_NNEN_DI:
    '- Pose ProgressView kluczowe migawki pozy w czasie, aby zwizualizować swoje postępy. Migawki są trwale rejestrowane i są dostępne do wyświetlenia na swoim koncie.',
  SOWEIT_DAUERHAFTE_VIDEOAUFZEIC:
    'O ile trwałe nagrania wideo są tworzone jako część migawek, są one zazwyczaj przechowywane do momentu usunięcia konta lub usunięcia pojedynczych migawek w ramach zarządzania kontem. Upewnij się, że żadne inne osoby nie są nagrywane.',
  BITTE_BEACHTEN_SIE_DIE_WEITERE: `Zwróć uwagę na dalsze informacje dotyczące opisu funkcji w warunkach użytkowania. Jeśli do korzystania z funkcji wymagana jest płatna subskrypcja, otrzymasz dalsze informacje na temat związanego z tym przetwarzania danych oraz informacji wymaganych w ramach procesu zamawiania. `,
  DIE_BEI_DER_NUTZUNG_DER_FUNKTI: `Dane zbierane podczas korzystania z funkcji, a w szczególności nagrań wideo oraz dane do weryfikacji i rozliczania abonamentów przetwarzamy na podstawie art. 6 ust. 1 lit. b) RODO w celu udostępnienia Ci funkcji, które wybrałeś jako część oferty.`,
  ERF_LLUNG_RECHTLICHER_VERF: `3.2 Wypełnianie obowiązków prawnych`,
  WIR_VERARBEITEN_IHRE_PERSONENB1: `Przetwarzamy Twoje dane osobowe w celu wypełnienia obowiązków prawnych, którym podlegamy. Przetwarzanie danych odbywa się na podstawie art. 6 ust. 1 lit. c) RODO. Obowiązki mogą wynikać m.in. lub prawa karnego. Cele przetwarzania wynikają z obowiązku prawnego; przetwarzanie zwykle służy wypełnieniu obowiązków kontrolnych i informacyjnych.`,
  WAHRUNG_BERECHTIGTER_INTER: `3.3 Ochrona prawnie uzasadnionych interesów`,
  WIR_VERARBEITEN_IHRE_PERSONENB2: `Przetwarzamy Twoje dane osobowe również w celu ochrony naszych uzasadnionych interesów lub interesów osób trzecich, chyba że Twoje interesy, które wymagają ochrony Twoich danych osobowych, są nadrzędne. Przetwarzanie danych odbywa się na podstawie art. 6 ust. 1 lit. f RODO. realizowane w celu ochrony prawnie uzasadnionych interesów w następujących celach lub w celu ochrony następujących interesów.`,
  WEITERENTWICKLUNG_VON_PRODUK: `- Dalszy rozwój produktów, usług i ofert wsparcia oraz innych środków kontroli transakcji i procesów biznesowych`,
  VERBESSERUNG_DER_PRODUKTQUAL:
    '- Poprawa jakości produktów, eliminacja błędów i usterek m.in. za pomocą analizy danych użytkowych i opinii klientów',
  BEARBEITUNG_VON_AUSSERVERTRA:
    '- Przetwarzanie zapytań pozaumownych od zainteresowanych stron i klientów',
  SICHERSTELLUNG_RECHTSKONFORM: `- Zapewnienie zgodnego z prawem działania, zapobieganie i ochrona przed naruszeniami prawa (w szczególności przestępstwami), dochodzenie i obrona przed roszczeniami prawnymi`,
  GEW_HRLEISTUNG_VON_VERF_GBAR: `- Zapewnienie dostępności, działania i bezpieczeństwa systemów technicznych oraz zarządzania danymi technicznymi`,
  WENN_SIE_DAS_ONLINE_ANGEBOT_AU: `Kiedy wywołasz ofertę online, dane dotyczące Twojego urządzenia końcowego i korzystania z oferty online będą przetwarzane i przechowywane w tak zwanym pliku dziennika. Dotyczy to w szczególności danych technicznych, takich jak data i godzina dostępu , czas trwania wizyty, rodzaj urządzenia końcowego, używany system operacyjny, wykorzystywane funkcje, ilość przesyłanych danych, adres IP i adres URL strony odsyłającej.Przetwarzamy te dane w celu zapewnienia technicznego działania oraz identyfikacji i usuwania usterek. w interesie stałego zapewnienia funkcjonalności technicznej. Nie wykorzystujemy tych danych w celu wyciągania wniosków na temat Twojej osoby.`,
  EINWILLIGUNG: `3.4 Zgoda`,
  WIR_VERARBEITEN_IHRE_PERSONENB3: `Przetwarzamy Twoje dane osobowe na podstawie odpowiedniej zgody. Przetwarzanie danych odbywa się na podstawie art. 6 ust. treść oświadczenia o wyrażeniu zgody. Zgodę możesz cofnąć w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody aż do odwołania.`,
  SIE_K_NNEN_SICH_BEI_DER_REGIST: `Możesz zarejestrować się do naszego newslettera z informacjami o PhysioStretch podczas rejestracji lub podczas zarządzania kontem. Wysyłamy newslettery dopiero po rejestracji, tj. za Twoją zgodą na podstawie art. 6 ust. 1 lit. a) Newsletter DSGVO, którego treść jest konkretnie opisane, decydują o zakresie zgody. Ponadto nasz newsletter zawiera informacje o funkcjach i działaniach związanych z PhysioStretch. Zgodę na otrzymywanie naszego newslettera możesz w każdej chwili odwołać, rezygnując z otrzymywania newslettera. Link do rezygnacji z subskrypcji Możesz dowiedzieć się, jak skorzystać z tego prawa na końcu każdego newslettera.`,
  BEI_DER_ERHEBUNG_UND_VERWALTUN: `Zbierając Twój adres e-mail i zarządzając nim, korzystamy z usługi MailChimp (dostawca Rocket Science Group, LLC, 675 Ponce De LeonAve NE 5000, Atlanta, GA 30308, USA). Aby nasz newsletter był dla Ciebie jak najbardziej interesujący, MailChimp w naszym imieniu, zachowanie użytkownika bez wyciągania wniosków na temat Twojej osoby lub tożsamości.W ten sposób możemy dowiedzieć się, na przykład, ilu naszych czytelników otworzyło nasz biuletyn i które linki były odwiedzane szczególnie często. Dotyczy to standardowych technologii rynkowych , np. cookies lub piksele śledzące wbudowane w nasz newsletter Dane przetwarzane są na podstawie naszego prawnie uzasadnionego interesu w rozumieniu art. 6 ust. Więcej informacji na temat ochrony danych w MailChimp można znaleźć w sekcji `,
  HTTPS_MAILCHIMP_COM_LEGAL_PR: `https`,
  ZWECK_NDERUNG: '3.5 Zmiana celu',
  SOWEIT_WIR_IHRE_PERSONENBEZOGE: `O ile przetwarzamy Twoje dane osobowe poza odpowiednią zgodą lub niepodważalną podstawą prawną w celu innym niż ten, dla którego dane zostały zebrane, bierzemy pod uwagę zgodność pierwotnego i obecnie realizowanego celu, rodzaju danych osobowych zgodnie z art. 6 ust. 4 danych DSGVO możliwe konsekwencje dalszego przetwarzania dla Ciebie oraz gwarancje ochrony danych osobowych.`,
  PROFILING: '3.6 Profilowanie',
  WIR_F_HREN_KEINE_AUTOMATISIERT: `Nie przeprowadzamy zautomatyzowanego podejmowania decyzji ani profilowania zgodnie z art. 22 RODO. Profil jest tworzony wyłącznie w celu ochrony naszych uzasadnionych interesów, jak opisano powyżej.`,
  ZUGRIFFSBERECHTIGUNGEN_IM_E: '4. Uprawnienia dostępu w urządzeniu końcowym',
  SOWEIT_FUNKTIONEN_UNSERES_ONLI: `O ile funkcje naszej oferty online wymagają nadawania uprawnień dostępu do urządzenia (np. dostęp do danych o lokalizacji lub zdjęć), nadanie uprawnień jest dobrowolne. Jeśli jednak chcesz korzystać z odpowiednich funkcji, nadanie odpowiednich uprawnienia są konieczne, w przeciwnym razie nie możesz korzystać z tych funkcji. Uprawnienia pozostają aktywne, dopóki nie zresetujesz ich w swoim urządzeniu poprzez dezaktywację odpowiedniego ustawienia.`,
  F_R_DIE_NUTZUNG_DER_UNTER_ZIFF: `W celu korzystania z funkcji wyjaśnionych w punkcie 3.1 (Korzystanie z funkcji) wymagane jest użycie kamery urządzenia końcowego. Jeśli chcesz korzystać z wymienionych funkcji, wymagane jest przyznanie odpowiedniej autoryzacji, w przeciwnym razie nie może korzystać z tych funkcji. Podstawowe uprawnienie do korzystania z aparatu podczas korzystania z funkcji wyjaśnionych powyżej pozostaje aktywne, dopóki nie zresetujesz go w swoim urządzeniu, dezaktywując odpowiednie ustawienie.`,
  COOKIES_UND_VERGLEICHBARE_T: `5. Cookies i podobne technologie`,
  WIR_VERWENDEN_IM_RAHMEN_DES_ON: `W ramach oferty internetowej stosujemy pliki cookies i podobne technologie, które służą do komunikacji z Twoim urządzeniem i wymiany przechowywanych informacji (zwane dalej "ciasteczkami"). Te pliki cookies służą przede wszystkim do korzystania z funkcji oferty internetowej. Ogólne przykłady, w których korzystanie z plików cookie jest w tym sensie technicznie konieczne, to przechowywanie wybranego języka, danych logowania lub listy zakupów lub listy obserwacyjnej. Odpowiednio, niezbędne technicznie pliki cookie mogą być przez nas używane w celu umożliwienia przetwarzania opisanego w punkcie 3.1 i w celu umożliwienia prawidłowego i bezpiecznego działania oferty internetowej. Przetwarzanie danych odbywa się wówczas na podstawie art. 6 ust. 1 lit. b) i f) RODO, ponieważ jest to niezbędne do realizacji wybranych przez Państwa funkcji lub ochrony naszego prawnie uzasadnionego interesu w zakresie funkcjonalności oferty internetowej.`,
  SOWEIT_WIR_DAR_BER_HINAUS_COOK: `W zakresie, w jakim używamy plików cookie do analizy korzystania z oferty online i dostosowywania jej do Twoich zainteresowań, a w razie potrzeby również do dostarczania Ci treści i reklam opartych na zainteresowaniach, odbywa się to wyłącznie na podstawie Twojej dobrowolnej zgody zgodnie z artykułem 6 ust. Więcej informacji na temat plików cookie i ich funkcji, a także możliwości ustawienia i odwołania można znaleźć w naszym`,
  WENN_SIE_DEN_EINSATZ_VON_COOKI: `Jeżeli nie chcesz, aby w ogóle wykorzystywane były pliki cookies, możesz również uniemożliwić ich zapisywanie dokonując odpowiednich ustawień w swoim urządzeniu końcowym. Zapisane pliki cookies możesz w każdej chwili usunąć w ustawieniach systemowych swojego urządzenia końcowego. że zablokowanie niektórych rodzajów plików cookie spowoduje utrudnione korzystanie z naszej oferty online.`,
  EINGEBUNDENE_DRITTDIENSTE: '6. Zintegrowane Usługi Osób Trzecich',
  SOWEIT_WIR_IM_RAHMEN_UNSERES_O: `O ile integrujemy usługi innych dostawców w ramach naszej oferty online w celu zaoferowania Państwu określonych treści lub funkcji (np. odtwarzanie filmów lub planowanie trasy) i przetwarzamy w tym procesie dane osobowe, odbywa się to na podstawie art. 6 ust. 1 lit. b) i f) RODO, ponieważ przetwarzanie danych jest wówczas niezbędne do realizacji wybranych przez Państwa funkcji lub do ochrony naszego prawnie uzasadnionego interesu w zakresie optymalnego zakresu funkcji oferty online. w kontekście tych usług świadczonych przez osoby trzecie obowiązują oświadczenia zawarte w punkcie 5. Proszę W odniesieniu do usług osób trzecich prosimy również o zapoznanie się z oświadczeniem o ochronie danych danego dostawcy.`,
  DIENSTE_ANDERER_ANBIETER_DIE: `Usługi innych dostawców, które integrujemy lub do których się odnosimy, są świadczone przez odpowiednie strony trzecie. Nie mamy wpływu na treść i funkcję usług stron trzecich i zasadniczo nie ponosimy odpowiedzialności za przetwarzanie Twoich danych osobowych przez ich usługodawcy, o ile usługi osób trzecich nie są projektowane wyłącznie w naszym imieniu, a następnie integrowane przez nas na naszą własną odpowiedzialność.Jeśli integracja usługi osoby trzeciej prowadzi do ustanowienia przez nas wspólnych procesów z jej dostawcą, określamy w ten sposób w umowie o współodpowiedzialności zgodnie z art. 26 RODO, w jaki sposób określone są odpowiednie zadania i obowiązki związane z przetwarzaniem danych osobowych oraz kto wypełnia jakie obowiązki w zakresie ochrony danych. Jeśli pliki cookie mają być również ustawione na podstawie Twojej zgody, będziesz otrzymać dalsze informacje na temat odpowiedzialności za r ustawienie tych plików cookie lub powiązanych z nimi usług stron trzecich w odpowiednim obszarze`,
  SOWEIT_NICHT_ANDERS_ANGEGEBEN: `O ile nie zaznaczono inaczej, profile w mediach społecznościowych są zawarte w naszej ofercie online wyłącznie jako łącze do odpowiednich usług stron trzecich. Po kliknięciu zintegrowanego łącza tekstowego/obrazowego zostaniesz przekierowany do oferty odpowiedniego dostawcy mediów społecznościowych Po przekazaniu dane osobowe mogą być zbierane bezpośrednio przez zewnętrznego dostawcę.Jeśli jesteś jednocześnie zalogowany na swoim koncie użytkownika u odpowiedniego dostawcy mediów społecznościowych, może on przyporządkować informacje zebrane podczas konkretnej wizyty do Twoje osobiste konto użytkownika. Wejdź w interakcję za pomocą przycisku "Udostępnij" u odpowiedniego dostawcy mediów społecznościowych. Dostawca mediów, informacje te mogą być przechowywane na osobistym koncie użytkownika i w razie potrzeby publikowane. Jeśli chcesz uniemożliwić bezpośrednie przypisanie zebranych informacji do swoje konto użytkownika, przed kliknięciem w zintegrowany link tekstowy/obrazowy należy się wylogować.`,
  F_R_DIE_ANALYSE_UND_VERWALTUNG: `PhysioStretch korzysta z usługi RevenueCat (dostawca Revenue Cat, Inc., 633 Taraval St. Suite101, San Francisco, CA, 94116, USA) do analizy i zarządzania zakupami w aplikacji. Transmisja jest zgodna z RODO, analogicznie do przepisy Art. 46 RODO. RevenueCat przechowuje lub przetwarza dane i przygotowuje je w interfejsie internetowym. Dane są przechowywane przez RevenueCat w USA lub Europie. RevenueCat podlega przepisom RODO UE (EU2016/679) per DPA`,
  HTTPS_WWW_REVENUECAT_COM_DPA: `https`,
  DU_STIMMST_DER_DATENERFASSUNG:
    'Wyrażasz zgodę na zbieranie danych przez PhysioStretch i przesyłanie ich do RevenueCat. Możesz usunąć własne dane z bazy danych w PhysioStretch lub w RevenueCat, możesz to zrobić wysyłając wiadomość e-mail na adres',
  VERFASSEN_UND_DIE_L_SC: `Napisz i poproś o usunięcie z dopiskiem "Usunięcie moich danych w RevenueCat". Jeśli tak się nie stanie, dane zostaną usunięte najpóźniej po upływie ustawowych okresów przechowywania. Odwołujemy się do przepisów o ochronie danych i Ogólnych Warunków oraz Warunki PrzychoduCat`,
  DATENSCHUTZBESTIMMUNGEN: `Polityka prywatności`,
  HTTPS_WWW_REVENUECAT_COM_PRI: `https`,
  ALLGEMEINE_GESCH_FTSBEDINGUNGE: `Warunki usługi`,
  HTTPS_WWW_REVENUECAT_COM_TER: `https`,
  UNTER_FOLGENDEM_LINK_K_NNEN_DI:
    'Dane przechowywane i przetwarzane przez RevenueCat można przeglądać pod następującym linkiem',
  EMPF_NGER_PERSONENBEZOGENER: '7. Odbiorcy danych osobowych',
  INNERHALB_UNSERES_UNTERNEHMENS: `W naszej firmie dostęp do Twoich danych osobowych mają tylko te osoby, które potrzebują ich do celów wymienionych każdorazowo. Twoje dane osobowe będą przekazywane odbiorcom zewnętrznym tylko wtedy, gdy istnieje na to prawnie pozwolenie lub mamy Twoją zgodę. Poniżej znajdziesz zestawienie odpowiednich odbiorców`,
  AUFTRAGSVERARBEITER_KONZERN: `- Przetwarzający, na przykład w obszarach infrastruktury technicznej i utrzymania, które są starannie dobierane i sprawdzane. Przetwarzający mogą wykorzystywać dane wyłącznie zgodnie z naszymi instrukcjami.`,
  FFENTLICHE_STELLEN_BEH_RDE:
    '- Organy publiczne, organy podatkowe, prokuratury lub sądy, którym (musimy) przekazać dane osobowe, np. w celu wypełnienia obowiązków prawnych lub ochrony prawnie uzasadnionych interesów.',
  PRIVATE_STELLEN_KOOPERATION:
    '- Podmioty prywatne:  Osoby, którym przekazywane są dane na podstawie zgody, w celu realizacji umowy z Państwem lub w celu ochrony prawnie uzasadnionych interesów.',
  DATENVERARBEITUNG_IN_DRITTL: '8. Przetwarzanie danych w państwach trzecich',
  FINDET_EINE_DATEN_BERMITTLUNG: `Jeżeli dane są przekazywane organom, których siedziba lub miejsce przetwarzania danych nie znajduje się w państwie członkowskim Unii Europejskiej, innemu państwu będącemu stroną Umowy o Europejskim Obszarze Gospodarczym lub państwu, dla którego zapewniono odpowiedni poziom ochrony danych określone decyzją Komisji Europejskiej, przed jej przekazaniem zapewniamy, że przekazanie danych jest objęte albo zezwoleniem ustawowym, że istnieją gwarancje odpowiedniego stopnia ochrony danych w związku z przekazaniem danych (np. poprzez umowy gwarancji, urzędowo uznanych przepisów lub obowiązujących wewnętrznych przepisów o ochronie danych u odbiorcy) lub że wyraziłeś zgodę na dane - wyraziłeś zgodę na transmisję.`,
  SOWEIT_DIE_DATEN_BERMITTLUNG_A: `O ile dane są przekazywane na podstawie art. 46, 47 lub 49 ust. 1 pkt 2 RODO, możesz otrzymać kopię gwarancji istnienia odpowiedniego poziomu ochrony danych w związku z przesyłaniem danych wskazanie dostępności kopii gwarancji. Proszę skorzystać z informacji w pkt 1.`,
  SPEICHERDAUER_L_SCHUNG: '9. Okres przechowywania, usuwanie',
  WIR_SPEICHERN_IHRE_PERSONENBEZ: `Przechowujemy Twoje dane osobowe, o ile jest na to prawnie dozwolone, tylko tak długo, jak jest to konieczne do realizacji zamierzonych celów lub tak długo, jak nie odwołałeś zgody. W przypadku wniesienia sprzeciwu wobec przetwarzania usunie Pani/Pana dane osobowe, chyba że dalsze przetwarzanie będzie odbywało się zgodnie z przepisami ustawowymi. Usuwamy również Pani/Pana dane osobowe, jeśli jesteśmy do tego zobowiązani z innych powodów prawnych. Stosując te ogólne zasady, zazwyczaj niezwłocznie usuwamy Pani/Pana dane osobowe`,
  NACH_WEGFALL_DER_RECHTLICHEN: `- po ustaniu podstawy prawnej i pod warunkiem, że nie ma zastosowania żadna inna podstawa prawna (np. okresy przechowywania w celach handlowych i podatkowych).`,
  WENN_IHRE_PERSONENBEZOGENEN: `- jeśli Twoje dane osobowe nie są już potrzebne do celów, które realizujemy i nie ma zastosowania żadna inna podstawa prawna (np. okresy przechowywania w celach handlowych i podatkowych). Jeśli to ostatnie ma zastosowanie, usuniemy dane, gdy druga podstawa prawna przestanie obowiązywać. `,
  BETROFFENENRECHTE: `10. Prawa osób, których dane dotyczą`,
  AUSKUNFTSRECHT_SIE_HABEN_DAS: `Prawo dostępu do przechowywanych danych osobowych.`,
  BERICHTIGUNGS_UND_L_SCHUNGSRE: `Prawo do sprostowania i usunięcia nieprawidłowych danych oraz – w przypadku spełnienia wymogów prawnych – żądania usunięcia swoich danych.`,
  EINSCHR_NKUNG_DER_VERARBEITUNG: `Spełnione są wymogi prawne dotyczące ograniczenia przetwarzania – żądać ograniczenia przetwarzania Twoich danych.`,
  DATEN_BERTRAGBARKEIT_SOLLTEN: `Jeżeli podałeś możliwość przenoszenia danych lub wyraziłeś zgodę, możesz, o ile spełnione są wymogi prawne, zażądać, abyś otrzymał dane, które podałeś, w ustrukturyzowanym, powszechnym i nadającym się do odczytu maszynowego formacie lub abyśmy przesłali je innej odpowiedzialnej osobie`,
  WIDERSPRUCH_SIE_HABEN_DAS_REC: `Jeśli skorzystasz z prawa do sprzeciwu, przestaniemy przetwarzać, chyba że będziemy w stanie wykazać istotne powody dalszego przetwarzania warte ochrony, nad którymi przeważają Twoje prawa i interesy.`,
  WIDERSPRUCH_GEGEN_DIREKTWERBUN: `Sprzeciw wobec przetwarzania korespondencji bezpośredniej w celu obsługi poczty bezpośredniej masz prawo w dowolnym momencie wnieść sprzeciw wobec przetwarzania przez nas danych w tym celu. Jeżeli skorzystasz z prawa do sprzeciwu, zaprzestaniemy przetwarzania w tym celu.`,
  WIDERRUF_DER_EINWILLIGUNG_SOF: `Cofnięcie zgody na przetwarzanie Twoich danych osobowych. Możesz ją odwołać w dowolnym momencie ze skutkiem na przyszłość. Nie ma to wpływu na zgodność z prawem przetwarzania Twoich danych.`,
  BESCHWERDERECHT_BEI_DER_AUFSIC: `Prawo odwołania do organu nadzorczego. Złóż skargę do właściwego organu nadzorczego, jeżeli uważasz, że przetwarzanie Twoich danych narusza obowiązujące przepisy prawa. Możesz skontaktować się z organem nadzorczym właściwym ze względu na Twoje miejsce zamieszkania lub kraj lub odpowiedzialny za nas organ nadzorczy.`,
  IHR_KONTAKT_ZU_UNS_UND_DIE_AUS: `Twój kontakt z nami i realizacja Twoich praw. W przypadku jakichkolwiek pytań dotyczących przetwarzania Twoich danych osobowych oraz praw jako osoby, której dane dotyczą, skontaktuj się z nami bezpłatnie. Prosimy o kontakt`,
  BETROFFENENRECHTE_INITIUM_GMBH: `kontakt@stretchme.pl`,
  ODER_POSTALISCH_AN_DIE_OBEN_UN: `lub listownie na adres podany w punkcie 1 powyżej. Upewnij się, że możemy jednoznacznie Cię zidentyfikować.`,
  STAND: 'XI trybuna',
  ES_GILT_DIE_AKTUELLSTE_VERSION:
    'Obowiązuje najnowsza wersja niniejszego oświadczenia o ochronie danych. Stan 12.10.2021.',
  AUF_DER_WEBSEITE_WWW_YUUXYOGA: `Na stronie www.stretchme.app ("`,
  WEBSEITE: 'Stronie internetowej',
  SOWIE_MIT_MIT_MIT_MIT_MIT_M: `") oraz za pomocą aplikacji ("`,
  APP: 'aplikacja',
  BIETET_DIE_INITIUM_GMBH_ST: `") oferuje PhysioStretch sp. z o.o., Stralauer Allee 12, 10245 Berlin, Niemcy ("`,
  WIR: 'My',
  EINE_PLATTFORM_DIE_ES_DEM: `") platforma umożliwiająca użytkownikowi ("`,
  DU: 'Ty',
  IM_RAHMEN_DES_NACHFOLGEND_B: `"), w ramach opisanych poniżej usług, umożliwia przeglądanie treningów z instrukcjami ćwiczeń różnych trenerów oraz ich powtarzanie w ramach własnych treningów ("`,
  PhysioStretch: 'PhysioStretch',
  EINE_NUTZUNG_VON_YUUYOGA_S: `"). Korzystanie z PhysioStretch wymaga wyrażenia zgody na niniejsze warunki użytkowania, które są przedmiotem umowy użytkownika zawartej między nami a tobą. Dlatego uważnie przeczytaj niniejsze warunki użytkowania i zapoznaj się z zawartymi w nich postanowieniami.`,
  LEISTUNGSGEGENSTAND_VON_YUU: '1. Przedmiot wykonania PhysioStretch app',
  YUUYOGA_BIETET_DIR_DIE_M_GLICH: `PhysioStretch oferuje możliwość dostępu do biblioteki treści wideo, takich jak treningi jogi, ćwiczenia i ćwiczenia ("`,
  WORKOUTS: 'treningi',
  VON_AUSGEW_HLTEN_LEHRERN: `") przez wybranych nauczycieli ("`,
  TRAINER: 'Trener',
  ZUZUGREIFEN_UND_W_HREND_UND: `") i otrzymywania spersonalizowanej informacji zwrotnej o tym, jak zostało to wykonane w trakcie i po wykonanej sesji.`,
  DAS_PERSONALISIERTE_FEEDBACK: `Spersonalizowana informacja zwrotna odbywa się w taki sposób, że pokazujemy Ci wirtualne lustro poprzez skonfigurowanie i podłączenie kamer na Twoim urządzeniu końcowym (np. smartfonie, tablecie, laptopie itp.) ("`,
  UND_SO_W_HREND_UND_NACH_EIN: `"), a tym samym uzyskaj bezpośrednie porównanie swojego wykonania ćwiczeń z wykonaniem przez trenera podczas i po sesji.`,
  DIE_NUTZUNG_VON_YUUYOGA_ERFORD:
    'Korzystanie z PhysioStretch wymaga zawarcia lub zawarcia dalszych umów z podmiotami trzecimi (np. z operatorem telefonii komórkowej); musisz ponieść wynikające z tego koszty - w szczególności za wykorzystaną ilość danych.',
  ZUR_VOLLUMF_NGLICHEN_NUTZUNG_V:
    'Do pełnego korzystania z PhysioStretch app mogą być wymagane pomoce, takie jak urządzenie końcowe lub sprzęt treningowy. Nie są one częścią oferty PhysioStretch i mogą być zakupione osobno i na własny koszt.',
  VORAUSSETZUNGEN_F_R_DIE_NUT:
    '2. Wymagania dotyczące korzystania z PhysioStretch app',
  DU_KANNST_YUUYOGA_NUTZEN_WENN:
    'Możesz korzystać z PhysioStretch app, jeśli jesteś osobą fizyczną, która ukończyła 18 lat. Pamiętaj również, że korzystanie z PhysioStretch app wymaga posiadania urządzenia Apple z systemem operacyjnym co najmniej IOS 8.',
  SOWEIT_DU_DICH_F_R_EIN_KOSTENP:
    'Jeśli zdecydujesz się na zakup płatnej subskrypcji, będziesz musiał zapłacić za dalsze korzystanie z PhysioStretch. Konto użytkownika zakładane jest poprzez podanie adresu e-mail oraz',
  DER_FESTLEGUNG_EINES_PASSWORTS: 'ustawienie hasła lub',
  DER_VERWENDUNG_EINES_NUTZERPRO: `Korzystanie z profilu użytkownika w sieci (społecznościowej) obsługiwanej przez stronę trzecią, taką jak Facebook, Google lub LinkedIn `,
  SOCIAL_LOGIN: 'logowanie społecznościowe',
  SOFERN_DU_DICH_MITTELS_SOC: `"); jeśli chcesz zarejestrować się w PhysioStretch za pomocą logowania społecznościowego, zostaniesz przekierowany do strony logowania/rejestracji dostawcy zewnętrznego i możesz się tam zalogować za pomocą swojej nazwy użytkownika i hasła przechowywanych u tego dostawcy zewnętrznego (" `,
  ZUGANGSDATEN: 'Dane dostępowe',
  DU_HAST_DAF_R_SORGE_ZU_TRAGEN: `Musisz upewnić się, że używane dane dostępowe są chronione przed dostępem osób trzecich i są starannie przechowywane. Jeśli masz powody, by sądzić, że poufność Twojego hasła została naruszona lub nieautoryzowany dostęp do Twojego konta użytkownika jest możliwy w jakikolwiek inny sposób , zobowiązujesz się do niezwłocznej zmiany hasła i powiadomienia nas.`,
  WIR_HABEN_DAS_RECHT_VON_ZEIT:
    'Mamy prawo od czasu do czasu zażądać zmiany hasła. Możemy zablokować dostęp do konta użytkownika do czasu dokonania takiej zmiany.',
  NACH_EINRICHTUNG_DES_NUTZERKON: `Po założeniu konta użytkownika możesz również podać nazwę użytkownika. W odniesieniu do wyboru nazwy użytkownika zobowiązujesz się chronić uzasadnione interesy osób trzecich i nie wybierać nazwy użytkownika, która jest obraźliwa, dyskryminująca, gloryfikuje przemoc, zniesławiające lub wprowadzające w błąd, promuje nielegalną działalność lub w inny sposób łamie obowiązujące prawo. W przypadku naruszenia tych zasad zastrzegamy sobie prawo do żądania zmiany nazwy użytkownika w późniejszym terminie. może zablokować dostęp do konta użytkownika zgodnie z ust. 6 do czasu zmiany nazwy użytkownika.`,
  IM_RAHMEN_VON_YUUYOGA_WERDEN_D: `W ramach PhysioStretch zostaną Ci pokazane instrukcje ćwiczeń (np. dotyczące niektórych ćwiczeń i sesji treningowych). W każdym przypadku musisz przestrzegać tych instrukcji, w przeciwnym razie istnieje ryzyko urazu i/lub naruszenia zdrowia.`,
  WIR_WEISEN_DICH_DARAUF_HIN_DA: `Chcielibyśmy zwrócić uwagę, że PhysioStretch nie determinuje Twoich indywidualnych zdolności sportowych, mobilności ani limitu. Nie znamy również Twojego stanu zdrowia. Dlatego sesje w PhysioStretch odbywają się na Twoje własne ryzyko. Używasz PhysioStretch app tylko wtedy, gdy jesteś w dobrej kondycji fizycznej i zdrowotnej. Zwracamy uwagę, że PhysioStretch nie powinna być używana, jeśli np. odczuwasz silny ból, ogólne złe samopoczucie, duszność, nudności lub zawroty głowy. Jeśli już cierpisz lub zdajesz sobie sprawę z wcześniejszych chorób, przed użyciem PhysioStretch zasięgnij porady lekarskiej Zasięgnij porady i postępuj zgodnie z tymi zaleceniami. Jest to szczególnie ważne, jeśli cierpisz na choroby układu krążenia, kręgosłupa, stawów i/lub inne schorzenia, które ograniczają twoje wyniki sportowe.`,
  VERF_GBARKEIT_FUNKTIONALIT: '3. Dostępność, funkcjonalności i dostosowania',
  WIR_BIETEN_DIE_M_GLICHKEIT_ZUR:
    'Oferujemy tylko opcję korzystania z PhysioStretch app z podstawowymi funkcjonalnościami opisanymi w Sekcji 1. Nie obejmuje to nieistotnych funkcjonalności oraz okresów planowanych konserwacji, aktualizacji, uaktualnień itp.',
  WIR_SIND_BERECHTIGT_YUUYOGA_U: `Możemy dostosować PhysioStretch i jego funkcje w dowolnym momencie i bez uprzedzenia ("`,
  ANPASSUNGEN: 'Korekty',
  VORAUSGESETZT_DASS_YUUYOGA: `), pod warunkiem, że PhysioStretch nadal będzie utrzymywać zakres usług opisany w Sekcji 1, a takie zmiany są dla Ciebie uzasadnione. Nie jesteśmy jednak zobowiązani do wprowadzania zmian. Zmiany mogą oznaczać, że będziesz musiał zainstalować aktualną wersję aplikacji kontynuacja korzystania z PhysioStretch app lub konieczność korzystania z poprzednio używanego urządzenia nie nadaje się już do korzystania z PhysioStretch app.`,
  GEISTIGES_EIGENTUM_UND_ANDE:
    '4. Własność intelektualna i inne prawa ochronne',
  WIR_BEHALTEN_UNS_AN_YUUYOGA_UN: `Zastrzegamy sobie wszelkie prawa autorskie i inne prawa własności do PhysioStretch app i treści prezentowanych w ramach PhysioStretch app, o ile jesteśmy do nich uprawnieni. Możesz używać PhysioStretch app i prezentowanych w nim treści wyłącznie do własnych celów osobistych i niekomercyjnych. wykorzystywanie PhysioStretch i zawartych w nich treści do celów komercyjnych (np. dla własnego zysku) jest zabronione, chyba że w indywidualnych przypadkach wyrazimy na to naszą pisemną zgodę.`,
  HINWEISE_UND_ANGABEN_ZU_URHEBE: `Powiadomienia i wskazania dotyczące praw autorskich, praw do znaków towarowych lub innych praw własności intelektualnej w PhysioStretch oraz treści nie mogą być zmieniane ani usuwane.`,
  VERTRAGSDAUER_UND_K_NDIGUNG: '5. Czas trwania umowy i wypowiedzenie',
  DER_ZWISCHEN_DIR_UND_UNS_GESCH: `Umowa użytkowania zawarta między Tobą a nami zawierana jest na czas nieokreślony.`,
  DU_KANNST_DEN_NUTZUNGSVERTRAG: `Umowę użytkowania można rozwiązać w dowolnym momencie bez zachowania okresu wypowiedzenia i bez obecności lub podania przyczyny. Można to również zrobić, usuwając konto użytkownika. Jeśli wykupisz płatną subskrypcję, w odstępstwie od zdania 1 i 2, anulowanie jest możliwe dopiero po zakończeniu minimalnego okresu umowy danego abonamentu. `,
  WIR_K_NNEN_DEN_NUTZUNGSVERTRAG: `Umowę o użytkowanie możemy rozwiązać tylko z zachowaniem rozsądnego okresu wypowiedzenia.`,
  DAS_RECHT_JEDER_PARTEI_ZUR_AUS: `Prawo każdej ze stron do nadzwyczajnego rozwiązania umowy bez wypowiedzenia z ważnej przyczyny oraz nasze prawo do zablokowania dostępu do konta użytkownika pozostają nienaruszone.`,
  AB_WIRKSAMWERDEN_EINER_K_NDIGU:
    'Usuniemy Twoje konto użytkownika, gdy tylko wypowiedzenie stanie się skuteczne',
  SPERRUNG_DES_NUTZERKONTOS: '6. Zablokowanie konta użytkownika',
  WIR_K_NNEN_DIR_DEN_ZUGRIFF_AUF: `Możemy tymczasowo zablokować dostęp do konta użytkownika, jeśli ustalimy lub mamy uzasadnione podstawy, aby podejrzewać, że korzystanie z PhysioStretch narusza niniejsze Warunki użytkowania i/lub obowiązujące prawo`,
  RECHTE_DRITTER_VERLETZT: `Naruszenie praw osób trzecich`,
  EIN_SICHERHEITSRISIKO_F_R_DIE: `stanowi zagrożenie bezpieczeństwa dla funkcjonalności PhysioStretch`,
  YUUYOGA_UND_ODER_ANDERE_NUTZER:
    'Może pociągnąć PhysioStretch i/lub innych użytkowników do odpowiedzialności, lub',
  BETR_GERISCH_SEIN_K_NNTE: 'może być fałszywe.',
  WIR_BEHALTEN_UNS_EINE_SPERRUNG: `Zastrzegamy sobie również prawo do zablokowania dostępu do Twojego konta użytkownika, jeśli nie wyraziłeś zgody na rozsądną zmianę niniejszych Warunków użytkowania zgodnie z sekcją 10.1 do zaplanowanej daty ich wejścia w życie.`,
  WIR_WERDEN_DIE_SPERRUNG_AUFHEB:
    'Zniesiemy zakaz, gdy tylko przyczyna zakazu przestanie obowiązywać. W tym celu prosimy o kontakt pod adresem',
  INFOA_YUUXYOGA_DE: `kontakt@stretchme.pl`,
  KONTAKTIEREN_UM_EINE_KL_RUNG: 'Kontakt w celu wyjaśnienia.',
  WEITERGEHENDE_ANSPR_CHE_GEGEN:
    'Dalsze roszczenia wobec Ciebie pozostają nienaruszone.',
  HAFTUNGSBESCHR_NKUNG: `7. Ograniczenie odpowiedzialności`,
  WIR_HAFTEN_GLEICH_AUS_WELCHE:
    'Ponosimy pełną odpowiedzialność - bez względu na przyczynę prawną',
  BEI_VORSATZ_ODER_GROBER_FAHRL: `W przypadku umyślnego działania lub rażącego niedbalstwa`,
  BEI_VORS_TZLICHER_ODER_FAHRL_S: `w przypadku umyślnego lub wynikającego z zaniedbania urazu życia, kończyny lub zdrowia`,
  BEI_FEHLEN_EINER_GARANTIERTEN:
    'w przypadku braku gwarantowanej jakości lub w przypadku podstępnie ukrytych wad, a także',
  AUFGRUND_ZWINGENDER_HAFTUNG_WI: `Ze względu na obowiązkową odpowiedzialność taką jak na podstawie ustawy o odpowiedzialności za produkt.`,
  BEI_FAHRL_SSIG_VERURSACHTEN_SA: `W przypadku szkody majątkowej i majątkowej wyrządzonej niedbale, nasza odpowiedzialność jest ograniczona do przypadków naruszenia istotnego zobowiązania umownego, a kwota jest ograniczona do szkody typowej dla umowy, przewidywalnej, o ile nie istnieje nieograniczona odpowiedzialność w zgodnie z powyższym punktem 7.1 Istotne zobowiązania umowne to zobowiązania, które użytkownik zawiera z nami swoją treścią nałożone w celu osiągnięcia celu umowy, których wypełnienie umożliwia przede wszystkim prawidłowe wykonanie umowy i których przestrzeganie ponieważ użytkownik może regularnie polegać.`,
  IM_BRIGEN_IST_EINE_HAFTUNG_VO:
    'Co do reszty, odpowiedzialność z naszej strony jest wykluczona.',
  VORSTEHENDE_HAFTUNGSREGELUNGEN: `Powyższe przepisy dotyczące odpowiedzialności obowiązują również w odniesieniu do naszej odpowiedzialności wobec naszych zastępców, pracowników i przedstawicieli prawnych.`,
  GESETZLICHE_GEW_HRLEISTUNGSANS: `Niniejsze przepisy dotyczące odpowiedzialności nie mają wpływu na roszczenia z tytułu rękojmi.`,
  DATENSCHUTZ: '8. Ochrona Danych',
  DIE_VERARBEITUNG_PERSONENBEZOG:
    'Przetwarzanie danych osobowych przez nas w związku z PhysioStretch odbywa się na podstawie naszej polityki prywatności, którą możesz zobaczyć tutaj',
  ALTERNATIVE_STREITBEILEGUNG: '9. Alternatywne Rozstrzyganie Sporów',
  WIR_SIND_NICHT_BEREIT_ODER_VER: `Nie jesteśmy skłonni ani zobowiązani do udziału w procedurach rozstrzygania sporów przed konsumencką radą arbitrażową.`,
  SCHLUSSVORSCHRIFTEN: `10. Postanowienia końcowe`,
  ANPASSUNGEN_VON_YUUYOGA_SOWIE: `Adaptacje PhysioStretch, a także zmiany w ramach prawnych mogą spowodować konieczność zmiany przez nas niniejszych warunków użytkowania. W przypadku planowanej zmiany niniejszych warunków użytkowania, poinformujemy Cię o treści zmiany co najmniej cztery (4) tygodnie przed planowaną datą wejścia w życie; zmienione warunki użytkowania zostaną do Ciebie przesłane w takim przypadku tylko wtedy, gdy wyraziłeś zgodę na zmiany.`,
  DU_BIST_NICHT_BERECHTIGT_DEN:
    'Nie możesz przenieść Umowy Użytkownika na jakąkolwiek osobę trzecią bez uprzedniej pisemnej zgody Nas.',
  DER_NUTZUNGSVERTRAG_UNTERLIEGT: `Umowa użytkowania podlega prawu Republiki Federalnej Niemiec, z wyłączeniem prawa sprzedaży ONZ. Miejscem jurysdykcji dla sporów pieniężnych wynikających z lub w związku z niniejszą umową użytkowania jest Stuttgart, pod warunkiem, że zostać pozwanym, przeniósł się do nas swoje miejsce zamieszkania lub zwykłego pobytu poza terytorium Republiki Federalnej Niemiec lub miejsce zamieszkania lub zwykłego pobytu do nas Miejsce zamieszkania nie jest znane w chwili wniesienia powództwa.`,
  MEINE_ACCOUNT_LOSEN: 'Chcę usunąć swoje konto',
  LOGE_DICH: 'Zaloguj się teraz.',
  LOGIN_GOOGLE: 'Zaloguj z Google',
  LOGIN_FACEBOOK: 'Zaloguj z Facebook',
  LOGIN_APPLE: 'Zaloguj z Apple',
  EMAIL_ADDRESS: 'Adres E-Mail',
  PASSWORD: 'Hasło',
  CLIENT: 'Client',

  VERGESSEN: 'Nie pamiętasz?',
  ODER: 'lub',
  LANGUAGE: 'Język',
  NO_ACCOUNT: 'Nie masz konta?',
  THIRTY_DAYS_CHALLANGE_UNFINISHED:
    'Niestety nie ukończyłeś wyzwania. Czy chcesz spróbować ponownie?',
  ACHIEVED_DAILY_GOAL: 'Osiągnięto dzienny cel!',
  SESSION_FINISHED: 'Zakończyłeś sesję na dzisiaj',
  BACK: 'Powrót',
  RESTART_CHALLENGE: 'Uruchom ponownie',
  YOUR_PROGRESS: 'Twój postęp',
  COURSE_WAITING: 'kurs czeka na ciebie',
  POSE_INFO: 'Więcej o pozie',
  SESSIONS: 'Sesje',
  AVAILABLE_SOON: 'Dostępne dla Ciebie wkrótce',
  LEVEL: 'Level',
  POSE_COMPARISON_EXAMPLE: 'Porównywanie pozycji',
  YOUR_SMARTPHONE_BACAME: 'Twój smartfon stanie się',
  SMART_MIRROR2: 'cyfrowym lustrem.',
  HOW_TO_POSITION_SMART_MIRROR:
    'Umieść SmartMirror na macie w taki sposób, abyś podczas sesji widział siebie w tej samej orientacji co trener.',
  PROPER_POSITION_HELP_YOU_IMPROVE:
    'Dzięki temu będziesz lepiej kontrolować swoją pozycje.',
  YOUR_FIRST_SESSION: 'Twoja pierwsza sesja',
  WITH_FEEDBACK: 'z informacją zwrotną',
  START_SESSION: 'Rozpocznij',
  POSE_COMPARSION: 'Porównanie pozycji',
  IMPROVE_YOUR_PROGRRESS: 'Zobacz swoje postępy!',
  WE_ARE_TAKING_PHOTOS:
    'Podczas wybranych pozycji w sesji robimy Ci zdjęcie, dzięki czemu po sesji możesz porównać swoje wykonanie z trenerem.',
  COUTDOWN_BEFORE_EACH_POSE:
    'Przed każdą pozą pojawia się odliczanie. Dzięki temu dowiesz się, kiedy zdjęcie zostanie wykonane.',
  SETTINGS: 'Ustawienia',
  CHOOSE_SIEZE: 'Wybierz rozmiar',
  LARGE: 'duży',
  MEDIUM: 'średni',
  SMALL: 'mały',
  MIRROR_REPRESENTAION: 'Widok lustra',
  MIRROR_VIEW: 'Obraz lustrzany inteligentnego lustra',
  AUDIO_COUNTDOWN: 'Dzwięk odliczania',
  AUDIO_COUNTDOWN_ON: 'Aktywne',
  SMART_MIRROR_HIDE: 'Ukryj/pokaż SmartMirror',
  SMART_MIRROR_ON: 'Aktywne',
  GRATULATIONS: 'Gratulacje',
  THANKS_FOR_SETTINGS: 'Dziękujemu za świetną sesję!',
  NEXT_SESSION_WAITING: 'Kontynuujemy? Kolejna sesja czeka...',
  SESSION_DATA_MISSING:
    'Błąd: dla tej sesji nie ma jeszcze porównania pozycji. Nie będą wykonane zdjęcia.',
  WHY_DO_YOU_WANT_DELETE: 'Dlaczego chcesz usunąc pozę?',
  QUESTION_1_HEADLINE: 'Nie widzę siebie właściwie',
  QUESTION_1_ANSWEAR:
    'Czasami może się zdarzyć, że czas wykonania zdjecia nie jest odpowiedni. Masz jednak możliwość samodzielnego określenia optymalnego czasu poprzez seryjną rejestrację obrazu. Użyj strzałek w lewym dolnym rogu obrazu.',
  QUESTION_2_HEADLINE: 'Jakość obrazu jest niewystarczająca.',
  QUESTION_2_ANSWEAR:
    'Oczywiście jakość zdjęć zależy od różnych czynników. W zależności od smartfona i środowiska może to się znacznie różnić. Jeśli jesteś niezadowolony z jakości obrazu, spróbuj zmienić pozycję swojego ustawienia w następnej sesji. Na przykład upewnij się, że oświetlenie jest dobre i poświęć trochę czasu na prawidłowe ustawienie smartfona.',
  QUESTION_3_HEADLINE: 'Czuję się nieswojo z tym zdjęciem.',
  QUESTION_3_ANSWEAR:
    'Nie martw się. Porównanie pozycji ma nie musi wyglądać perfekcyjnie, ale ma pomóc Ci lepiej wykonywać ćwiczenia i zrozumieć, które miejsca wymagają poprawy. Jeśli kolekcjonujesz swoje pozy przez dłuższy czas, pomogą ci one również uwidocznić swoje postępy z czasem.',
  QUESTION_4_HEADLINE: 'Nie chcę, aby moje zdjęcia były zapisywane.',
  QUESTION_4_ANSWEAR:
    'Oczywiście możesz usunąć swoje zdjęcie, ale nie musisz się martwić o swoją prywatność. Twoje zdjęcia są w 100% prywatne i bezpieczne. Nikt poza tobą nie może ich zobaczyć. Bezpieczeństwo jest naszym najwyższym priorytetem.',
  CANCEL: 'Anuluj',
  DELETE_POSE: 'Usuń pozycje',
  SAVE: 'Zapisz',
  EDIT: 'Edytuj',
  START_OUR_COURSESS: 'Zacznij teraz od jednego z naszych kursów',
  REMOVE_FROM_FAVORITES: 'Dodaj do ulubionych',
  ADD_TO_FAVORITES: 'Usuń z ulubionych',
  READ_MORE: 'Czytaj więcej +',
  READ_LESS: 'mniej -',
  PROPS: 'Props',
  THATS_WAITS_FOR_YOU: 'To Cię czeka',
  ERROR: 'Błąd',
  INSTRUCTOR_NOT_FOUND: 'Nie znaleziono instruktora',
  ABOUT: '',
  COURSES_WITH: 'Kursy z',
  DAY_AFTER_DAY: 'dzień po dniu',
  APP_PAGE_TITLE: 'STRETCHME',
  CONNECT_PAGE_TITLE: 'STRETCHME - Connect',
  CONNECT_YPOUR_PHONE: 'Podłącz telefon',
  SCAN_QR_CODE: 'Zeskanuj kod QR',
  ON_YOUR_IOS_DEVICE: 'na telefonie z systemem IOS',
  CAMERA_APP: '',
  SORRY_2: 'Przepraszamy',
  SOMETHING_WENT_WRONG: 'Coś poszło nie tak',
  CONTACT_US: 'Zapraszam do kontaktu, pomożemy',
  INFO_MAIL: 'kontakt@stretchme.pl',
  COOKIE_NAME: 'Nazwa Cookie',
  COOKIE_TYPE: 'Typ',
  COOKIE_PERIOD: 'Czas przechowywania',
  COOKIE_COMPANY: 'Firma',
  COOKIE_AIM: 'Cel',
  COOKIE_LEGAL_BASIS: 'Podstawa prawna',
  COOKIE_TABLE: [
    [
      'cookie_acceptance',
      'Permanent',
      '2 Wochen',
      'Porsche Digital',
      'YuuYoga Webseite – Information, ob Cookies akzeptiert werden',
      'Art. 6 Abs. 1 f) DSGVO',
    ],
    [
      '_ga',
      'Permanent',
      '2 Jahre',
      'Google Analytics',
      'Google Analytics Cookie zur Nutzeridentifikation; siehe auch https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage',
      'Art. 6 Abs. 1 a) DSGVO',
    ],
    [
      '_gid',
      'Permanent',
      '1 Tag',
      'Google Analytics',
      'Google Analytics Cookie zur Nutzeridentifikation.',
      'Art. 6 Abs. 1 a) DSGVO',
    ],
    [
      '_gat_gtag',
      'Permanent',
      '1 Min',
      'Google Analytics',
      'Google Analytics Cookie zur Drosselung der Requestanzahl.',
      'Art. 6 Abs. 1 a) DSGVO',
    ],
    [
      '_fbp',
      'Permanent',
      '90 Tage',
      'Facebook',
      'Über den Facebook Pixel können wir statistisch nachvollziehen, welche Werbung zu einem Besuch unserer Website bzw. App geführt hat. Mehr Details weiter oben unter Marketing.',
      'Art. 6 Abs. 1 a) DSGVO',
    ],
    [
      'fs_uid',
      'Permanent',
      '1 Jahr',
      'FullStory',
      'FullStory Cookie zur Nutzeridentifikation.',
      'Art. 6 Abs. 1 a) DSGVO',
    ],
  ],
  LOGIN_PAGE_META_TITLE: 'STRETCHME - Zarejestruj się',
  RESSET_YOUR_PASSWORD: 'Zresetuj Hasło',
  CONFIRM_RESET_PASSWORD: 'Zapisz nowe hasło',
  CONFIRM_ACCOUT_FIRST: 'Najpierw potwierdź email',
  USER_NOT_FOUND: 'Nie znaleziono użytkownika',
  REGISTER_BUTTON_LINK: 'Rejestracja',
  INVALID_PASSWORD: 'Hasło nieprawidłowe',
  INVALID_LOGIN_OR_PASSWORD: 'Nieprawidałowy login lub hasło',
  EMAIL_SENT: 'Wysłaliśmy Ci aktywacyjny e-mail ',
  ENTER_CODE_FROM_EMAIL:
    'Wprowadź kod z wiadomości e-mail, aby potwierdzić swój adres e-mail.',
  ENTER_THE_CODE: 'Wpisz kod',
  PASSWORD_NOT_MEET_REQUIREMENTS:
    'Hasło nie spełnia wymagań. Można używać tylko cyfr, liter i tych znaków:',
  EMAIL_INVALID: 'e-mail nieprawidłowy',
  USER_ALREADY_EXISITS: 'Użytkownik już istnieje.',
  LOGIN_BUTTON_LINK: 'Zaloguj',
  ACTIVATION_CODE_INVALID: 'Kod jest nieprawidłowy.',
  FEEL_FREE_TO_CONTACT_US: 'Zapraszamy do kontaktu,  pomożemy:',
  CODE_EXPIRED: 'Twój kod wygasł.',
  EMAIL_NOT_FOUND: 'Ten adres e-mail nie istnieje.',
  ACTIVATION_SUCCESFULL: 'Aktywacja zakończona sukcesem',
  ACCOUNT_ACTIVATED:
    'Twój adres e-mail został pomyślnie aktywowany, możesz się teraz zalogować.',
  ENTER_CODE_TO_CHANGE_PASSWORD:
    'Wprowadź kod z e-maila, aby ustawić nowe hasło.',
  CONTACT_US_IF_YOU_HAVENT_RECIEVE_EMAIL:
    'Jeśli nie otrzymałeś e-maila, skontaktuj się z nami:',
  CONFIRM_ACCOUNT_FIRST: 'Najpierw potwierdź swoj email.',
  PASSWORD_CHANGED: 'Hasło poprawnie zmienione.',
  PASSWORD_CHANGED_SUCCESSFULLY:
    'Twoje hasło zostało pomyślnie zmienione, możesz się teraz zalogować.',
  CODE_FROM_EMAIL: 'Kod z e-mail',
  FREE_REGISTER_BUTTON: 'Rejerstracja',
  CONSENT: 'Zapoznałem się i akceptuję warunki',
  TERMS_OF_USE: 'regulaminu',
  ACCEPT: 'oraz',
  PRIVACY_POLICY: 'Polityki prywatności',
  PRIVACY_POLICY_2:
    'Wyrażam zgodę na przetwarzanie moich danych osobowych w celu realizacji umowy subskrypcji. Wyrażam zgodę na natychmiastowe wykonanie umowy i przyjmuję do wiadomości, że utracę przysługujące mi prawo do odstąpienia od umowy z chwilą rozpoczęcia korzystania z usług w ramach subskrypcji.',
  MARKETING_CONSENT:
    'Wyrażam zgodę na otrzymywanie od StretchMe Sp. z o.o., ul. Lubomirskiego 20, 31-509 Kraków (Administrator) informacji handlowych drogą elektroniczną na wskazany adres e-mail oraz drogą telefoniczną na wskazany numer telefonu dotyczących produktów i usług Administratora.',
  CONFIRM_BUTTON: 'Zatwierdź',
  ALREADY_HAVE_AN_ACCOUNT: 'Posiadasz już konto?',
  PRIVACY_PLOLICY_PAGE_TITLE: 'Polityka prywatności',
  TERMS_OF_USE_PAGE_TITLE: 'Warunki korzystania',
  GOAL_OPTIONS_LABELS: {
    '30_days_challenge': '30-Day Challenge',
    headstand: 'Naucz się stawać na głowie',
    basics: 'Nauka podstaw',
    other: 'Inne',
    health_care: 'Zadbać o zdrowie fizyczne',
    twine: 'Zrobić szpagat',
    improve_mobilitiy: 'Poprawić mobilność ciała',
    gear_up: 'Wzmocnić się',
    imprive_condition: 'Poprawić kondycję',
    get_rid_of_tensions: 'Pozbyć się napięć',
    get_rid_of_stress: 'Zredukować stres',
    relax: 'Szukać wyciszenia (tutaj relaks i medytacje)',
    be_young: 'Zachować młodość',
  },
  GOAL_OPTIONS_NAMES: {
    '30_days_challenge': '',
    headstand: 'stanie na głowie',
    basics: 'podstawy',
    other: '',
    health_care: 'Zadbać o zdrowie fizyczne',
    twine: 'Zrobić szpagat',
    improve_mobilitiy: 'Poprawić mobilność ciała',
    gear_up: 'Wzmocnić się',
    imprive_condition: 'Poprawić kondycję',
    get_rid_of_tensions: 'Pozbyć się napięć',
    get_rid_of_stress: 'Zredukować stres',
    relax: 'Szukać wyciszenia (tutaj relaks i medytacje)',
    be_young: 'Zachować młodość',
  },
  GOAL_OPTIONS_TITLES: {
    '30_days_challenge': 'Moje 30-dniowe wyzwanie',
    headstand: 'Moja droga do stania na głowie',
    basics: 'Zacznij od podstaw.',
    other: '',
    health_care: 'Zadbać o zdrowie fizyczne',
    twine: 'Zrobić szpagat',
    improve_mobilitiy: 'Poprawić mobilność ciała',
    gear_up: 'Wzmocnić się',
    imprive_condition: 'Poprawić kondycję',
    get_rid_of_tensions: 'Pozbyć się napięć',
    get_rid_of_stress: 'Zredukować stres',
    relax: 'Szukać wyciszenia (tutaj relaks i medytacje)',
    be_young: 'Zachować młodość',
  },
  PRIVACY_POLICY_PAGE: {
    section_1: {
      title: '1. Postanowienia ogólne',
      content: `
        <ol>
          <li>Administratorem danych osobowych jest StretchMe Sp. z o.o. z siedzibą w Krakowie, przy ul. Lubomirskiego 20, 31-509 Kraków, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy dla Krakowa Śródmieścia w Krakowie, XI Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000881960, NIP 5213919480, REGON 388143664, kapitał zakładowy w wysokości 10.000,00 złotych (zwany dalej „<strong>Administratorem</strong>").</li>
          <li>Dane osobowe osób korzystających z aplikacji Administratora (dalej „Aplikacja") lub z usług świadczonych przez Administratora (dalej „<strong>Usługa</strong>") (dalej „<strong>Użytkownicy</strong>") są przetwarzane z poszanowaniem zasad przewidzianych w Rozporządzeniu Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych, zwane dalej „RODO"), jak również przewidzianych w polskiej ustawie o ochronie danych osobowych, aktów wykonawczych do tej ustawy oraz ustawie o świadczeniu usług drogą elektroniczną z dnia 18 lipca 2002 r. (Dz. U. z 2002 r., nr 144, poz. 1204, ze zm.).</li>
          <li>Danymi osobowymi są wszelkie informacje o zidentyfikowanej lub możliwej do zidentyfikowania osobie fizycznej. Możliwa do zidentyfikowania osoba fizyczna to osoba, którą można bezpośrednio lub pośrednio zidentyfikować, w szczególności na podstawie identyfikatora takiego jak imię i nazwisko, adres e-mail, numer identyfikacyjny, dane o lokalizacji, identyfikator internetowy lub jeden bądź kilka szczególnych czynników określających fizyczną, fizjologiczną, genetyczną, psychiczną, ekonomiczną, kulturową lub społeczną tożsamość osoby fizycznej.</li>
          <li>Przetwarzaniem danych osobowych jest co do zasady każda czynność dokonywana na danych osobowych, niezależnie od tego, czy jest dokonywana w sposób zautomatyzowany czy niezautomatyzowany, w tym np. zbieranie, przechowywanie, utrwalanie, porządkowanie, modyfikowanie, przeglądanie, wykorzystywanie, udostępnianie, ograniczenie, usuwanie lub niszczenie.</li>
          <li>Administrator uprzejmie prosi Użytkowników o uważne zapoznanie się z treścią niniejszej Polityki Prywatności przed rozpoczęciem korzystania z Aplikacji lub Usług.</li>
          <li>Ściągnięcie, zainstalowanie i korzystanie z Aplikacji jest równoznaczne z wyrażeniem zgody przez Użytkownika na gromadzenie, wykorzystywanie oraz przesyłanie danych osobowych Użytkownika w celu przetwarzania w sposób opisany w niniejszej Polityce Prywatności. Jeśli Użytkownik zdecyduje się nie przekazywać Administratorowi swoich danych osobowych wówczas Użytkownik nie będzie mógł w pełni korzystać z Usług.</li>
        </ol>
      `,
    },
    section_2: {
      title: 'II. PODSTAWA PRAWNA PRZETWARZANIA DANYCH',
      content: `
        <ol>
          <li>Podstawą prawną przetwarzania danych osobowych przez Administratora w celu:
            <ol>
              <li>założenia konta Użytkownika, jest art. 6 ust. 1 lit. a RODO, tj. wyrażenie zgody Użytkownika na przetwarzanie danych;</li>
              <li>realizacji działań zmierzających do zawarcia i realizacji Usługi, jest art. 6 ust. 1 lit. b RODO, tj. podejmowanie czynności niezbędnych do zawarcia umowy, której przedmiotem jest realizacja Usługi i konieczność jej realizacji przez Administratora na rzecz danego Użytkownika;</li>
              <li>realizacji Usługi, w zakresie danych biometrycznych (cechy aktywności i sprawności fizycznej oraz wizerunek) i danych dotyczących zdrowia, jest art. 9 ust. 2 lit. a RODO, tj. wyrażenie wyraźnej zgody Użytkownika na przetwarzanie danych oraz art. 9 ust. 2 lit. f RODO, tj. przetwarzanie jest niezbędne do ustalenia, dochodzenia lub obrony roszczeń przez Administratora;</li>
              <li>zamówienia newslettera Administratora, jest art. 6 ust. 1 lit. a RODO, tj. wyraźna zgoda Użytkownika na przetwarzanie danych;</li>
              <li>wyrażenia zgody na gromadzenie danych lokalizacyjnych przy wykorzystaniu funkcjonalności urządzeń mobilnych Użytkownika, jest art. 6 ust. 1 lit. a RODO, tj. wyrażenie zgody Użytkownika na przetwarzanie danych;</li>
              <li>niezbędnym do wypełnienia obowiązków prawnych ciążących na Administratorze, jest art. 6 ust. 1 lit. c RODO, tj. realizacji obowiązków Administratora wynikających z przepisów prawa, dotyczących działalności Administratora, w szczególności na podstawie przepisów księgowych i podatkowych;</li>
              <li>niezbędnym do wypełnienia uzasadnionych interesów Administratora, w tym prowadzenia działań marketingowych i kampanii reklamowych Usług Administratora drogą elektroniczną lub telefoniczną na podstawie odrębnej wyrażonej przez Użytkownika zgody z art. 6 ust. 1 lit. a RODO, tj. wyraźna zgoda Użytkownika na przetwarzanie tych danych;</li>
              <li>niezbędnym do realizacji prawnie uzasadnionych interesów Administratora lub stron trzecich, jest art. 6 ust. 1 lit. f RODO, tj. ze względu na prawnie usprawiedliwione cele realizowane przez Administratora, w szczególności w celu ustalenia, dochodzenia lub obrony roszczeń, wewnętrznych celów administracyjnych Administratora, w tym analiz, statystyk i raportowania wewnętrznego Administratora.</li>
            </ol>
          </li>
          <li>Administrator przetwarza dane osobowe podane dobrowolnie przez Użytkowników oraz dane zbierane automatycznie z zakresu korzystania z Aplikacji zgodnie z art. 6 ust. 1 lit. a RODO, tj. wyrażenie zgody Użytkownika na przetwarzanie danych oraz art. 6 ust. 1 lit. f RODO, tj. ze względu na prawnie usprawiedliwione cele realizowane przez Administratora, a w szczególności w celu marketingu bezpośredniego Usług Administratora, jak również w celu optymalizacji, usprawnienia i personalizacji funkcji Aplikacji oraz w celu tworzenia statystyk. Administrator zapewnia, że takie przetwarzanie nie będzie naruszać praw i wolności osób, których dane dotyczą.</li>
          <li>Dane osobowe podawane dobrowolnie przez Użytkownika nie są łączone ze zbieranymi automatycznie danymi z zakresu sposobu korzystania przez tego Użytkownika z Aplikacji. Administrator informuje, że ze względów technicznych może dojść do takiego połączenia, jednakże w takiej sytuacji dane tak połączone będą przetwarzane przez Administratora wyłącznie ze względu na prawnie usprawiedliwione cele realizowane przez Administratora, a w szczególności w celu optymalizacji, usprawnienia i personalizacji funkcji Aplikacji oraz w celu tworzenia statystyk na wewnętrzne potrzeby Administratora.</li>
          <li>Za odrębnie wyrażonymi zgodami Użytkownika, jego dane osobowe mogą być przetwarzane w celu przesyłania mu drogą elektroniczną informacji handlowych na temat Usług Administratora. Użytkownik ma prawo w każdym czasie żądać zaprzestania wysyłania mu informacji handlowych drogą elektroniczną lub zaprzestania wykorzystywania jego numeru telefonu do celów marketingu bezpośredniego.</li>
          <li>Dane osobowe Użytkowników będą przetwarzane przez okres niezbędny do realizacji wskazanych powyżej celów przetwarzania, tj.:
            <ol>
              <li>w przypadku wyrażenia zgody przez Użytkownika – do czasu jej wycofania;</li>
              <li>w zakresie realizacji działań zmierzających do zawarcia i realizacji Usługi na żądanie Użytkownika – do czasu zakończenia ich realizacji, a po tym czasie – w zakresie ustalenia, dochodzenia lub obrony roszczeń z tymi działaniami – do upływu okresu przedawnienia tych roszczeń określonego przepisami prawa;</li>
              <li>w zakresie realizacji Usługi na żądanie Użytkownika – do czasu zakończenia ich realizacji, a po tym czasie – w zakresie ustalenia, dochodzenia lub obrony roszczeń z tymi działaniami – do upływu okresu przedawnienia tych roszczeń określonego przepisami prawa;</li>
              <li>w zakresie wypełniania obowiązków prawnych ciążących na Administratorze w związku z prowadzeniem działalności i realizacją Usług – przez okresy wskazane w odpowiednich przepisach prawa dotyczących prowadzenia działalności przez Administratora, w szczególności:
                <ol type="a">
                  <li>dla dokumentacji podatkowej – przez okres 5 lat licząc od końca roku kalendarzowego, w którym upłynął termin płatności podatku,</li>
                  <li>dla dokumentacji księgowej – przez okres 5 lat licząc od końca roku kalendarzowego, w którym operacje, transakcje i postępowanie zostały ostatecznie zakończone, spłacone, rozliczone lub przedawnione;</li>
                </ol>
              </li>
              <li>w przypadku, gdy przetwarzanie odbywa się w celach wynikających z prawnie uzasadnionych interesów realizowanych przez Administratora lub strony trzecie – do czasu ich zrealizowania, lub do czasu wniesienia przez Użytkownika sprzeciwu wobec takiego przetwarzania, o ile nie występują prawnie uzasadnione podstawy dalszego przetwarzania danych Użytkownika przez Administratora;</li>
              <li>w przypadku, gdy przetwarzanie odbywa się w celach marketingowych Administratora drogą elektroniczną lub telefoniczną będą przetwarzane do czasu ewentualnego cofnięcia odpowiednich zgód;</li>
              <li>w zakresie informacji niezbędnych do obsługi reklamacji będą przetwarzane do czasu wygaśnięcia uprawnień z tego tytułu;</li>
              <li>w przypadku przetwarzania w celach archiwizacyjnych będą przetwarzane przez 3 lata od zakończenia świadczenia Usług.</li>
            </ol>
          </li>
        </ol>
      `,
    },
    section_3: {
      title:
        'III. CEL I ZAKRES ZBIERANIA DANYCH OSOBOWYCH ORAZ ODBIORCY DANYCH',
      content: `
        <ol>
          <li>Każdorazowo cel przetwarzania, zakres danych osobowych oraz ewentualni odbiorcy danych osobowych Użytkownika, który dane przekazuje do przetwarzania Administratorowi wynikają ze zgody Użytkownika lub przepisów prawa, dodatkowo dane mogą być doprecyzowane w wyniku działań podejmowanych przez Użytkownika w Aplikacji lub ramach innych kanałów komunikacji z Użytkownikiem.</li>
          <li>Administrator dokłada szczególnej staranności dla ochrony praw i wolności osób, których dane przetwarza, a w szczególności zapewnia, że zbierane przez niego dane są przetwarzane:
            <ol>
              <li>zgodnie z prawem, rzetelnie i w sposób przejrzysty dla osoby, której dotyczą (zgodność z prawem, rzetelność i przejrzystość);</li>
              <li>w konkretnych, wyraźnych i prawnie uzasadnionych celach i nie są przetwarzane dalej w sposób niezgodny z tymi celami (ograniczenie celu);</li>
              <li>adekwatnie, stosownie oraz w sposób ograniczony do tego, co niezbędne do celów, w których są przetwarzane (minimalizacja danych);</li>
              <li>prawidłowo, a w razie potrzeby dane osobowe są uaktualniane (prawidłowość);</li>
              <li>w formie umożliwiającej identyfikację osoby, której dane dotyczą, przez okres nie dłuższy, niż jest to niezbędne do celów, w których dane te są przetwarzane (ograniczenie przechowywania);</li>
              <li>w sposób zapewniający odpowiednie bezpieczeństwo danych osobowych, w tym ochronę przed niedozwolonym lub niezgodnym z prawem przetwarzaniem oraz przypadkową utratą, zniszczeniem lub uszkodzeniem, za pomocą odpowiednich środków technicznych lub organizacyjnych (integralność i poufność).</li>
            </ol>
          </li>
          <li>Wynikających z celów przetwarzania danych osobowych Użytkownik podaje następujące dane:
            <ol>
              <li>dane tożsamości – imię, nazwisko, płeć;</li>
              <li>dane kontaktowe – adres e-mail, adres dostawy, adres rozliczeniowy, numer telefonu;</li>
              <li>dane finansowe – dane dotyczące rachunku bankowego i karty płatniczej;</li>
              <li>dane transakcyjne – szczegóły dotyczące płatności na rzecz i od Użytkownika oraz inne szczegóły dotyczące produktów i Usług zakupionych od Administratora;</li>
              <li>dane techniczne – adres protokołu internetowego (IP), dane do logowania, typ i wersję przeglądarki, ustawienia i lokalizację strefy czasowej, typy i wersje wtyczek do przeglądarki, system operacyjny i platformę oraz inne technologie na urządzeniach używanych przez Użytkownika do uzyskania dostępu do Aplikacji;</li>
              <li>dane profilowe – nazwę użytkownika (login) i hasło, zakupy lub zamówienia dokonane przez użytkownika, preferencje, opinie i odpowiedzi na ankiety;</li>
              <li>dane użytkowe – informacje o sposobie korzystania z Aplikacji, produktów i Usług;</li>
              <li>dane marketingowe i komunikacyjne – preferencje otrzymania marketingu od Administratora.</li>
            </ol>
          </li>
          <li>Administrator przetwarza również szczególne kategorie danych osobowych przekazanych za wyraźną zgodą Użytkownika:
            <ol>
              <li>dane biometryczne - cechy aktywności i sprawności fizycznej oraz wizerunek;</li>
              <li>dane dotyczące zdrowia.</li>
            </ol>
          </li>
          <li>Aplikacja Administratora i Usługi mogą udostępniać Administratorowi identyfikatory urządzeń, dane lokalizacyjne. Identyfikatory urządzeń obejmują identyfikatory związane z nośnikami urządzeń mobilnych, takie jak numer IMEI, identyfikatory specyficzne dla platformy, jak IDFA firmy Apple; a także identyfikatory stanowiące część sprzętu urządzenia, takie jak adres MAC WiFi i numer seryjny. Dokładne informacje o tym, jakie identyfikatory urządzeń są przesyłane, mogą zależeć od platformy Państwa urządzenia mobilnego i wersji systemu operacyjnego. Dane lokalizacyjne z kolei można uzyskać z usług GPS, na podstawie triangulacji wież telefonii komórkowej lub znanej lokalizacji pobliskich punktów dostępu WiFi. Administrator może wykorzystywać identyfikatory do ściśle niezbędnych celów określonych w dziale II w punktach 1 i 2 powyżej.</li>
          <li>Aplikacja nie jest przeznaczona dla osób poniżej 16 roku życia. Administrator świadomie nie zbiera informacji o osobach poniżej 16 roku życia. Jeśli Użytkownik uważa, że Administrator w sposób niezamierzony zebrał takie informacje, powinien powiadomić o tym Administratora pod adresem poczty elektronicznej: kontakt@stretchme.pl, w celu umożliwienia Administratorowi usunięcia tych informacji.</li>
          <li>Przekazywanie danych osobowych przez Użytkownika jest dobrowolne. Użytkownik może nie przekazywać swoich danych osobowych, jednakże w takim wypadku Administrator może nie być w stanie świadczyć Usług na rzecz Użytkownika. W związku z charakterem niektórych Usług, Użytkownik może być poproszony o podanie dodatkowych danych osobowych. Zakres dodatkowych danych osobowych wskazany będzie przez Administratora.</li>
          <li>Cele zbierania danych osobowych Użytkownika przez Administratora określone zostały w dziale II w punktach 1 i 2 powyżej.</li>
          <li>W związku z przetwarzaniem danych w celach wskazanych w dziale II w punktach 1 i 2, dane osobowe Użytkowników mogą być udostępnione innym odbiorcom danych osobowych. Odbiorcami danych osobowych Użytkowników mogą być w szczególności:
            <ol>
              <li>pracownicy, współpracownicy Administratora;</li>
              <li>inne podmioty upoważnione do odbioru danych osobowych Użytkowników na podstawie odpowiednich przepisów prawa;</li>
              <li>podmioty, które przetwarzają dane osobowe Użytkowników w imieniu Administratora na podstawie zawartej z Administratorem umowy powierzenia przetwarzania danych osobowych (tzw. podmioty przetwarzające), np. świadczące usługi z zakresu IT, usługi płatnicze, usługi księgowe, usługi prawne i inne usługi wspierające prowadzenie działalności przez Administratora.</li>
            </ol>
          </li>
          <li>Podmioty, którym powierzono przetwarzanie danych osobowych, zobowiązane są do przestrzegania zasad poufności i bezpieczeństwa danych osobowych, a w szczególności nieudostępniania danych osobowych osobom nieuprawnionym, oraz do stosowania fizycznych i technicznych środków bezpieczeństwa, adekwatnych do sposobu przetwarzania tych danych. Administrator zapewnia Użytkownikowi na jego życzenie szczegółową informację o podmiocie, któremu powierzył przetwarzanie danych, zakresie powierzonych do przetwarzania danych osobowych oraz terminie ich przekazania. Ponadto, w tym trybie Administrator zapewnia także dostęp do aktualnej i szczegółowej informacji o wykorzystanych lub udostępnionych przez Administratora środkach technicznych zapobiegających pozyskiwaniu i modyfikowaniu przez osoby nieuprawnione danych osobowych przesyłanych przez Użytkownika drogą elektroniczną.</li>
          <li>Przy prowadzeniu Aplikacji Administrator korzysta z narzędzi dostarczonych przez podmioty zewnętrzne. Dane Użytkownika mogą zostać przekazane poza Europejski Obszar Gospodarczy (EOG). Dane osobowe mogą być przekazane jedynie do takich państw trzecich (państwa poza EOG) lub podmiotów w państwach trzecich, w stosunku do których decyzją Komisji Europejskiej stwierdzono odpowiedni stopień ochrony danych, zastosowano w umowach z tymi podmiotami standardowe klauzule ochrony danych lub zastosowano odpowiednie inne zabezpieczenia, o których mowa w powszechnie obowiązujących przepisach prawa. W związku z przekazaniem danych poza EOG Użytkownik może zażądać dalszych informacji o stosowanych zabezpieczeniach w tym zakresie, uzyskać kopię tych zabezpieczeń lub informację o miejscu ich udostępnienia kontaktując się z Administratorem.</li>
          <li>Dane osobowe Użytkownika nie będą sprzedawane ani też przekazywane podmiotom trzecim w innych celach niż opisane powyżej.</li>
        </ol>
      `,
    },

    section_4: {
      title: 'IV. PRAWA UŻYTKOWNIKA',
      content: `
        <ol>
          <li>Każdemu Użytkownikowi, którego dane osobowe są przetwarzane przez Administratora, przysługuje:
            <ol>
              <li>prawo dostępu do treści danych. Jest to prawo do uzyskania od Administratora potwierdzenia, czy przetwarza dane osobowe Użytkownika oraz prawo uzyskania dostępu do tych danych (w tym ich kopii), a także w szczególności do następujących informacji:
                <ol type="a">
                  <li>o celach przetwarzania danych osobowych Użytkownika,</li>
                  <li>o kategoriach przetwarzanych danych osobowych,</li>
                  <li>informacji o odbiorcach lub kategoriach odbiorców, którym Administrator ujawnił dane osobowe Użytkownika lub którym ma zamiar ujawnić te dane,</li>
                  <li>o możliwości skorzystania z prawa w zakresie ochrony danych osobowych i o sposobie realizacji tych praw,</li>
                  <li>o prawie do wniesienia skargi do organu nadzorczego,</li>
                  <li>o zautomatyzowanym podejmowaniu decyzji, w tym o profilowaniu, a także o konsekwencjach takiego przetwarzania dla Użytkownika, o ile nie dotyczy to przetwarzania danych osobowych na potrzeby przeciwdziałania praniu pieniędzy i finansowaniu terroryzmu oraz przeciwdziałania innym przestępstwom;</li>
                </ol>
              </li>
              <li>prawo do sprostowania danych. Jest to prawo do żądania od Administratora niezwłocznego sprostowania danych osobowych Użytkownika, które są nieprawidłowe oraz do żądania uzupełnienia niekompletnych danych osobowych;</li>
              <li>prawo do usunięcia danych. Jest to prawo do żądania od Administratora niezwłocznego usunięcia danych osobowych dotyczących Użytkownika (zwane również „prawem do bycia zapomnianym"). Administrator ma w takiej sytuacji obowiązek usunąć dane osobowe Użytkownika, pod warunkiem, że jest spełniona jedna z następujących przesłanek:
                <ol type="a">
                  <li>dane osobowe nie są już niezbędne dla celów, dla których zostały zebrane,</li>
                  <li>cofnięto zgodę, na której opiera się przetwarzanie i Administrator nie ma innej podstawy prawnej przetwarzania,</li>
                  <li>dane osobowe były przetwarzane niezgodnie z prawem,</li>
                  <li>dane osobowe muszą zostać usunięte w celu wywiązania się z obowiązku prawnego.</li>
                </ol>
                Nie jest możliwa realizacja prawa do usunięcia danych osobowych, w przypadku, gdy Administrator jest zobowiązany na podstawie przepisów prawa do dalszego przetwarzania danych osobowych w zakresie określonym przez odpowiednie przepisy prawa lub w celach niezbędnych do ustalenia, dochodzenia lub obrony roszczeń;</li>
              <li>prawo do ograniczenia przetwarzania danych. Jest to prawo do żądania od Administratora ograniczenia przetwarzania danych osobowych Użytkownika, gdy:
                <ol type="a">
                  <li>Użytkownik kwestionuje prawidłowość danych osobowych przetwarzanych przez Administratora,</li>
                  <li>przetwarzanie danych osobowych Użytkownika jest niezgodne z prawem, a Użytkownik sprzeciwia się usunięcia jego danych osobowych,</li>
                  <li>Administrator nie potrzebuje już danych osobowych Użytkownika, ale są one Użytkownikowi potrzebne do ustalenia, dochodzenia lub obrony roszczeń.</li>
                </ol>
                W przypadku realizacji prawa do ograniczenia przetwarzania danych osobowych, Administrator może przetwarzać dane osobowe Użytkownika, z wyjątkiem ich przechowywania, wyłącznie za zgodą Użytkownika lub w celu ustalenia, dochodzenia lub obrony roszczeń lub w celu ochrony praw innej osoby fizycznej lub prawnej lub z uwagi na ważne względy interesu publicznego;</li>
              <li>prawo do przenoszenia danych. Jest to prawo do otrzymania w ustrukturyzowanym, powszechnie używanym formacie nadającym się do odczytu maszynowego danych osobowych, dostarczonych Administratorowi przez Użytkownika oraz prawdo do żądania przesłania danych osobowych innemu administratorowi danych, jeśli jest to technicznie możliwe. Prawo to przysługuje, jeśli spełnione są łącznie następujące przesłanki:
                <ol type="a">
                  <li>przetwarzanie odbywa się w sposób zautomatyzowany,</li>
                  <li>dane przetwarzane są na podstawie zgody lub na podstawie umowy.</li>
                </ol>
                Jeżeli jednak dane, które mają ulec przeniesieniu na żądanie Użytkownika, stanowią tajemnicę przedsiębiorstwa Administratora, wówczas Administrator ma prawo odmówić wykonania żądania Użytkownika do przeniesienia takich danych, o czym zostanie poinformowany;</li>
              <li>prawo do wniesienia sprzeciwu wobec przetwarzania danych. Użytkownik ma prawo w dowolnym momencie wnieść sprzeciw – z przyczyn związanych ze szczególną sytuacją Użytkownika – wobec przetwarzania danych opartego na prawie uzasadnionym interesie Administratora (tj. na podstawie art. 6 ust. 1 lit. f RODO), w tym profilowania. W takiej sytuacji Administrator nie będzie mógł dalej przetwarzać danych w tych celach, chyba że istnieją ważne prawnie uzasadnione podstawy do przetwarzania lub dane potrzebne są Administratorowi do ustalenia, dochodzenia lub obrony roszczeń. Jeżeli dane osobowe Użytkownika są przetwarzane na potrzeby marketingu bezpośredniego, Użytkownik ma prawo w dowolnym momencie wnieść sprzeciw wobec przetwarzania na potrzeby takiego marketingu, w tym profilowania, w zakresie, w jakim przetwarzanie jest związane z takim marketingiem bezpośrednim. Wówczas Administratorowi nie będzie wolno przetwarzać danych osobowych Użytkownika do takich celów.</li>
            </ol>
          </li>
          <li>W przypadkach, w których przetwarzanie danych osobowych Użytkownika odbywa się na podstawie zgody użytkownika (art. 6 ust. 1 lit. a RODO), Użytkownikowi przysługuje prawo do wycofania tej zgody w dowolnym momencie, bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem.</li>
          <li>Podanie danych przez Użytkownika ma charakter dobrowolny, jednak konsekwencją niepodania niezbędnych danych będzie brak możliwości podjęcia działań polegających na kontakcie Administratora z Użytkownikiem lub zmierzających do zawarcia i realizacji Usług z Administratorem na żądanie Użytkownika.</li>
          <li>Przetwarzanie danych osobowych Użytkownika może odbywać się w sposób zautomatyzowany, co może wiązać się z profilowaniem. Profilowanie polega na tworzeniu profilu Użytkownika, z wykorzystaniem algorytmów komputerowych, na podstawie informacji posiadanych przez Administratora w celu zidentyfikowania, jakie są możliwe cechy i preferencje Użytkownika. Działania te posłużą Użytkownikowi do przygotowania i przedstawienia oferty/informacji marketingowych jak najlepiej dopasowanych do potrzeb Użytkownika.</li>
          <li>Cofniecie zgody lub sprzeciw co do przetwarzania danych osobowych następuje poprzez wysłanie wiadomości na adres poczty elektronicznej Administratora: kontakt@stretchme.pl</li>
          <li>Po cofnięciu zgody lub wyrażeniu sprzeciwu, dane osobowe Użytkownika nie będą więcej przetwarzane w celach, w których zostały przekazane. Zmianę / uzupełnienie danych osobowych Użytkownik może przeprowadzić w drodze przesłania stosownego wniosku na adres poczty elektronicznej Administratora: kontakt@stretchme.pl</li>
          <li>W przypadkach uznania, iż przetwarzanie przez Administratora danych osobowych Użytkownika narusza przepisy RODO, Użytkownikowi przysługuje prawo do wniesienia skargi do organu nadzorczego (na terytorium Polski jest to Prezes Urzędu Ochrony Danych Osobowych).</li>
          <li>Kontakt z osobą nadzorująca przetwarzanie danych osobowych w organizacji Administratora jest możliwy drogą elektroniczną pod adresem poczty elektronicznej: kontakt@stretchme.pl</li>
        </ol>`,
    },
    section_5: {
      title: 'V. KONTAKT Z ADMINISTRATOREM',
      content: `
        <ol>
          <li>Użytkownik w każdej chwili ma możliwość bezpośredniego kontaktu z Administratorem poprzez
          przesłanie stosownej wiadomości pisemnie lub pocztą elektroniczną na adres Administratora, tj.:
            <ol>
              <li>pisemnie na adres: Młocarni 69, Warszawa</li>
              <li>elektronicznie na adres poczty elektronicznej: kontakt@stretchme.pl</li>
            </ol>
          </li>
          <li>Administrator przechowuje korespondencję z Użytkownikiem w celach statystycznych oraz jak
          najlepszej i najszybszej reakcji na pojawiające się zapytania, a także w celach rozstrzygnięć
          reklamacji i podejmowanych na podstawie zgłoszeń ewentualnych decyzji o interwencjach
          administracyjnych dotyczących wskazanych danych. Adresy oraz dane w ten sposób gromadzone
          nie będą wykorzystywane do komunikacji z Użytkownikiem w celu innym niż realizacja zgłoszenia.</li>
          <li>Wszelkie wnioski Użytkownika o dokonanie przez Administratora czynności związanych z danymi
          osobowymi Administrator będzie wykonywał po uprzedniej weryfikacji Użytkownika. W tym celu
          zastrzega sobie prawo by ponownie zwrócić się do Użytkownika o potwierdzenie jego danych, w
          tym osobowych, np. w postaci imienia, nazwiska, adresu e-mail, etc. Powyższe dotyczy tych
          samych danych, w tym osobowych, które wcześniej zostały przekazane przez Użytkownika i na
          których przetwarzanie Użytkownik wyraził zgodę. Podanie tych danych nie jest obowiązkowe, lecz
          może być niezbędne do dokonania czynności lub uzyskania informacji, o których przekazanie wnosi
          Użytkownik</li>
        </ol>`,
    },
    section_6: {
      title: 'VI. ZABEZPIECZENIA',
      content: `
        <ol>
          <li>Administrator stosuje odpowiednie środki techniczne i organizacyjne zapewniające bezpieczeństwo
          ochrony przetwarzanych danych osobowych adekwatne do zagrożeń oraz kategorii danych
          osobowych objętych ochroną, a w szczególności zabezpiecza dane przed ich udostepnieniem
          osobom nieupoważnionym, wglądem lub zabraniem przez osobę uprawnioną, przetwarzaniem z
          naruszeniem obowiązujących przepisów oraz zmianą, utratą, uszkodzeniem lub zniszczeniem.</li>
          <li>Uwzględniając stan wiedzy technicznej, koszt wdrażania oraz charakter, zakres, kontekst i cele
          przetwarzania a także ryzyko naruszenia praw lub wolności osób fizycznych o różnym
          prawdopodobieństwie wystąpienia i wadze zagrożenia, Administrator wdraża odpowiednie środki
          techniczne i organizacyjne, aby zapewnić stopień bezpieczeństwa odpowiadający temu ryzyku, w
          tym między innymi w stosownym przypadku:
            <ol>
              <li>zdolności ciągłego zapewnienia poufności, integralności, dostępności i odporności systemów i
              usług przetwarzania;</li>
              <li>zdolności szybkiego przywrócenia dostępności danych osobowych i dostępu do nich w razie
              incydentu fizycznego lub technicznego;</li>
              <li>regularnych testów, pomiarów i ocen skuteczności środków technicznych i organizacyjnych
              mających zapewnić bezpieczeństwo przetwarzania</li>
            </ol>
          </li>
        </ol>`,
    },
    section_7: {
      title: 'VII. POSTANOWIENIA KOŃCOWE',
      content: `
        <ol>
          <li>Aby uczynić Aplikację jeszcze bardziej atrakcyjny dla Użytkownika, Administrator posługuje się
          plikami „cookies". Plik „cookies" to niewielki plik, zawierający łańcuch znaków, których jest wysyłany
          do komputera Użytkownika podczas korzystania z Aplikacji. Informacje zbierane automatycznie przy
          pomocy „cookies" pozwalają dostosować usługi i treści oferowane przez Administratora do
          indywidualnych potrzeb i prefenracji Użytkownika, jak również służą do opracowania ogólnych
          statystyk dotyczących korzystania przez Użytkownika z Aplikacji. Dzięki plikowi „cookie".
          Przeglądarka Użytkownika może zostac rozpoznana przez witryny należące do Administratora.
          Większość używanych przez Administratora „cookies" jest po zakończeniu sesji przez Użytkownika
          usuwana z jego dysku twardego („session cookies"). Pozostałe poliki „cookies" pozostają na
          komputerze Użytkownika w celu umożliwienia rozpoznania komputera Użytkownika podczas
          kolejnej wizyty („trwałe cookies"). Wyłączenie przez Użytkownika opcji pozwalającej na zapisywanie
          pików „cookies" w przeglądarce internetowej może utrudnić lub uniemożliwić korzystanie z Aplikacji.</li>
          <li>Mając na uwadze fakt, że rynek usług monitoringu sieci teleinformatycznej rozwija się niezwykle
          dynamicznie, Administrator stara się informować Użytkowników o korzystaniu z usług nowych
          podmiotów w tym zakresie, a Użytkownik wyraża zgodę, na to, aby w przyszłości mogły to być
          również inne firmy. Jednocześnie, Administrator informuje, że Użytkownik w każdej chwili może
          cofnąć swoją zgodę w tym zakresie poprzez zmianę ustawień swojej przeglądarki.</li>
          <li>Szczegółowe zasady polityki „cookies" Administrator udostępnia do publicznej wiadomości i
          znajdują się pod adresem https://app-dev.physiostretch.app/pdfs/cookies-policy_eng.pdf</li>
          <li>W Aplikacji mogą zostać umieszczone linki (np. w formie logotypów podmiotów trzecich), które w
          razie aktywacji przekierowują Użytkownika na zewnętrzną stronę internetową. Administrator w
          żadnym wypadku nie ponosi odpowiedzialności za skutki tego rodzaju przekierowań ani nie ma
          wpływu na treści zawarte na takich stronach internetowych. Administrator nie ponosi
          odpowiedzialności za treść polityk prywatności i bezpieczeństwa obowiązujących na tych stronach,
          ani za stosowane w trakcie ich przeglądania „cookies". Zachęcamy Użytkowników korzystających z
          tego rodzaju odesłań do zapoznawania się z treścią odpowiednich dokumentów prawnych
          obowiązujących na tych stronach.</li>
          <li>Usługi i funkcje w ramach Aplikacji mogą z czasem ulegać rozszerzeniu, co oznacza, że
          Administrator zastrzega sobie prawo do wprowadzania zmian w niniejszej Polityce Prywatności,
          przy zachowaniu jednak praw dotychczas nabytych przez Użytkowników. Polityka Prywatności
          dotyczy każdego Użytkownika. Zmiany mogą nastąpić m.in. z następujących ważnych powodów:
            <ol>
              <li>miany obowiązujących przepisów, w szczególności w zakresie ochrony danych osobowych,
              prawa telekomunikacyjnego, usług świadczonych drogą elektroniczną oraz regulujących
              prawa konsumentów, wpływające na prawa i obowiązki Administratora lub prawa i obowiązki
              Użytkowników;</li>
              <li>rozwój funkcjonalności lub Usług podyktowany postępem technologii internetowej, w tym
              stosowanie/wdrożenie nowych rozwiązań technologicznych lub technicznych, mające wpływ
              na zakres Polityki Prywatności.</li>
            </ol>
          </li>
          <li>Administrator każdorazowo umieści w ramach Aplikacji informację o zmianach w Polityce
          Prywatności. Wraz z każdą zmianą, nowa wersja Polityki Prywatności będzie udostępniana w
          Aplikacji do publicznej wiadomości z nową datą. Archiwalne Polityki Prywatności znajdują się pod
          adresem www.physiostretch.app/archiwum</li>
          <li>W razie wątpliwości lub sprzeczności pomiędzy Polityką Prywatności, a udzielonymi przez
          Użytkownika zgodami na przetwarzanie danych osobowych, niezależnie od postanowień Polityki
          Prywatności, zawsze podstawą do podejmowania oraz określenia przez Administratora zakresu
          działań są dobrowolnie udzielone przez Użytkownika zgody lub przepisy prawa.</li>
        </ol>`,
    },
  },
  STRIPE_GATE: {
    title: 'Twoja subskrypcja Yuu Yoga wygasła',
    content: 'Obecnie nie masz dostępu do aplikacji.',
    button: 'Zapłać jednorazowo teraz',
    payment_success: 'Płatność zakończona sukcesem. Kliknij tutaj, aby kontynuować.',
    payment_failed: 'Płatność nie powiodła się.',
    payment: 'Kontynuuj',
    processing: 'Przetwarzanie płatności...',
  },
};
