import * as React from 'react';

interface Env {
  apiBaseUrl: string;
  revenueCatApiKey: string;
  gracePeriod: number;
}

interface StripeContextType {
  isStripePayment: boolean;
  setIsStripePayment: (value: boolean) => void;
}

export const StripeContext = React.createContext<StripeContextType | undefined>(undefined);

interface Props {
  env: Env;
}

export function StripeProvider({ children }: React.PropsWithChildren<unknown>) {
  const [isStripePayment, setIsStripePayment] = React.useState(() => {
    const isBrowser = typeof window !== 'undefined'
    const getLocalStorage = (key: string) => {
      if (isBrowser) {
        return localStorage.getItem('isStripePayment')
      }
      return null
    }

    const storedValue = getLocalStorage('isStripePayment');
    return storedValue !== null ? storedValue === 'true' : true;
  });

  const value = React.useMemo(() => ({
    isStripePayment,
    setIsStripePayment,
  }), [isStripePayment]);

  React.useEffect(() => {
    const isBrowser = typeof window !== 'undefined'
    const setLocalStorage = (key: string, value: string) => {
      if (isBrowser) {
        localStorage.setItem(key, value)
      }
    }

    setLocalStorage('isStripePayment', isStripePayment.toString());
  }, [isStripePayment]);

  return <StripeContext.Provider value={value}>{children}</StripeContext.Provider>;
}

export function useStripe() {
  const context = React.useContext(StripeContext);

  if (context == null) {
    throw new Error('useStripe() must be used within a StripeProvider');
  }

  return context;
}
