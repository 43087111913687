export default {
  android_promo_banner: {
    title: 'PhysioStretch.app',
    desc: 'stretching & yoga',
    install: 'Zainstaluj',
  },
  i_have_voucher: 'Do you have a voucher?',
  have_voucher: 'have a voucher?',
  voucher_placeholder: 'voucher code',
  send_voucher: 'Send',
  voucher_activated: 'Voucher activated',
  voucher_activated_desc:
    "We're glad you've joined us as a business customer. Your partnership is valuable to us. Once the process is complete, cancel your AppStore/Google play subscriptions",
  voucher_activated_label: 'Next',
  voucher_code_invalid: 'Code invalid',
  voucher_code_invalid_desc: 'Enter code again',
  voucher_code_invalid_label: 'Ok',
  finish_session_thanks: {
    position_to_improve: 'Position to improve:',
    tips_for_you: 'Training tips for you:',
    level_1_message: 'Great effort!',
    level_2_message: "You're on the right track!",
    level_3_message: "We're impressed!",
    level_4_message: "You're doing great!",
    level_5_message: "You're a stretching star!",
    congratulation: 'Congratulations!',
    your_score: 'Your score is:',
    best_score: 'Best score:',
    first_category_tips: {
      _1: "Regular training improves circulation and increases body flexibility. You'll see the effects in your everyday life!",
      _2: 'Regular training is half the success! Over time, your flexibility will increase.',
      _3: "It's better to exercise often and briefly than rarely and for a long time.",
      _4: 'Regular physical activity is the foundation of a healthy lifestyle. Remember - a healthy body, a healthy mind! :)',
      _5: 'Consistency is the key to self-improvement.',
    },
    second_category_tips: {
      _1: "Most positions are performed correctly only when the back is straight. It's okay if you don't reach far!",
      _2: 'When bending, remember to keep your spine and knees straight. The range of motion may be smaller, but the exercise will be performed correctly.',
      _3: 'Remember to stay hydrated during your training!',
      _4: "Don't hold your breath while performing exercises unless instructed to do so - try to follow the instructor's cues",
      _5: 'If you need to, come out of the position earlier. Your body will guide you.',
    },
    third_category_tips: {
      _1: 'Deep breathing helps deepen the stretch',
      _2: "Exercise to the point of discomfort. Stretching should not exceed your strength. If you feel that it's your maximum range, don't try to force it further.",
      _3: "Don't skip the warm-up! Stretching is a workout and requires preparing your body before starting.",
      _4: "You can achieve any goal, don't give up!",
      _5: 'Stretching can be both dynamic and static. Adjust your training to suit yourself.',
    },
  },
  COMPANY_NAME: 'Company name',
  MINIMAL_CHAR_LONG: 'Minimum 6 characters long',
  PASS_TO_SHORT: 'Too short (6 characters)',
  CAPITAL_LETTER_REQUIRED: 'Capital letters required',
  LOWERCASE_REQUIRED: 'Lowercase letters required',
  ACTIVATION_SUCCESFULL: 'Activated',
  QUESTIONS_AND_ANSWERS: {
    QUESTION_1: 'Why StretchMe?',
    QUESTION_2: 'What is instant feedback and how does it work?',
    QUESTION_3: 'What types of workouts will I find on StretchMe?',
    QUESTION_4: 'Do I need experience in stretching to use StretchMe?',
    QUESTION_5: 'Can I preview the offered workouts for free?',
    QUESTION_6: 'How does the subscription work?',
    QUESTION_7: 'What are the payment options?',
    QUESTION_8: 'On which devices can I use StretchMe?',
    QUESTION_9: 'What do I need for the workouts?',
    QUESTION_10: 'Who leads the workouts?',
    QUESTION_11: 'Are my workouts recorded?',
    ANSWER_1:
      'PhysioStretch is a training platform like no other! Thanks to our proprietary trainings, splits, ropes, and bridges will be within reach. After analyzing individual goals and the preliminary form, PhysioStretch will select a training program and automatically adjust it based on your progress. Additionally, during the training, our proprietary algorithm will track the position of your body and provide information about the correctness of the exercises you perform.',
    ANSWER_2:
      "During the training, the camera in the user's phone/tablet is actively used. This allows the exerciser to correct their poses in real-time. It ensures the proper execution of exercises, increasing user safety. Immediate feedback operates based on artificial intelligence algorithms. To fully exploit StretchMe's potential, all you need is one device!",
    ANSWER_3_1:
      'On our PhysioStretch platform, you will find all the workouts offered by the studio:',
    ANSWER_3_2:
      'proprietary stretching and strengthening classes that increase body mobility and prepare for splits, ropes, and bridges',
    ANSWER_3_3:
      'Stretch Wheel workouts focusing primarily on stretching and strengthening the spine, helping to develop correct body posture (we use a yoga wheel during trainings)',
    ANSWER_3_4:
      'yoga practices that will help you better understand your body and its capabilities',
    ANSWER_3_5:
      'RelaxStretch and StretchFlow workouts that will help you rest and relax after a hard and stressful day',
    ANSWER_4:
      "You don't need to have experience. The PhysioStretch training program offers workouts at every level of advancement, tailored to your lifestyle and needs. Our trainers will accompany you on your stretching adventure and help you achieve your goals.",
    ANSWER_5:
      'Yes! We offer 14 days free, during which you will have full access to our training library and assess whether PhysioStretch is right for you.',
    ANSWER_6_1: 'A StretchMe subscription is available for:',
    ANSWER_6_2: 'a month,',
    ANSWER_6_3: '3 months,',
    ANSWER_6_4: 'a year.',
    ANSWER_6_5:
      'The subscription is automatically renewable, so if you do not wish to continue, you must cancel it in the app. StretchMe offers a 14-day trial period.',
    ANSWER_7:
      'Payment is possible in the StretchMe app. In this case, the payment method is the one the user has linked to their AppleID. Access to the web platform is also possible using card payment through the stretchme.app website.',
    ANSWER_8:
      'StretchMe can be fully used on IOS mobile devices. It is also possible to only play workouts on the web platform, accessible from any browser.',
    ANSWER_9:
      "For most of our workouts, you only need a mat and some free space. For some workouts, having blocks, resistance bands, and a yoga wheel will be a significant advantage. If you don't currently have this equipment, don't worry, the training programs will be fully adapted to the equipment you have.",
    ANSWER_10_1:
      'All PhysioStretch workouts are led by certified teachers who you can meet daily in our brick-and-mortar studio. Would you like to meet them?',
    ANSWER_10_2: 'Monika Stasiak',
    ANSWER_10_3:
      "Coach of the proprietary StretchMe program, inspired by gymnastics, dance, and yoga. Stretching is an integral part of her personality, just like a positive attitude towards the world, gratitude, joy, and open-mindedness. She believes that beautiful moments don't just happen, beautiful moments must be created. She says she's not 'normal' since she lives from stretching, which for most people is associated with pain. She demystifies that pain. She will show you that stretching will become the most enjoyable part of your day, week, or simply your free time.",
    ANSWER_10_4: 'Natalia Zagumna',
    ANSWER_10_5:
      'A graduate of the Academy of Physical Education in Warsaw with a degree in physiotherapy, a certified personal trainer, and a passionate enthusiast of human body movement. Since her earliest years, she has been involved in sports, theater, acrobatics, and dance. She is constantly searching for new forms of self-expression. On a daily basis, she teaches people movement, but her goal is to show how beautiful and sufficient the human body can be.',
    ANSWER_10_6: 'Nikola Chmiel',
    ANSWER_10_7:
      'A former dancer and certified yoga teacher. It was dance and yoga that made her fall in love with stretching. Thanks to them, she discovered herself and her greatest passion, which is teaching others. Her classes are for everyone, led straight from the heart.',
    ANSWER_11:
      "Only if you give your consent before a particular session. If so, the data from your workout will be used to improve the machine learning algorithm's performance. During the session, you have the opportunity to take photos in specific positions to observe and measure your progress.",
  },
  QUESTIONS: 'Questions and answers',
  BUSINESS_REGISTRATION2: 'Signup',
  BUSINESS_LOGIN: 'B2B SignIN',
  BUSINESS_LOGIN_SUBTITLE:
    'If you are already our business customer, you can log in now or extend your subscription using your voucher',
  BUSINESS_REGISTER: 'B2B SignUP',
  BUSINESS_REGISTER_SUBTITLE:
    'If you are a new business customer, you can now register and start using our services. If you have a voucher, you can use it when registering.',
  TRAIN_WITH_US: 'Train with PhysioStretch',
  SET_CONSENT: 'To continue, grant the following consents',
  OWN_RISK: 'I train at my own risk',
  ALLOW_ML:
    'I consent to the processing of my data for the purpose of improving Machine Learning models',
  NEXT: 'Next',
  COOKIE_BANNER: {
    title: 'Cookies - Help us become better',
    body: 'You can decide which cookies to use by selecting the appropriate options below. Remember that your choice may affect the functionality of the application.',
    required_only: 'Necessary',
    analytics: 'Functional',
    marketing: 'All',
    manage_cookie: 'Manage cookies',
  },
  COOKIE_MANAGER: 'Cookie Files',
  NEU: 'New',
  BESONDERS_BELIEBT: 'Popular',
  BEISPIEL_ANSEHEN: 'Show example',
  POSE_COMPARISON: 'Pose comparison',
  VERGLEICHE_DEINE_POSEN_DIREKT:
    'Compare your poses with the video right after the session.',
  SMART_MIRROR: 'smart mirror',
  VERBESSERE_DICH_DIREKT_IN_DER:
    'Improve poses during the session with real-time feedback.',
  SMARTMIRROR_VERBINDEN: 'Connect SmartMirror',
  MEHR_BER: 'More about ',
  NON_FULLSCREEN: 'non-fullscreen',
  BY: 'by',
  F_R_DICH: 'For you',
  VISUELLES_FEEDBACK_SO_FUNKTI: 'Real-time feedback - how it works',
  MEHR_ERFAHREN: 'Learn more',
  CENTER_RIGHT: 'center right',
  BEST_TIGEN: 'Confirm',
  SEND_CODE_AGAIN: 'Send code again',
  ZUR_CK_ZUM_LOGIN: 'Back to login',
  PASSWORT_ZUR_CKSETZEN: 'Reset password',
  IMPRESSUM: 'Application information',
  DATENSCHUTZERKL_RUNG: 'Data protection',
  COOKIE_POLICY: 'Cookie policy',
  NUTZUNGSBEDINGUNGEN: 'Terms of use',
  LINKS_FOOTER: 'links.footer',
  LINKS_FOOTERDARKSOLID: 'links.footerDarkSolid',
  LINKS_FOOTERDARKTRANSPARENT: 'links.footerDarkTransparent',
  SERIE: 'Series (',
  BILDER1: 'Pictures)',
  ABLEHNEN: 'Reject',
  ABOUT_YOU: 'about you',
  HOME: 'Home screen',
  VERGESSEN: 'Forgot',
  KURSE: 'courses',
  MEIN_BEREICH: 'My space',
  MEIN_ACCOUNT: 'My account',
  WIE_FUNKTIONIERT_S: 'How does it work?',
  AUSLOGGEN: 'Log out',
  HALLO: 'Hello',
  NOCH: 'Still',
  TAGE: 'days',
  FINAL: 'end',
  DAY: 'day',
  TAG: 'Day #',
  INTRO: '1 - Introduction',
  MEHR_BER_DIE_POSE: 'More about the pose',
  MIT_HILFE_DER_POSE_COMPARISON:
    'Thanks to pose comparison, we offer you a personal and private training diary. In the example below, you can see how the pose comparison works. To start using pose comparison, start your first session. With pose comparison, you can compare individual poses with the teacher and monitor your progress.',
  KEINE_SORGE_DEINE_BILDER_SIN:
    "Don't worry - only you have access to the photos",
  SORRY: 'Sorry!',
  DA_HAT_WOHL_ETWAS_NICHT_FUNKTI: 'Something must have gone wrong!',
  MELDE_DICH_GERNE_BEI_UNS_UND_W: 'Feel free to contact us for help',
  QR_CODE_ZUM_EINLOGGEN: 'QR code for login',
  BITTE_SCANNE_DEN_CODE_MIT_DER: 'Please scan the code using the',
  YUUYOGA_SMARTPHONE_APP: 'PhysioStretch app',
  UM_ZUGANG_ZU_ERHALTEN: 'to gain access',
  SMARTMIRROR: 'SmartMirror+',
  SMARTMIRROR1: 'Smart Mirror',
  VERBINDUNG_ERFOLGREICH: 'Connection successful.',
  SEHE_DIREKT_WO_DU_DICH_VERBES: 'See exactly what you can improve.',
  SCANNE_DEN_CODE_MIT_DER: 'Scan the code with the',
  KAMERA_APP: 'camera',
  CLIENT: 'Client',
  ODER: 'Or',
  EMAIL_ADDRESS: 'E-Mail Address',
  AUF_DEINEM_IOS_GER_T: 'on your iOS device.',
  STELLE_DEN_SMART_MIRROR_AUF_UN:
    'Set up the smart mirror and adjust it to your yoga mat.',
  STARTE_DEINE_SESSION: 'Start your session.',
  ERSTELLE_JETZT_DEINEN_YUU_YOGA:
    'CREATE YOUR PhysioStretch ACCOUNT IN THE APP',
  UM_UNSERE_WEB_APP_ZU_NUTZEN_M:
    'To use our web app, you need to have an account. You can create one in the PhysioStretch app.',
  FFNE_DAZU_EINFACH_DIE_APP_UND:
    "To create an account, simply open the PhysioStretch app and click 'Create new account'. Then come back here and log in.",
  EINLOGGEN: 'Log in',
  HTTPS_APPS_APPLE_COM_APP_APP: 'https',
  ZUM_APP_STORE: 'To the App Store',
  JOURNEY_ERFOLGREICH_ABGESCHLOS: 'successfully completed',
  DEINE_EMAIL_ADRESSE_BEST_TIGEN: 'Confirm your email address.',
  DU_HAST_VERSUCHT_DEINE_EMAIL_A:
    'You tried to confirm your email address, unfortunately, we can only do this in the app at the moment. Open the email or link again on your mobile phone to complete the confirmation. Thank you for your help!',
  SMART_MIRROR1: 'Smart Mirror',
  VERBINDE_DEIN_PHONE: 'Connect your phone',
  ALLE_KURSE: 'All Courses',
  FAVORITEN: 'favorites',
  VISUELLES_FEEDBACK_SO_FUNKTI1: 'Real-time feedback - how it works',
  ALLE_VORTEILE_VON_YUU_YOGA_IN:
    'All the benefits of PhysioStretch in 60 seconds.',
  NOCH_FRAGEN_WIR_HELFEN_DIR_GE: 'Any questions? We are happy to help.',
  YUU_YOGA_IST_YOGA_ZU_HAUSE_WEI:
    "PhysioStretch is stretching at home. Your stretching is the focus. Want to exercise at home and make sure you're doing the exercises correctly and making satisfactory progress? PhysioStretch enables this by giving you real-time feedback.",
  MIT_DEM_SMARTMIRROR_KANNST_DU:
    "Thanks to SmartMirror, you can compare your pose with the teacher's pose directly during the session and correct it yourself. All you need is a laptop and a smartphone.",
  DURCH_DIE_POSE_COMPARISON_GEBE:
    'Thanks to pose comparison, after each session, we give you feedback on individual positions and a direct comparison with your stretching teacher.',
  UNSERE_YOGALEHRERINNEN_HELFEN:
    'Our teachers will help you try out various training styles tailored to your individual level. From exercises for beginners to programs aimed at learning or perfecting specific poses, everyone will find a training session at PhysioStretch.',
  SOLLTE_DIE_RICHTIGE_SESSION_F:
    "If you don't see a session for yourself in the app, write to us at kontakt@stretchme.pl, and we'll see if we can add more to the program.",
  ALLE_YUU_YOGA_KURSE_WERDEN_VON:
    'All PhysioStretch courses are taught by certified teachers. Would you like to meet them?',
  MONIKA: 'Monika',
  SCH_N_DICH_KENNENZULERNEN_IC:
    "Author's program trainer StretchMe, inspired by gymnastics, dance, and yoga. Stretching is an integral part of her personality, just like a positive attitude towards the world, gratitude, joy, and open-mindedness. She believes that beautiful moments don't just happen, beautiful moments need to be created. She says she is not 'normal' as she lives from stretching, which most people associate with pain. She dispels that pain. She will show you that stretching will become the most enjoyable part of your day, week, or simply your free time.",
  KAROLINA: 'Karolina',
  HEY_YOGI_ICH_BIN_RICARDA_YOG:
    "Pole dance sparked Karolina's passion for dance and stretching. To improve her skills, she began training in stretching under Monika's demanding eye. Thanks to systematic and persistent efforts, her passion turned into a love for stretching, which Karolina now shares during trainings. She appreciates the willingness to work and a good attitude.",
  NATALIA: 'Natalia',
  NAMAST_WIE_SCH_N_DASS_DU_DA:
    'Physiotherapist, graduate of the Academy of Physical Education in Warsaw, certified personal trainer, passionate about human body movement. From an early age, she has been involved in sports, theater, acrobatics, and dance. She is constantly seeking new forms of self-expression. On a daily basis, she teaches people how to move, but her goal is to show how beautiful and sufficient the human body can be.',
  NIKOLA: 'Nikola',
  ICH_BIN_ZERTIFIZIERTE_YOGALEHR:
    'Former dancer and model, certified yoga teacher. It was dance and yoga that made her fall in love with stretching. Thanks to them, she discovered herself and her greatest love, teaching others. Her classes are for everyone and are led straight from the heart.',
  MARTA: 'Marta',
  WZMACNIANIE:
    'A bit thoughtful, a bit crazy, infectious with a smile and energy, a freshly baked fitness mom. LovingEGAL_OB_NEULING_ODER_ERFAHRENE: `Niezależnie od tego, czy jesteś początkujący, czy nie, PhysioStretch oferuje Ci kursy dostosowane do Twoich potrzeb. Nasi nauczyciele będą Ci towarzyszyć w Twojej podróży i pomogą Ci osiągnąć oczekiwane efekty',
  VERBINDEST_DU_LAPTOP_UND_SMART:
    "If you connect your laptop and smartphone at the beginning of the session, PhysioStretch will help you get even more out of the session with real-time feedback. Use your smartphone's camera to see yourself next to the yoga teacher on the big screen.",
  DIE_VERBINDUNG_DER_BEIDEN_GER:
    "Devices are connected by scanning QR codes with your smartphone. You'll receive the option and instructions for visual feedback once you select a video.",
  ALTERNATIV_ZUM_LAPTOP_KANNST_D:
    'Instead of a laptop, you can use a tablet if the screen is large enough.',
  DEINE_DATEN_GEH_REN_DIR_DESWE:
    "Your data belongs to you. That's why neither our team nor anyone else can watch you during your yoga session.",
  ENTSCHEIDEST_DU_DICH_DEN_SMART:
    "If you decide to activate the Smart Mirror, your live image will be transferred from your smartphone to your laptop or tablet, but it won't be saved.",
  M_CHTEST_DU_ZUS_TZLICH_DIE_POS:
    'If you want to use pose comparison, photos of your individual poses will be taken during the session and displayed at the end. You have access to a photo gallery from all your previous sessions.',
  WARUM_YUU_YOGA_WELCHE_VORTEIL:
    'Why PhysioStretch? What are the benefits of pose comparison and the smart mirror?',
  WELCHE_VERSCHIEDENEN_KURSE_UND:
    'What different classes and sessions does PhysioStretch offer?',
  VON_WEM_WERDEN_DIE_SESSIONS_AN: 'Who leads the sessions?',
  MUSS_ICH_BEREITS_YOGA_ERFAHRUN:
    'Do I need to have stretching experience to use PhysioStretch?',
  MIT_WELCHEN_GER_TEN_KANN_ICH_Y: 'Which devices can I use with PhysioStretch?',
  WANN_WERDEN_BILDER_UND_VIDEOS:
    'When will photos and videos be taken of me, and who can see them?',
  ZUR_POSE_COMPARISON: 'for pose comparison',
  COURSECAROUSEL_ITEM: 'course carousel-item-',
  SO_FUNKTIONIERT_S: 'How it works',
  DEINE_POSE_COMPARISONS: 'Your Pose Comparisons',
  KURSE_F_R_DICH: 'Courses for you',
  DEINE_FAVORITEN: 'Your favorites',
  DEINE_TRAINERINNEN: 'Your trainers',
  HOL_DIR_DIE_APP: 'Download the app.',
  MEINE_ACCOUNT_LOSEN: 'Delete Account',
  UM_DIR_YOGA_IN_BESTER_QUALIT_T:
    'To offer workouts in the best quality, you can currently use PhysioStretch on your smartphone only with our app. Simply download it from the App Store or Google play.',
  MEINE_SESSIONS_BISHER: 'My sessions so far',
  MEIN_FORTSCHRITT: 'My progress',
  DEINE_POSE_COMPARISONS_PER_S: 'Your Pose Comparisons - per session',
  BEREITS_GESTARTETE_KURSE: 'Started courses',
  DEINE_JOURNEY: 'Your journey',
  ABGESCHLOSSENE_SESSIONS: 'Completed sessions',
  DIESE_WOCHE: 'This week',
  DIESEN_MONAT: 'This month',
  DIESES_JAHR: 'This year',
  DEINE_EMAIL_ADRESSE: 'Your email address',
  ANGEMELDET_SEIT: 'Registered since',
  DEIN_AKTUELLES_ABONNEMENT: 'Your current subscription',
  GEKAUFT_AM: '(purchased on',
  MAILTO_INFO_YUUXYOGA_DE_SUBJEC: 'send to',
  NUR_NOCH_WENIGE_SCHRITTE_ENTFE: 'Just a few steps away...',
  BITTE_W_HLE_NUN_AUF_DIESEM_GER:
    'Now select the session you want to start on this device.',
  ZUR_AUSWAHL: 'Choose',
  BITTE_MELDE_DICH_NUN_AUF_DIESE:
    'Now log in on this device and choose the session you want to start.',
  ZUM_LOGIN: 'To login',
  AN_VORDEFINIERTEN_ZEITPUNKTEN:
    'At predetermined moments, we take a photo of your workout so you can now compare it with the teacher and share your progress on social media.',
  DEIN_YUU_YOGA_ABO_IST_ABGELAUF:
    'Your PhysioStretch subscription has expired.',
  MOMENTAN_HAST_DU_KEINEN_ZUGRIF:
    'Currently, you no longer have access to PhysioStretch content. To reactivate your subscription, use the PhysioStretch app.',
  HIER_KANNST_DU_EINFACH_UND_UMK:
    'Here you can easily reactivate your subscription. Just open the app, choose a new package, and activate it through an in-app purchase.',
  UNG_LTIGE_E_MAIL_ADRESSE: 'Invalid email address.',
  JETZT_VORMERKEN: 'Reserve now',
  F_R_DIE_ANDROID_VERSION: 'for the Android version.',
  ICH_M_CHTE_INFORMIERT_WERDEN:
    'I would like to be informed as soon as the PhysioStretch app is also available as an Android app.',
  ABSCHICKEN: 'Send',
  AKTUELL_NUR: 'Currently only',
  F_R_IOS_VERF_GBAR: 'available on iOS.',
  MOMENTAN_BIETEN_WIR_YUU_YOGA_N:
    'Currently, we offer PhysioStretch only as an iOS app. An Android smartphone version is planned.',
  LASS_DICH_GERNE_VORMERKEN_UND:
    'Sign up and we will inform you as soon as it is available.',
  WIR_MELDEN_UNS: 'We will be in touch!',
  DANKE_F_R_DEIN_INTERESSE: 'Thank you for your interest.',
  LEIDER_KONNTE_DIE_SEITE_NICHT: "Sorry, we couldn't find the page",
  ANWENDUNGSBEREICH: 'Scope of application',
  DIESE_COOKIE_POLICY_GILT_ERG_N:
    'This cookie policy applies as a supplement to the general data protection statement for our website and describes the type, scope, purpose, and legal basis for the processing of data in the case of cookies. The general data protection statement also applies to all further information.',
  WAS_SIND_COOKIES: 'What are cookies?',
  UM_IHNEN_EINEN_UMFANGREICHEN_F:
    "In order to offer you a wide range of functions, make their use more convenient, and optimize our offers, we use so-called 'cookies'. Cookies are small files that are stored on your end device by your internet browser.",
  KATEGORIEN_VON_COOKIES: 'Categories of cookies',
  WIR_SETZEN_COOKIES_ZU_UNTERSCH:
    'We use cookies for different purposes and with different functions. We differentiate between whether the cookie is technically necessary (technical necessity), how long it is stored and used (storage period), and whether it is created by our website itself or by third parties and by which provider this cookie has been set (cookie provider).',
  TECHNISCHE_ERFORDERLICHKEIT: 'Technical necessity',
  TECHNISCH_ERFORDERLICHE_COOKIE:
    'Technically necessary cookies are absolutely essential for the proper functioning of our website. These cookies are set automatically when you visit our website or use a specific function, unless you have blocked the setting of cookies by making the appropriate settings on your end device or browser.',
  TECHNISCH_NICHT_ERFORDERLICHE:
    'Technically not necessary cookies: we also use cookies that are not strictly necessary, for example, to improve the comfort and performance of the website or to save some of your settings. We also use non-technically necessary cookies to determine information about the frequency of use of certain areas of our website so that we can tailor them to your needs.',
  SPEICHERDAUER: 'Storage period',
  SESSION: 'Session',
  HIER: 'here',
  SESSION_COOKIES_DIE_MEISTEN_C:
    "Session cookies are required for the current service connection or session (session) and are deleted or lose their validity when you leave our website or the current session expires (so-called 'session cookies'). E.g., to store certain data during your session such as your login to our service.",

  PERMANENT_COOKIES_NUR_VEREINZ:
    "Permanent cookies are stored for a certain period of time, for example, to recognize you when you visit our website again later and to be able to call up stored settings. This allows, for example, access to our website faster or more conveniently, or you don't have to re-enter certain settings, such as the chosen language. Permanent cookies are deleted after a specified period of time.",
  COOKIE_ANBIETER: 'Cookie Provider',
  DRITTANBIETER_COOKIES_SOGENAN:
    'Third-party cookies are set and used by other websites or websites, for example, by network analysis tool providers. More detailed information about network analysis tools can be found later in this cookie policy. External providers may also use cookies to display advertisements or integrate content from social networks, such as social plugins.',
  EINSATZ_VON_TOOLS_ZUR_WEBANALY: 'Use of network analysis tools',
  WIR_VERWENDEN_IM_BEREICH_DER_N:
    'In the area of usage analysis, we use the Google Analytics web analysis service, offered by Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Ireland. Google Analytics uses third-party cookies to determine the frequency of use of certain areas of our website and preferences. Information generated by the cookie about your use (including your abbreviated IP address) is transmitted to a Google server in the USA and stored there. Google will use this information on our behalf to evaluate the use of our website, to compile reports for us, and to provide additional information related to the site. Information about data protection and security in Google Analytics can be found at https://support.google.com/analytics/answer/6004245?hl=en.',
  WIR_SETZEN_F_R_UNSERE_MARKETIN:
    'We use the Facebook Pixel service (provider Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland) for our marketing activities and in the area of usage analysis. Advertising on Facebook and its partners is then only displayed to those users who, due to their usage behavior, might be interested in relevant offers (so-called Custom audiences). If you log in to Facebook in connection with the use of our website or app, a connection is established with the Facebook server, and this information is linked to your Facebook account, so you can be assigned to the target group if necessary. However, Facebook may also collect data if you are not simultaneously logged into your Facebook account. We may also use the Facebook Pixel to statistically understand which promotion visits to our website led you to the site. This allows us to evaluate the effectiveness of our ads for statistical purposes and market research and to optimize future advertising activities based on this. More information about data protection in connection with the Facebook Pixel can be found at https://de-de.facebook.com/privacy/explanation.',
  WIR_VERWENDEN_ZUR_ANALYSE_DER:
    'We use the Sentry service (provider Functional Software, 132 Hawthorne Street, San Francisco, CA 94107, USA) for application stability analysis and identification of software errors. Sentry uses third-party cookies to collect information for analysis on our behalf, such as information about the device used or at the time the error occurred, and to share it with us. More information about data protection and security in Sentry can be found at https://sentry.io/privacy/.',
  WIR_VERWENDEN_IM_BEREICH_DER_N1:
    'In the area of usage analysis, we use the FullStory web analysis service (provider FullStory, Inc., 1745 Peachtree St NE, Suite G, Atlanta GA 30309, USA). Website (selected links, end device specifications from which you download data, clicks, mouse movements). FullStory uses the information collected on our behalf and based on the order processing agreement to evaluate your use of our website, create reports on usage-related activities for us, and provide other related services. More information about data protection in relation to FullStory can be found at https://www.fullstory.com/legal/privacy-policy.',
  WIR_NUTZEN_TRACKING_TECHNOLOGI:
    "We use tracking technology from the provider Singular (Singular Labs Inc., 181 South Park Street, Apt 2, San Francisco, CA 94107, USA). To continuously improve our services, we use Singular to collect data about the use of our services and which campaigns/external pages have been directed to our services. In particular, information sent to us by your device, such as the IP address or advertising identifier, which is assigned by the device's operating system, is used for this purpose. More information about data protection in connection with Singular can be found at https://www.singular.net/privacy-policy.",
  RECHTSGRUNDLAGE_F_R_DEN_EINSAT: 'Legal basis for the use of cookies',
  SOWEIT_SIE_DIE_WEBSITE_NUTZEN:
    'If you use the website, we only use non-technically necessary cookies if you have given your consent by expressing your consent. The legal basis is then Art. 6 para. 1 lit. a) GDPR. You can revoke your consent at any time with effect for the future by deactivating the opt-in option via the appropriate settings on the website or in the browser. The legal basis for the use of technically required cookies is Art. 6 para. accordingly.',
  L_SCHUNG_UND_WIDERSPRUCH_GEGEN:
    'Deletion and objection to the use of cookies',
  DIE_AKZEPTANZ_VON_COOKIES_IST:
    'Acceptance of cookies is not mandatory; if you do not want cookies to be saved on your end device, you can deactivate the appropriate option in the system settings of your end device or browser. Saved cookies can be deleted at any time in the system settings tab of your end device or browser, but the lack of acceptance of cookies may lead to functional limitations of our offers.',
  LISTE_DER_COOKIES: 'List of cookies',
  IM_EINZELNEN_K_NNEN_BEIM_AUFRU:
    'In particular, the following cookies may be stored when visiting our website',
  EINSTELLUNGEN_ZU_COOKIES_ANZEI: 'Show cookie settings',
  LINKS_DEFAULT: 'left.default',
  ANBIETER: 'Provider',
  INITIUM_GMBH: 'PhysioStretch Ltd.',
  STRALAUER_ALLEE: 'Lubomirskiego 20',
  BERLIN: `Kraków`,
  DEUTSCHLAND: `Poland`,
  CONTACT: 'Contact',
  TELEFONNUMMER: 'Telephone number',
  EMAIL: 'Email',
  VERTRETEN_DURCH: '',
  MATTIAS_ULBRICH_STEFAN_ZERWEC: '',
  REGISTRIEREINTRAG: 'registry entry',
  REGISTERGERICHT_AMTSGERICHT_C: `Registration Court`,
  HRB_NR_B: `HRB no.`,
  UST_IDENT_NR_DE: `VAT No.`,
  VERBRAUCHERINFORMATION_GEM_SS: `Consumer information in accordance with the Act on Settlement of Consumer Disputes`,
  DIE_INITIUM_GMBH_IST_NICHT_BER: `PhysioStretch sp. Z o.o. is neither willing nor obliged to participate in a dispute resolution procedure before a consumer arbitration board.`,
  WIR_DIE_INITIUM_GMBH_NACHFOL: `We, PhysioStretch sp. Z o.o. (hereinafter "we" or "PhysioStretch"), we are glad that you are interested in our online offer PhysioStretch, which we offer via the application and additionally via the website (hereinafter "online offer"). We take the protection of your personal data very seriously. The processing of your personal data takes place only within the framework of the statutory provisions of data protection law, in particular the General Data Protection Regulation (hereinafter "GDPR"). a statement that we inform you about the processing of your personal data and about your rights as a data subject within the internet offer.`,
  SOFERN_WIR_VON_EXTERNEN_SOCIAL: `If we refer to this data protection declaration from external social media profiles, the following explanations only apply to the extent that the processing takes place within our area of responsibility and unless more detailed and therefore overriding data protection information is provided in the context of such social media profiles.`,
  VERANTWORTLICHER_UND_DATES: '1. Responsible and data protection officer',
  VERANTWORTLICH_F_R_DIE_DATENVE: `Responsible for data processing within the meaning of data protection law is`,
  SOLLTEN_SIE_FRAGEN_ODER_ANREGU:
    'If you have any questions or suggestions regarding data protection, please contact us. Our data protection officer can be contacted as follows',
  BEAUFTRAGTER_F_R_DATENSCHUTZ: 'Privacy Officer',
  DATENSCHUTZBEAUFTRAGTER_INITIU: `kontakt@stretchme.pl`,
  GEGENSTAND_DES_DATENSCHUTZE: '2. Subject of data protection',
  GEGENSTAND_DES_DATENSCHUTZES_I:
    'The subject of data protection is the protection of personal data. This is any information relating to an identified or identifiable natural person (the so-called data subject). This includes information such as name and surname, postal address, e-mail address or telephone number, but also other information that arises as part of the use of the Internet offer, in particular information about the start, end and scope of use and transmission of your IP address. ',
  ZWECKE_UND_RECHTSGRUNDLAGEN: `3. Purposes and legal basis for data processing`,
  NACHFOLGEND_ERHALTEN_SIE_EINEN: `Following you will get an overview of the purposes and legal bases for data processing in the context of the online offer. In each case, we process personal data in accordance with legal requirements, even if in individual cases there is a different legal basis than stated below.`,
  DIE_BEREITSTELLUNG_PERSONENBEZ: `Provision of personal data by you may be required by law or contract, or be necessary to conclude a contract. We will separately inform you whether you should be obliged to provide personal data and what the consequences of not providing them would be (e.g. loss of claims or our notification is unable to perform the requested service without providing certain information). The use of the online offer is generally possible without registration. The use of certain services and features may require prior registration. Even if you use our online offer without registering, personal data may still be processed.`,
  ERF_LLUNG_VERTRAGLICHER_UN: `3.1 Fulfillment of contractual and pre-contractual obligations`,
  WIR_VERARBEITEN_IHRE_PERSONENB: `We process your personal data if it is necessary for the performance of a contract to which you are a party or for the performance of pre-contractual measures that comply with your request. Data processing is carried out on the basis of art. 6 sec. 1 lit. b) DSGVO The purpose of the processing is to enable the use of our specific products and services as part of the online offer. In this respect, please also read the descriptions of these products and services as part of the online offer. In this particular case, these are in particular the following functions`,
  REGISTRIERUNG_F_R_EIN_KONTO: '- account registration',
  MIT_DER_REGISTRIERUNG_F_R_EIN:
    'By registering a PhysioStretch account with your email address, you choose a username and password that you choose yourself and you can add additional information to your account via your account settings if you wish. Through your account, you can then use the Manage and content features (view completed trainings, etc.),',
  WIR_VERWENDEN_ZUR_REGISTRIERUN: `We use the so-called "single sign-on procedure" for registration and registration, involving third-party services. With the single sign-on procedure, you can log in as a logged-in Facebook user (responsible Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland), Google (responsible Google Ireland Ltd., Gordon House, BarrowStreet, Dublin 4, Ireland) or Apple (responsible Apple Inc., Infinite Loop, Cupertino, CA 95014, USA) for a PhysioStretch account, register and log in. This means you don't have to remember your new PhysioStretch password. Alternatively, you can register and log in with your email address. Your e-mail address and password of your choice.`,
  SOLLTEN_SIE_SICH_F_R_DAS_SINGL: `If you choose to use the single sign-on procedure, you will be redirected to the login/registration mask of your account with the respective provider through which the single sign-on procedure is processed. Here you register with your local username and password. We receive a message from the provider that you have successfully logged in and registration or login has been completed. During this process, we do not come to the provider with your password or username through which the single sign-on procedure will be processed in contact. If you register or log in successfully, you must confirm to the provider that we can access your basic profile details (see below for the relevant provider). This means you don't have to keep your basic profile data in PhysioStretch and update it (if your address changes). If you choose not to link your PhysioStretch account with an Anbi account where single sign-on is processed, the appropriate settings are available from that provider.`,
  WENN_SIE_EIN_YUU_YOGA_KONTO_B:
    "If you create a PhysioStretch account via Facebook or log in to a PhysioStretch account via Facebook, Facebook will send us some information from your Facebook account: name,SOWEIT_DAUERHAFTE_VIDEOAUFZEIC: 'O ile trwałe nagrania wideo są tworzone jako część migawek, są one zazwyczaj przechowywane do momentu usunięcia konta lub usunięcia pojedynczych migawek w ramach zarządzania kontem. Upewnij się, że żadne inne osoby nie są nagrywane.",
  BITTE_BEACHTEN_SIE_DIE_WEITERE: `Please note further information regarding the description of features in the terms of use. If a paid subscription is required to use the features, you will receive further information on the data processing involved and the information required as part of the ordering process. `,
  DIE_BEI_DER_NUTZUNG_DER_FUNKTI: `Data collected when using the function, in particular video recordings and data for verification and settlement of subscriptions, are processed pursuant to art. 6 sec. 1 lit. b) GDPR in order to provide you with the functions you have selected as part of the offer.`,
  ERF_LLUNG_RECHTLICHER_VERF: `3.2 Compliance with legal obligations`,
  WIR_VERARBEITEN_IHRE_PERSONENB1: `We process your personal data in order to comply with legal obligations to which we are subject. Data processing is carried out on the basis of art. 6 sec. 1 lit. c) GDPR. Responsibilities may include: or criminal law. The purposes of processing result from a legal obligation; the processing usually serves the fulfillment of control and information obligations.`,
  WAHRUNG_BERECHTIGTER_INTER: `3.3 Protecting legitimate interests`,
  WIR_VERARBEITEN_IHRE_PERSONENB2: `We also process your personal data to protect our legitimate interests or those of third parties, unless your interests that require the protection of your personal data are overriding. Data processing is carried out on the basis of art. 6 sec. 1 lit. f GDPR. carried out to protect legitimate interests for the following purposes or to protect the following interests.`,
  WEITERENTWICKLUNG_VON_PRODUK: `- Further development of products, services and support offers and other means of controlling transactions and business processes`,
  VERBESSERUNG_DER_PRODUCTQUAL:
    '- Improving the quality of products, eliminating errors and faults, e.g. using the analysis of usage data and customer opinions',
  BEARBEITUNG_VON_AUSSERVERTRA:
    '- Processing of non-contractual inquiries from interested parties and customers',
  SICHERSTELLUNG_RECHTSKONFORM: `- Ensuring lawful operation, prevention and protection against violations of the law (in particular crimes), investigation and defense against legal claims`,
  GEW_HRLEISTUNG_VON_VERF_GBAR: `- Ensuring the availability, operation and security of technical systems and technical data management`,
  WENN_SIE_DAS_ONLINE_ANGEBOT_AU: `When you call up an online offer, data about your end device and the use of the online offer will be processed and stored in a so-called log file. This applies in particular to technical data such as date and time of access, duration of the visit, type of end device, operating system used, functions used, amount of data transferred, IP address and referrer URL. We process this data to ensure technical operation and identifying and removing faults. in the interest of continued technical functionality. We do not use this data to draw conclusions about you.`,
  EINWILLIGUNG: `3.4 Consent`,
  WIR_VERARBEITEN_IHRE_PERSONENB3: `We process your personal data based on your consent. Data processing is carried out on the basis of art. 6 sec. content of the declaration of consent. You can withdraw your consent at any time without affecting the lawfulness of the processing that was made on the basis of consent until revoked.`,
  SIE_K_NNEN_SICH_BEI_DER_REGIST: `You can sign up for our newsletter with information about PhysioStretch during registration or when managing your account. We send newsletters only after registration, i.e. with your consent pursuant to art. 6 sec. 1 lit. a) The DSGVO newsletter, the content of which is specifically described, determine the scope of consent. In addition, our newsletter contains information about PhysioStretch-related features and activities. You can revoke your consent to receive our newsletter at any time by unsubscribing from the newsletter. Unsubscribe link You can find out how to exercise this right at the end of each newsletter.`,
  BEI_DER_ERHEBUNG_UND_VERWALTUN: `We use MailChimp (provider Rocket Science Group, LLC, 675 Ponce De LeonAve NE 5000, Atlanta, GA 30308, USA) to collect and manage your email address. To make our newsletter as interesting as possible for you, MailChimp, on our behalf, user behavior without drawing conclusions about your person or identity. In this way, we can find out, for example, how many of our readers opened our newsletter and which links were visited particularly often . This applies to standard market technologies, e.g. cookies or tracking pixels embedded in our newsletter. The data is processed on the basis of our legitimate interest within the meaning of art. 6 sec. More information on data protection in MailChimp can be found in the section `,
  HTTPS_MAILCHIMP_COM_LEGAL_PR: `https`,
  ZWECK_NDERUNG: '3.5 Change target',
  SOWEIT_WIR_IHRE_PERSONENBEZOGE: `O ile przetwarzamy Twoje dane osobowe poza odpowiednią zgodą lub niepodważalną podstawą prawną w celu innym niż ten, dla którego dane zostały zebrane, bierzemy pod uwagę zgodność pierwotnego i obecnie realizowanego celu, rodzaju danych osobowych zgodnie z art. 6 ust. 4 danych DSGVO możliwe konsekwencje dalszego przetwarzania dla Ciebie oraz gwarancje ochrony danych osobowych.`,
  PROFILING: '3.6 Profilowanie',
  WIR_F_HREN_KEINE_AUTOMATISIERT: `Nie przeprowadzamy zautomatyzowanego podejmowania decyzji ani profilowania zgodnie z art. 22 RODO. Profil jest tworzony wyłącznie w celu ochrony naszych uzasadnionych interesów, jak opisano powyżej.`,
  ZUGRIFFSBERECHTIGUNGEN_IM_E: '4. Uprawnienia dostępu w urządzeniu końcowym',
  SOWEIT_FUNKTIONEN_UNSERES_ONLI: `O ile funkcje naszej oferty online wymagają nadawania uprawnień dostępu do urządzenia (np. dostęp do danych o lokalizacji lub zdjęć), nadanie uprawnień jest dobrowolne. Jeśli jednak chcesz korzystać z odpowiednich funkcji, nadanie odpowiednich uprawnienia są konieczne, w przeciwnym razie nie możesz korzystać z tych funkcji. Uprawnienia pozostają aktywne, dopóki nie zresetujesz ich w swoim urządzeniu poprzez dezaktywację odpowiedniego ustawienia.`,
  F_R_DIE_NUTZUNG_DER_UNTER_ZIFF: `W celu korzystania z funkcji wyjaśnionych w punkcie 3.1 (Korzystanie z funkcji) wymagane jest użycie kamery urządzenia końcowego. Jeśli chcesz korzystać z wymienionych funkcji, wymagane jest przyznanie odpowiedniej autoryzacji, w przeciwnym razie nie może korzystać z tych funkcji. Podstawowe uprawnienie do korzystania z aparatu podczas korzystania z funkcji wyjaśnionych powyżej pozostaje aktywne, dopóki nie zresetujesz go w swoim urządzeniu, dezaktywując odpowiednie ustawienie.`,
  COOKIES_UND_VERGLEICHBARE_T: `5. Cookies i podobne technologie`,
  WIR_VERWENDEN_IM_RAHMEN_DES_ON: `W ramach oferty internetowej stosujemy pliki cookies i podobne technologie, które służą do komunikacji z Twoim urządzeniem i wymiany przechowywanych informacji (zwane dalej "ciasteczkami"). Te pliki cookies służą przede wszystkim do korzystania z funkcji oferty internetowej. Ogólne przykłady, w których korzystanie z plików cookie jest w tym sensie technicznie konieczne, to przechowywanie wybranego języka, danych logowania lub listy zakupów lub listy obserwacyjnej. Odpowiednio, niezbędne technicznie pliki cookie mogą być przez nas używane w celu umożliwienia przetwarzania opisanego w punkcie 3.1 i w celu umożliwienia prawidłowego i bezpiecznego działania oferty internetowej. Przetwarzanie danych odbywa się wówczas na podstawie art. 6 ust. 1 lit. b) i f) RODO, ponieważ jest to niezbędne do realizacji wybranych przez Państwa funkcji lub ochrony naszego prawnie uzasadnionego interesu w zakresie funkcjonalności oferty internetowej.`,
  SOWEIT_WIR_DAR_BER_HINAUS_COOK: `W zakresie, w jakim używamy plików cookie do analizy korzystania z oferty online i dostosowywania jej do Twoich zainteresowań, a w razie potrzeby również do dostarczania Ci treści i reklam opartych na zainteresowaniach, odbywa się to wyłącznie na podstawie Twojej dobrowolnej zgody zgodnie z artykułem 6 ust. Więcej informacji na temat plików cookie i ich funkcji, a także możliwości ustawienia i odwołania można znaleźć w naszym`,
  WENN_SIE_DEN_EINSATZ_VON_COOKI: `Jeżeli nie chcesz, aby w ogóle wykorzystywane były pliki cookies, możesz również uniemożliwić ich zapisywanie dokonując odpowiednich ustawień w swoim urządzeniu końcowym. Zapisane pliki cookies możesz w każdej chwili usunąć w ustawieniach systemowych swojego urządzenia końcowego. że zablokowanie niektórych rodzajów plików cookie spowoduje utrudnione korzystanie z naszej oferty online.`,
  EINGEBUNDENE_DRITTDIENSTE: '6. Zintegrowane Usługi Osób Trzecich',
  SOWEIT_WIR_IM_RAHMEN_UNSERES_O: `O ile integrujemy usługi innych dostawców w ramach naszej oferty online w celu zaoferowania Państwu określonych treści lub funkcji (np. odtwarzanie filmów lub planowanie trasy) i przetwarzamy w tym procesie dane osobowe, odbywa się to na podstawie art. 6 ust. 1 lit. b) i f) RODO, ponieważ przetwarzanie danych jest wówczas niezbędne do realizacji wybranych przez Państwa funkcji lub do ochrony naszego prawnie uzasadnionego interesu w zakresie optymalnego zakresu funkcji oferty online. w kontekście tych usług świadczonych przez osoby trzecie obowiązują oświadczenia zawarte w punkcie 5. Proszę W odniesieniu do usług osób trzecich prosimy również o zapoznanie się z oświadczeniem o ochronie danych danego dostawcy.`,
  DIENSTE_ANDERER_ANBIETER_DIE: `Usługi innych dostawców, które integrujemy lub do których się odnosimy, są świadczone przez odpowiednie strony trzecie. Nie mamy wpływu na treść i funkcję usług stron trzecich i zasadniczo nie ponosimy odpowiedzialności za przetwarzanie Twoich danych osobowych przez ich usługodawcy, o ile usługi osób trzecich nie są projektowane wyłącznie w naszym imieniu, a następnie integrowane przez nas na naszą własną odpowiedzialność.Jeśli integracja usługi osoby trzeciej prowadzi do ustanowienia przez nas wspólnych procesów z jej dostawcą, określamy w ten sposób w umowie o współodpowiedzialności zgodnie z art. 26 RODO, w jaki sposób określone są odpowiednie zadania i obowiązki związane z przetwarzaniem danych osobowych oraz kto wypełnia jakie obowiązki w zakresie ochrony danych. Jeśli pliki cookie mają być również ustawione na podstawie Twojej zgody, będziesz otrzymać dalsze informacje na temat odpowiedzialności za r ustawienie tych plików cookie lub powiązanych z nimi usług stron trzecich w odpowiednim obszarze`,
  SOWEIT_NICHT_ANDERS_ANGEGEBEN: `O ile nie zaznaczono inaczej, profile w mediach społecznościowych są zawarte w naszej ofercie online wyłącznie jako łącze do odpowiednich usług stron trzecich. Po kliknięciu zintegrowanego łącza tekstowego/obrazowego zostaniesz przekierowany do oferty odpowiedniego dostawcy mediów społecznościowych Po przekazaniu dane osobowe mogą być zbierane bezpośrednio przez zewnętrznego dostawcę.Jeśli jesteś jednocześnie zalogowany na swoim koncie użytkownika u odpowiedniego dostawcy mediów społecznościowych, może on przyporządkować informacje zebrane podczas konkretnej wizyty do Twoje osobiste konto użytkownika. Wejdź w interakcję za pomocą przycisku "Udostępnij" u odpowiedniego dostawcy mediów społecznościowych. Dostawca mediów, informacje te mogą być przechowywane na osobistym koncie użytkownika i w razie potrzeby publikowane. Jeśli chcesz uniemożliwić bezpośrednie przypisanie zebranych informacji do swoje konto użytkownika, przed kliknięciem w zintegrowany link tekstowy/obrazowy należy się wylogować.`,
  F_R_DIE_ANALYSE_UND_VERWALTUNG: `PhysioStretch korzysta z usługi RevenueCat (dostawca Revenue Cat, Inc., 633 Taraval St. Suite101, San Francisco, CA, 94116, USA) do analizy i zarządzania zakupami w aplikacji. Transmisja jest zgodna z RODO, analogicznie do przepisy Art. 46 RODO. RevenueCat przechowuje lub przetwarza dane i przygotowuje je w interfejsie internetowym. Dane są przechowywane przez RevenueCat w USA lub Europie. RevenueCat podlega przepisom RODO UE (EU2016/679) per DPA`,
  HTTPS_WWW_REVENUECAT_COM_DPA: `https`,
  DU_STIMMST_DER_DATENERFASSUNG:
    'Wyrażasz zgodę na zbieranie danych przez PhysioStretch i przesyłanie ich do RevenueCat. Możesz usunąć własne dane z bazy danych w PhysioStretch lub w RevenueCat, możesz to zrobić wysyłając wiadomość e-mail na adres',
  VERFASSEN_UND_DIE_L_SC: `Napisz i poproś o usunięcie z dopiskiem "Usunięcie moich danych w RevenueCat". Jeśli tak się nie stanie, dane zostaną usunięte najpóźniej po upływie ustawowych okresów przechowywania. Odwołujemy się do przepisów o ochronie danych i Ogólnych Warunków oraz Warunki PrzychoduCat`,
  DATENSCHUTZBESTIMMUNGEN: `Polityka prywatności`,
  HTTPS_WWW_REVENUECAT_COM_PRI: `https`,
  ALLGEMEINE_GESCH_FTSBEDINGUNGE: `Warunki usługi`,
  HTTPS_WWW_REVENUECAT_COM_TER: `https`,
  UNTER_FOLGENDEM_LINK_K_NNEN_DI:
    'Dane przechowywane i przetwarzane przez RevenueCat można przeglądać pod następującym linkiem',
  EMPF_NGER_PERSONENBEZOGENER: '7. Odbiorcy danych osobowych',
  INNERHALB_UNSERES_UNTERNEHMENS: `W naszej firmie dostęp do Twoich danych osobowych mają tylko te osoby, które potrzebują ich do celów wymienionych każdorazowo. Twoje dane osobowe będą przekazywane odbiorcom zewnętrznym tylko wtedy, gdy istnieje na to prawnie pozwolenie lub mamy Twoją zgodę. Poniżej znajdziesz zestawienie odpowiednich odbiorców`,
  AUFTRAGSVERARBEITER_KONZERN: `- Przetwarzający, na przykład w obszarach infrastruktury technicznej i utrzymania, które są starannie dobierane i sprawdzane. Przetwarzający mogą wykorzystywać dane wyłącznie zgodnie z naszymi instrukcjami.`,
  FFENTLICHE_STELLEN_BEH_RDE:
    '- Organy publiczne, organy podatkowe, prokuratury lub sądy, którym (musimy) przekazać dane osobowe, np. w celu wypełnienia obowiązków prawnych lub ochrony prawnie uzasadnionych interesów.',
  PRIVATE_STELLEN_KOOPERATION:
    '-Private entities: Persons to whom data is transferred on the basis of consent, in order to perform a contract with you or to protect legitimate interests.',
  DATENVERARBEITUNG_IN_DRITTL: '8. Data processing in third countries',
  FINDET_EINE_DATEN_BERMITTLUNG: `If the data is transferred to authorities whose registered office or place of data processing is not located in a Member State of the European Union, another country that is a party to the Agreement on the European Economic Area or a country for which an adequate level of data protection has been ensured, as defined by the decision of the European Commission, before its transfer, we ensure that that the transfer of data is covered by either a statutory permit, that there are guarantees of an adequate level of data protection in connection with the transfer of data (e.g. through guarantee agreements, officially recognized regulations or applicable internal data protection regulations at the recipient) or that you have consented to the data - you have consented for transmission.`,
  SOWEIT_DIE_DATEN_BERMITTLUNG_A: `Insofar as the data is transferred pursuant to Art. 46, 47 or 49 sec. 1 point 2 of the GDPR, you can receive a copy of the guarantee of an adequate level of data protection in connection with the transfer of data, indicating the availability of a copy of the guarantee. Please refer to the information in point 1.`,
  SPEICHERDAUER_L_SCHUNG: '9. Storage period, deletion',
  WIR_SPEICHERN_IHRE_PERSONENBEZ: `We store your personal data, where legally permitted, only for as long as necessary to achieve the intended purposes or as long as you have not revoked your consent. If you object to the processing, you will delete your personal data, unless further processing is carried out in accordance with statutory provisions. We also delete your personal data if we are obliged to do so for other legal reasons. When applying these general rules, we usually delete your personal data immediately`,
  NACH_WEGFALL_DER_RECHTLICHEN: `- after termination of the legal basis and provided that no other legal basis applies (e.g. retention periods for commercial and tax purposes).`,
  WENN_IHRE_PERSONENBEZOGENEN: `- if your personal data is no longer needed for the purposes we pursue and no other legal basis applies (e.g. retention periods for commercial and tax purposes). If the latter applies, we will delete the data when the second legal ground no longer applies. `,
  BETROFENENRECHTE: `10. Rights of data subjects`,
  AUSKUNFTSRECHT_SIE_HABEN_DAS: `Right to access stored personal data.`,
  BERICHTIGUNGS_UND_L_SCHUNGSRE: `Right to rectify and delete inaccurate data and - if the legal requirements are met - to request the deletion of your data.`,
  EINSCHR_NKUNG_DER_VERARBEITUNG: `The legal requirements for limiting processing are met - request limiting the processing of your data.`,
  DATEN_BERTRAGBARKEIT_SOLLTEN: `If you have provided data portability or consent, you may, provided the legal requirements are met, request that you receive the data you have provided in a structured, common and machine-readable format or that we send it to another responsible person`,
  WIDERSPRUCH_SIE_HABEN_DAS_REC: `If you exercise your right to object, we will stop processing, unless we can demonstrate compelling reasons worthy of protection for further processing, which outweigh your rights and interests.`,
  WIDERSPRUCH_GEGEN_DIREKTWERBUN: `Objection to the processing of direct correspondence for the purpose of handling direct mail, you have the right to object to our processing of data for this purpose at any time. If you exercise your right to object, we will stop processing for this purpose.`,
  WIDERRUF_DER_EINWILLIGUNG_SOF: `Withdrawal of consent to the processing of your personal data. You can revoke it at any time with effect for the future. This does not affect the lawfulness of the processing of your data.`,
  BESCHWERDERECHT_BEI_DER_AUFSIC: `Right of appeal to the supervisory authority. Submit a complaint to the competent supervisory authority if you believe that the processing of your data violates applicable law. You can contact the supervisory authority competent for your place of residence or country or the supervisory authority responsible for us.`,
  IHR_KONTAKT_ZU_UNS_UND_DIE_AUS: `Your contact with us and exercise of your rights. If you have any questions regarding the processing of your personal data and your rights as a data subject, please contact us free of charge. Please contact us`,
  BETROFFENENRECHTE_INITIUM_GMBH: `kontakt@stretchme.pl`,
  ODER_POSTALISCH_AN_DIE_OBEN_UN: `or by post to the address given in point 1 above. Make sure we can uniquely identify you.`,
  STAND: '11th tribune',
  ALLGEMEIN: `General`,
  ES_GILT_DIE_AKTUELLSTE_VERSION:
    'The latest version of this data protection declaration applies. As of October 12, 2021.',
  AUF_DER_WEBSEITE_WWW_YUUXYOGA: `On the website www.stretchme.app ("`,
  WEBSEITE: 'Website',
  SOWIE_MIT_MIT_MIT_MIT_MIT_M: `") and using the application ("`,
  APP: 'application',
  BIETET_DIE_INITIUM_GMBH_ST: `") offers PhysioStretch sp. z o.o., Stralauer Allee 12, 10245 Berlin, Germany ("`,
  WIR: 'We',
  EINE_PLATTFORM_DIE_ES_DEM: `") a platform that allows the user ("`,
  DU: 'You',
  IM_RAHMEN_DES_NACHFOLGEND_B: `"), within the services described below, allows you to view workouts with exercise instructions from different trainers and repeat them in your own workouts ("`,
  PhysioStretch: 'PhysioStretch',
  EINE_NUTZUNG_VON_YUUYOGA_S: `"). Use of PhysioStretch requires you to agree to these terms of use, which are the subject of the user agreement between us and you. Therefore, please read these terms of use carefully and familiarize yourself with the provisions contained therein.`,
  LEISTUNGSGEGENSTAND_VON_YUU:
    '1. The object of execution of PhysioStretch app',
  YUUYOGA_BIETET_DIR_DIE_M_GLICH: `PhysioStretch offers the ability to access a library of video content such as yoga workouts, exercises and exercises ("`,
  WORKOUTS: 'workouts',
  VON_AUSGEW_HLTEN_LEHRERN: `") by selected teachers ("`,
  TRAINER: 'Trainer',
  ZUZUGREIFEN_UND_W_HREND_UND: `") and receiving personalized feedback on how it was performed during and after the session.`,
  DAS_PERSONALISIERTE_FEEDBACK: `Personalized feedback is done in such a way that we show you a virtual mirror by configuring and connecting cameras on your end device (e.g. smartphone, tablet, laptop, etc.) ("`,
  UND_SO_W_HREND_UND_NACH_EIN: `") and thus get a direct comparison of your exercise performance with the trainer's performance during and after the session.`,
  DIE_NUUTZUNG_VON_YUUYOGA_ERFORD:
    'The use of PhysioStretch requires the conclusion or conclusion of further agreements with third parties (e.g. with a mobile operator); you must bear the resulting costs - in particular for the amount of data used.',
  ZUR_VOLLUMF_NGLICHEN_NUTZUNG_V:
    'Aids such as a terminal device or training equipment may be required to fully use the PhysioStretch app. They are not part of the PhysioStretch offer and can be purchased separately and at your own expense.',
  VORAUSSETZUNGEN_F_R_DIE_NUT: '2. Requirements for using PhysioStretch app',
  DU_KANNST_YUUYOGA_NUTZEN_WENN:
    'You can use the PhysioStretch app if you are an individual over the age of 18. Also remember that the use of the PhysioStretch app requires an Apple device with at least IOS 8 operating system.',
  SOWEIT_DU_DICH_F_R_EIN_KOSTENP:
    'If you choose to purchase a paid subscription, you will have to pay to continue using PhysioStretch. A user account is created by providing an e-mail address and',
  DER_FESTLEGUNG_EINES_PASSWORTS: 'password setting or',
  DER_VERWENDUNG_EINES_NUTZERPRO: `Using a user profile on a (social) network operated by a third party such as Facebook, Google or LinkedIn`,
  SOCIAL_LOGIN: 'social login',
  SOFERN_DU_DICH_MITTELS_SOC: `"); if you want to sign up for PhysioStretch using social login, you will be redirected to the third-party provider's login/registration page and you can log in there with your username and password stored with that third-party provider (" `,
  ZUGANGSDATEN: 'Access data',
  DU_HAST_DAF_R_SORGE_ZU_TRAGEN:
    'You must ensure that the access data you use is protected against access by third parties and is carefully stored. If you have reason to believe that the confidentiality of your password has been compromised or unauthorized access to your user account is possible in any way',
  THIRTY_DAYS_CHALLANGE_UNFINISHED:
    "Unfortunately, you didn't complete the challenge. Do you want to try again?",
  ACHIEVED_DAILY_GOAL: 'Daily Goal Reached!',
  SESSION_FINISHED: 'You have finished your session for today',
  BACK: 'Back',
  RESTART_CHALLENGE: 'Restart',
  YOUR_PROGRESS: 'Your progress',
  COURSE_WAITING: 'course is waiting for you',
  POSE_INFO: 'More about pose',
  SESSIONS: 'Sessions',
  AVAILABLE_SOON: 'Available for you soon',
  LEVEL: 'Level',
  POSE_COMPARISON_EXAMPLE: 'Position Comparison',
  YOUR_SMARTPHONE_BACAME: 'Your smartphone will become',
  SMART_MIRROR2: 'a digital mirror.',
  HOW_TO_POSITION_SMART_MIRROR:
    'Place the SmartMirror on the mat so that you see yourself in the same orientation as the trainer during the session.',
  PROPER_POSITION_HELP_YOU_IMPROVE:
    'This will give you better control of your position.',
  YOUR_FIRST_SESSION: 'Your first session',
  WITH_FEEDBACK: 'with feedback',
  START_SESSION: 'Start',
  POSE_COMPARSION: 'Position Comparison',
  IMPROVE_YOUR_PROGRRESS: 'See your progress!',
  WE_ARE_TAKING_PHOTOS:
    'During selected positions in the session, we take a photo of you, so that after the session you can compare your performance with the trainer.',
  COUTDOWN_BEFORE_EACH_POSE:
    "There's a countdown before each pose. This will let you know when the photo is taken.",
  SETTINGS: 'Settings',
  CHOOSE_SIEZE: 'Choose size',
  LARGE: 'big',
  MEDIUM: 'medium',
  LANGUAGE: 'Language',
  SMALL: 'small',
  MIRROR_REPRESENTAION: 'Mirror view',
  MIRROR_VIEW: 'Mirror image of a smart mirror',
  AUDIO_COUNTDOWN: 'Countdown sound',
  AUDIO_COUNTDOWN_ON: 'Active',
  SMART_MIRROR_HIDE: 'Hide/show SmartMirror',
  SMART_MIRROR_ON: 'Active',
  CONGRATULATIONS: 'Congratulations',
  THANKS_FOR_SETTINGS: 'Thank you for a great session!',
  NEXT_SESSION_WAITING: 'Continuing? Another session awaits...',
  SESSION_DATA_MISSING:
    'Error: There is no item comparison for this session yet. No photos will be taken.',
  WHY_DO_YOU_WANT_DELETE: 'Why do you want to delete the pose?',
  QUESTION_1_HEADLINE: "I can't see myself properly",
  QUESTION_1_ANSWEAR:
    'Sometimes it may happen that the timing of the photo is not right. However, you have the option of determining the optimal time yourself by serially recording the image. Use the arrows in the lower left corner of the image.',
  QUESTION_2_HEADLINE: 'Image quality is insufficient.',
  QUESTION_2_ANSWEAR:
    'Of course, the quality of the photos depends on various factors. Depending on your smartphone and environment, this can vary greatly. If you are dissatisfied with the picture quality, please try to change the position of your setting in the next session. For example, make sure the lighting is good and take the time to position your smartphone correctly.',
  QUESTION_3_HEADLINE: 'I feel uncomfortable with this photo.',
  QUESTION_3_ANSWEAR:
    "Don't worry. The position comparison doesn't have to look perfect, but it's meant to help you perform better and understand where you need to improve. If you've been collecting your poses for a long time, they'll also help you visualize your progress over time.'",
  QUESTION_4_HEADLINE: "I don't want my photos saved.'",
  QUESTION_4_ANSWEAR:
    "'Of course you can delete your photo, but you don't have to worry about your privacy. Your photos are 100% private and secure. No one but you can see them. Safety is our top priority.'",
  CANCEL: 'Cancel',
  DELETE_POSE: 'Delete positions',
  SAVE: 'Save',
  EDIT: 'Edit',
  START_OUR_COURSESS: 'Start now with one of our courses',
  REMOVE_FROM_FAVORITES: 'Add to Favorites',
  ADD_TO_FAVORITES: 'Remove from favorites',
  READ_MORE: 'Read more +',
  READ_LESS: 'less -',
  PROPS: 'Props',
  THATS_WAITS_FOR_YOU: 'This is what awaits you',
  ERROR: 'Error',
  INSTRUCTOR_NOT_FOUND: 'Instructor not found',
  ABOUT: '',
  COURSES_WITH: 'Courses from',
  DAY_AFTER_DAY: 'day after day',
  APP_PAGE_TITLE: 'STRETCHME',
  CONNECT_PAGE_TITLE: 'STRETCHME - Connect',
  CONNECT_YPOUR_PHONE: 'Connect phone',
  SCAN_QR_CODE: 'Scan the QR code',
  ON_YOUR_IOS_DEVICE: 'on IOS phone',
  CAMERA_APP: '',
  SORRY_2: 'Sorry',
  SOMETHING_WENT_WRONG: 'Something went wrong',
  CONTACT_US: 'Please contact me, we will help',
  INFO_MAIL: 'kontakt@stretchme.pl',
  COOKIE_NAME: 'Cookie name',
  COOKIE_TYPE: 'Type',
  COOKIE_PERIOD: 'Storage time',
  COOKIE_COMPANY: 'Company',
  COOKIE_AIM: 'Target',
  COOKIE_LEGAL_BASIS: 'Legal basis',
  COOKIE_TABLE: [
    [
      'cookie_acceptance',
      'Permanent',
      '2 Wochen',
      'Porsche Digital',
      'YuuYoga Webseite – Information, ob Cookies akzeptiert werden',
      'Art. 6 Abs. 1 f) DSGVO',
    ],
    [
      '_ga',
      'Permanent',
      '2 Jahre',
      'Google Analytics',
      'Google Analytics Cookie zur Nutzeridentifikation; siehe auch https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage',
      'Art. 6 Abs. 1 a) DSGVO',
    ],
    [
      '_gid',
      'Permanent',
      '1 Tag',
      'Google Analytics',
      'Google Analytics Cookie zur Nutzeridentifikation.',
      'Art. 6 Abs. 1 a) DSGVO',
    ],
    [
      '_gat_gtag',
      'Permanent',
      '1 Min',
      'Google Analytics',
      'Google Analytics Cookie zur Drosselung der Requestanzahl.',
      'Art. 6 Abs. 1 a) DSGVO',
    ],
    [
      '_fbp',
      'Permanent',
      '90 Tage',
      'Facebook',
      'Über den Facebook Pixel können wir statistisch nachvollziehen, welche Werbung zu einem Besuch unserer Website bzw. App geführt hat. Mehr Details weiter oben unter Marketing.',
      'Art. 6 Abs. 1 a) DSGVO',
    ],
    [
      'fs_uid',
      'Permanent',
      '1 Jahr',
      'FullStory',
      'FullStory Cookie zur Nutzeridentifikation.',
      'Art. 6 Abs. 1 a) DSGVO',
    ],
  ],
  LOGIN_PAGE_META_TITLE: 'STRETCHME - Register',
  RESSET_YOUR_PASSWORD: 'Reset Password',
  CONFIRM_RESET_PASSWORD: 'Save new password',
  CONFIRM_ACCOUT_FIRST: 'First confirm email',
  USER_NOT_FOUND: 'User not found',
  REGISTER_BUTTON_LINK: 'Registration',
  INVALID_PASSWORD: 'Invalid password',
  INVALID_LOGIN_OR_PASSWORD: 'Invalid login or password',
  EMAIL_SENT: 'We have sent you an activation email',
  ENTER_CODE_FROM_EMAIL:
    'Enter the code from your email to confirm your email address.',
  ENTER_THE_CODE: 'Enter code',
  PASSWORD_NOT_MEET_REQUIREMENTS:
    'The password does not meet the requirements. Only numbers, letters and these characters can be used:',
  EMAIL_INVALID: 'invalid email',
  USER_ALREADY_EXISITS: 'User already exists.',
  LOGIN_BUTTON_LINK: 'Login',
  LOGE_DICH: 'Login',
  LOGIN_GOOGLE: 'Login with Google',
  LOGIN_FACEBOOK: 'Login with Facebook',
  LOGIN_APPLE: 'Login with Apple',
  PASSWORD: 'Hasło',
  ACTIVATION_CODE_INVALID: 'The code is invalid.',
  FEEL_FREE_TO_CONTACT_US: 'Please contact us, we will help:',
  CODE_EXPIRED: 'Your code has expired.',
  EMAIL_NOT_FOUND: 'This email address does not exist.',
  ACTIVATION_SUCCESSFULL: 'Activation successful',
  ACCOUNT_ACTIVATED:
    'Your email address has been successfully activated, you can login now.',
  ENTER_CODE_TO_CHANGE_PASSWORD:
    'Enter the code from your email to set a new password.',
  CONTACT_US_IF_YOU_HAVENT_RECIEVE_EMAIL:
    "If you haven't received an email, please contact us:",
  CONFIRM_ACCOUNT_FIRST: 'Please confirm your email first.',
  PASSWORD_CHANGED: 'Password changed successfully.',
  PASSWORD_CHANGED_SUCCESSFULLY:
    'Your password has been successfully changed, you can log in now.',
  CODE_FROM_EMAIL: 'Code from email',
  FREE_REGISTER_BUTTON: 'Registration',
  CONSENT: 'I have read and accept the terms',
  TERMS_OF_USE: 'regulations',
  ACCEPT: 'and',
  PRIVACY_POLICY: 'Privacy Policy',
  PRIVACY_POLICY_2:
    'I consent to the processing of my personal data in order to implement the subscription agreement. I agree to the immediate performance of the contract and acknowledge that I will lose my right to withdraw from the contract once I start using the subscription services.',
  MARKETING_CONSENT:
    "I agree to receive from StretchMe Sp. z o.o., ul. Lubomirskiego 20, 31-509 Kraków (Administrator) of commercial information by electronic means to the indicated e-mail address and by telephone to the indicated telephone number regarding the Administrator's products and services.",
  CONFIRM_BUTTON: 'Confirm',
  ALREADY_HAVE_AN_ACCOUNT: 'Already have an account?',
  PRIVACY_PLOLICY_PAGE_TITLE: 'Privacy Policy',
  TERMS_OF_USE_PAGE_TITLE: 'Terms of use',
  GOAL_OPTIONS_LABELS: {
    '30_days_challenge': '30-Day Challenge',
    headstand: 'Learn to stand on your head',
    basics: 'Learning the basics',
    other: 'Other',
    health_care: 'Take care of your physical health',
    twine: 'Do the twines',
    improve_mobilitiy: 'Improve body mobility',
    gear_up: 'Get Stronger',
    imprive_condition: 'Improve fitness',
    get_rid_of_tensions: 'Get rid of tensions',
    get_rid_of_stress: 'Reduce Stress',
    relax: 'Seek tranquility (relaxation and meditation here)',
    be_young: 'Keep young',
  },
  GOAL_OPTIONS_NAMES: {
    '30_days_challenge': '',
    headstand: 'headstand',
    basics: 'basics',
    others: '',
    health_care: 'Take care of your physical health',
    twine: 'Do the twines',
    improve_mobilitiy: 'Improve body mobility',
    gear_up: 'Get Stronger',
    imprive_condition: 'Improve fitness',
    get_rid_of_tensions: 'Get rid of tensions',
    get_rid_of_stress: 'Reduce Stress',
    relax: 'Seek tranquility (relaxation and meditation here)',
    be_young: 'Keep young',
  },
  GOAL_OPTIONS_TITLES: {
    '30_days_challenge': 'My 30-day challenge',
    headstand: 'My way to headstand',
    basics: 'Start with the basics.',
    others: '',
    health_care: 'Take care of your physical health',
    twine: 'Do the twines',
    improve_mobilitiy: 'Improve body mobility',
    gear_up: 'Get Stronger',
    imprive_condition: 'Improve fitness',
    get_rid_of_tensions: 'Get rid of tensions',
    get_rid_of_stress: 'Reduce Stress',
    relax: 'Seek tranquility (relaxation and meditation here)',
    be_young: 'Keep young',
  },
  PRIVACY_POLICY_PAGE: {
    section_1: {
      title: '',
      content: '',
    },
    section_2: {
      title: '',
      content: '',
    },
    section_3: {
      title: '',
      content: '',
    },
    section_4: {
      title: '',
      content: '',
    },
    section_5: {
      title: '',
      content: '',
    },
    section_6: {
      title: '',
      content: '',
    },
    section_7: {
      title: '',
      content: '',
    },
  },
  STRIPE_GATE: {
    title: 'Important Subscription Information',
    content: 'Thank you for choosing PhysioStretch! Please note that when you purchase a subscription on our website, it is a one-time payment and will not automatically renew. To enable automatic renewals, you\'ll need to manage your subscription through our mobile app. If you continue purchasing through the website, you\'ll need to manually repurchase access each time your subscription expires. For a seamless experience, we recommend using our mobile app to manage renewals.',
    button: 'Purchase One-Time Access',
    payment_success: 'Payment completed successfully. Click here to continue.',
    payment_failed: 'Payment failed.',
    payment: 'Continue',
    processing: 'Processing payment...',
  },
};
