import { useTranslation } from 'react-i18next';
import { Box, Button, Image, Grid } from 'theme-ui';
import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe as useStripeStripeHook, useElements } from '@stripe/react-stripe-js';
import { getDeviceWidth } from '../../../utils/getDeviceWidth';
import { useStripe } from '../../../context/StripeContext';

import Container from '../../01_atoms/Container/Container';
import Text from '../../01_atoms/Text/Text';
import Header from '../../02_molecules/Header/Header';
import useAuth from '../../../hooks/useAuth';
import SubscriptionGateImage from '../../../assets/images/SubscriptionGate.png';
import API from '../../../services/API';
import styled from 'styled-components';
import { useUser } from '@youga/youga-client-api';
// Update the Stripe publishable key
const stripePromise = loadStripe('pk_live_51IMFy6LUy4YGHzTF6WXs2WhGwl9eTi2wKCeGPv7HyIERPau98tBCLxzRNvod7Dh7To0adRhpjYMno6YvE97Ck04g007aNsNP1G');
const testStripePromise = loadStripe('pk_test_51IMFy6LUy4YGHzTF3egmToBYgdPKxoEOqtsZJHTVTy19mMMyf7xwacv0RTh9D02fSiOP5VDvuGcW69tPC0QKSggj00XSjFIctr');

const StyledAnchorTag = styled.a`
  @media (max-width: ${getDeviceWidth.tablet}) {
    margin-top: 16px;
  }
  color: #000;
  &.active {
    color: #a4d9d6;
  }
`;

// Add new styled component for product buttons
const ProductButton = styled(Button)`
  margin: 12px;
  padding: 8px 16px;
  font-size: 14px;
  min-width: 160px;
  &.active {
    background-color: #a4d9d6;
    color: white;
  }
`;

const StripeGate: React.FC<unknown> = () => {
  const { t } = useTranslation();
  const { token, user } = useAuth();
  const { refreshUser, data: userData } = useUser()
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [paymentStatus, setPaymentStatus] = useState<0 | 1 | 2>(0); // 0: basic, 1: payment ok, 2: payment failed
  const { setIsStripePayment, isStripePayment } = useStripe();
  const [selectedProduct, setSelectedProduct] = useState('prod_R6KH2qkMfR2qLI'); // Default product

  // Add products data
  const products = [
    { id: 'prod_R6KH2qkMfR2qLI', name: 'Basic', price: 1400, label: 'PLN 14.00' },
    { id: 'dev_30', name: 'Premium', price: 3000, label: 'PLN 30.00' },
    { id: 'stretchme14', name: 'Starter', price: 1400, label: 'PLN 14.00' },
  ];

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        setLoading(true);
        if (!token) throw new Error('Token not defined.');
        const response = await API.postStripePaymentIntent({
          idToken: token,
          payload: {
            productId: selectedProduct,
            customerId: user?.attributes?.sub,
            amount: products.find(p => p.id === selectedProduct)?.price || 1400
          }
        });
        setClientSecret(response.clientSecret);
      } catch (error) {
        console.error('Error fetching client secret:', error);
        setError('An unexpected error occurred. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchClientSecret();
  }, [token, selectedProduct]);

  useEffect(() => {
    console.log({ userData })
    if (userData?.plan?.b2b === 'stripe') {
      setIsStripePayment(false);
    }
  }, [userData?.plan?.type]);

  const PaymentForm = () => {
    const stripe = useStripeStripeHook();
    const elements = useElements();
    const [paymentError, setPaymentError] = useState<string | null>(null);
    const [paymentProcessing, setPaymentProcessing] = useState(false);
    const { user } = useAuth();
    const handleSubmit = async (event: React.FormEvent) => {
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }

      setPaymentProcessing(true);
      setPaymentError(null);

      const result = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
        confirmParams: {
          return_url: `${window.location.origin}/app/`,
          payment_method_data: {
            billing_details: {
              name: user?.attributes?.sub,
              email: user?.attributes?.email,
              phone: '123',
            },
          },
        },
      });

      console.log({ result });

      if (result.error) {
        setPaymentError(result.error.message || 'An error occurred during payment.');
        setPaymentProcessing(false);
        setPaymentStatus(2); // Payment failed
      } else {
        setPaymentStatus(1); // Payment succeeded
        setIsStripePayment(false);

        refreshUser();
      }
    };

    return (
      <form onSubmit={handleSubmit}>
        <PaymentElement options={{
          layout: 'tabs',
          paymentMethodOrder: ['card', 'p24'],
          defaultValues: {
            billingDetails: {
              name: 'never',
              email: 'never',
              phone: 'never',
            }
          },
          fields: {
            billingDetails: {
              name: 'never',
              email: 'never',
              phone: 'never',
            }
          },
          wallets: {
            applePay: 'never',
            googlePay: 'never'
          },
          appearance: {
            variables: {
              colorPrimary: '#a4d9d6',
              borderRadius: '4px',
              fontFamily: 'inherit',
              spacingUnit: '2px',
              spacingGridRow: '8px'
            },
            rules: {
              '.Tab': {
                padding: '4px 8px',
                fontSize: '12px'
              },
              '.Input': {
                padding: '4px',
                fontSize: '12px'
              },
              '.TabLabel': {
                fontSize: '12px'
              },
              '.TabIcon': {
                width: '12px',
                height: '12px'
              },
              '.Label': {
                fontSize: '12px'
              }
            }
          }
        }} />
        <Button
          variant="primary"
          type="submit"
          disabled={!stripe || paymentProcessing || paymentStatus !== 0}
          sx={{ 
            mt: 2, 
            width: '100%',
            fontSize: '14px',
            padding: '8px 16px'
          }}
        >
          {paymentProcessing ? t('STRIPE_GATE.processing') : t('STRIPE_GATE.payment_success')}
        </Button>
        {paymentError && (
          <Text color="red" sx={{ mt: 1, fontSize: '12px' }}>{paymentError}</Text>
        )}
      </form>
    );
  };

  // Add product selection section in the render before the payment form
  const renderProductSelection = () => (
    <Box sx={{ mb: 4, textAlign: 'center' }}>
      <Text variant="h6" sx={{ mb: 3 }}>{t('STRIPE_GATE.select_plan')}</Text>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        flexWrap: 'wrap',
        gap: '16px'
      }}>
        {products.map((product) => (
          <ProductButton
            key={product.id}
            onClick={() => setSelectedProduct(product.id)}
            className={selectedProduct === product.id ? 'active' : ''}
          >
            {product.name} - {product.label}
          </ProductButton>
        ))}
      </Box>
    </Box>
  );

  return (
    <>
      <Header showButton={null} theme="light" />

      <Box
        sx={{
          position: 'relative',
          minHeight: 'calc(100vh - 3rem)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '@media (orientation: portrait)': {
            minHeight: 'calc(100vh - 3.75rem)',
          },
          '@media (orientation: portrait) and (min-width: 48em)': {
            minHeight: 'calc(100vh - 5rem)',
          },
          '@media (orientation: landscape) and (min-height: 48em)': {
            minHeight: 'calc(100vh - 3.75rem)',
          },
          '@media (orientation: landscape) and (min-height: 62em)': {
            minHeight: 'calc(100vh - 5rem)',
          },
        }}
      >
        <Container>
          <Grid columns={[1, 1, 2]} gap="2rem">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: ['center', null, 'left'],
              }}
            >
              <Text variant="h2" sx={{ m: 0 }}>
                {t('STRIPE_GATE.title')}
              </Text>
              <Text  variant="h6">
                {t('STRIPE_GATE.content')}
              </Text>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                maxWidth: '400px',
                margin: ['2rem auto 0', null, '0 auto'],
              }}
            >
              {loading && <Text>Loading payment information...</Text>}
              {error && <Text color="red">{error}</Text>}
              {clientSecret && paymentStatus === 0 && (
                <Elements stripe={testStripePromise} options={{ clientSecret }}>
                  {renderProductSelection()}
                  <PaymentForm />
                </Elements>
              )}
              {paymentStatus === 1 && (
                <>
                  <Text color="green">{t('STRIPE_GATE.payment_success')}</Text>
                  <StyledAnchorTag
                    onClick={() => setIsStripePayment(false)}
                    sx={{ textDecoration: 'none' }}
                  >
                    {t('COOKIE_POLICY')}
                  </StyledAnchorTag>
                </>
              )}
              {paymentStatus === 2 && (
                <Text color="red">{t('STRIPE_GATE.payment_failed')}</Text>
              )}
            </Box>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default StripeGate;
