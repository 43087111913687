/** @jsxImportSource theme-ui */

import DefaultPageLayout from '../components/04_templates/DefaultPageLayout';
import { useEffect } from 'react';
import i18n from '../i18n/i18n';
import { useTranslation } from 'react-i18next';

function PrivacyPolicyPage() {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage('pl');
  }, []);

  return (
    <DefaultPageLayout title={t('PRIVACY_PLOLICY_PAGE_TITLE')}>
      <h3>{t('PRIVACY_POLICY_PAGE.section_1.title')}</h3>
      <div
        dangerouslySetInnerHTML={{
          __html: t('PRIVACY_POLICY_PAGE.section_1.content'),
        }}
        sx={{ lineHeight: '25px' }}
      />
      <h3>{t('PRIVACY_POLICY_PAGE.section_2.title')}</h3>
      <div
        dangerouslySetInnerHTML={{
          __html: t('PRIVACY_POLICY_PAGE.section_2.content'),
        }}
        sx={{ lineHeight: '25px' }}
      />
      <h3>{t('PRIVACY_POLICY_PAGE.section_3.title')}</h3>
      <div
        dangerouslySetInnerHTML={{
          __html: t('PRIVACY_POLICY_PAGE.section_3.content'),
        }}
        sx={{ lineHeight: '25px' }}
      />
      <h3>{t('PRIVACY_POLICY_PAGE.section_4.title')}</h3>
      <div
        dangerouslySetInnerHTML={{
          __html: t('PRIVACY_POLICY_PAGE.section_4.content'),
        }}
        sx={{ lineHeight: '25px' }}
      />
      <h3>{t('PRIVACY_POLICY_PAGE.section_5.title')}</h3>
      <div
        dangerouslySetInnerHTML={{
          __html: t('PRIVACY_POLICY_PAGE.section_5.content'),
        }}
        sx={{ lineHeight: '25px' }}
      />
      <h3>{t('PRIVACY_POLICY_PAGE.section_6.title')}</h3>
      <div
        dangerouslySetInnerHTML={{
          __html: t('PRIVACY_POLICY_PAGE.section_6.content'),
        }}
        sx={{ lineHeight: '25px' }}
      />
      <h3>{t('PRIVACY_POLICY_PAGE.section_7.title')}</h3>
      <div
        dangerouslySetInnerHTML={{
          __html: t('PRIVACY_POLICY_PAGE.section_7.content'),
        }}
        sx={{ lineHeight: '25px' }}
      />
    </DefaultPageLayout>
  );
}

export default PrivacyPolicyPage;
